import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const StatisticsSummaryContainer = styled.div``;

export const LegendLeftText = styled.td`
    font-weight: bold;
    padding-right: 10px;
`;

export const LegendRightText = styled.div`
    width: 200px;
    padding-bottom: 8px;
`;

export const LegendTable = styled.table`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
`;
