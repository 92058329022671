import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AccelerationHeatmapViewContent } from './AccelerationHeatmap.style';
import { AccelerationHeatmapViewProps } from './AccelerationHeatmap.type';
import { useTranslation } from 'react-i18next';
import { MapAction, MapActionData } from '../../states/global/Map';
import { HeatmapSettings } from '../../components/HeatmapPlayground/HeatmapPlayground.state';
import HeatmapTopbar from '../../components/Maps/HeatmapTopBar';
import MapLegend from '../../components/Ui/Components/UiMapComponent';
import Map from '../../components/Map';
import Widget from '../../components/Ui/Components/UiWidget';
import { SpeedHeatmapLegend, SpeedHeatmapLegendLabel } from '../SpeedHeatmap/SpeedHeatmap.style';
import { HeatmapKeys, MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

export const AccelerationHeatmapContent: React.FC<AccelerationHeatmapViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const { fromServerToUserUnit, displayUserUnits, convertType } = useConverter();
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const heatmapSettingsState = useRecoilValue(HeatmapSettings);
    const heatmapSettings = heatmapSettingsState();
    const heatmapLimit = { min: 0, max: 1 };
    const ThemeMode = useRecoilValue(Theme);

    const heatmap = useMemo(() => {
        const zoom = Math.max(mapActionData.zoomLevel || 13, 13);
        return {
            dotSize: heatmapSettings[zoom].dotSize,
            innerRadius: heatmapSettings[zoom].innerRadius,
            outerRadius: heatmapSettings[zoom].outerRadius,
            innerOpacity: heatmapSettings[zoom].innerOpacity,
            outerOpacity: heatmapSettings[zoom].outerOpacity,
            enableBlur: !heatmapSettings[zoom].disableBlur
        };
    }, [mapActionData.zoomLevel]);

    return (
        <AccelerationHeatmapViewContent data-testid='AccelerationHeatmapContent'>
            <HeatmapTopbar
                showDatetimePicker={true}
                showVehiclePicker={true}
                onApply={(dateRange, vehicles) => {
                    if (dateRange) {
                        setMapActionData((currentMapActionData) => {
                            return {
                                ...currentMapActionData,
                                heatmapLimit,
                                heatmapDateRange: dateRange,
                                ...heatmap,
                                vehicleIds: vehicles,
                                heatmapKey: HeatmapKeys.ACCELERATION
                            };
                        });
                        setMapAction({
                            action: MapActionsEnum.DRAW_HEATMAP
                        });
                    }
                }}
            />
            <Widget
                title={translate('t.acceleration')}
                collapseButton={false}
                fullScreenButton={true}
                avatar={
                    <UiIcon
                        icon={faLocationDot}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                scrollbar={false}
                $contentHeight={75}
                marginBottom={false}
                applyPadding={false}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
                content={
                    <>
                        <MapLegend>
                            <SpeedHeatmapLegend />
                            <SpeedHeatmapLegendLabel>
                                <span>{heatmapLimit.min}</span>
                                <span>{displayUserUnits.acceleration.replace('^', '')}</span>
                                <span>
                                    {fromServerToUserUnit({
                                        type: convertType.acceleration,
                                        value: heatmapLimit.max
                                    })}
                                </span>
                            </SpeedHeatmapLegendLabel>
                        </MapLegend>
                        <Map mapMode={MapModes.ACCELERATION_HEATMAP} />
                    </>
                }
            />
        </AccelerationHeatmapViewContent>
    );
};
