import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { LINK } from '../../Ui/colors';
import { TextField } from '@mui/material';

export const BatchesTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 100%;
    && .MuiCardContent-root {
        padding: 0px 10px 10px 10px;
        font-size: 13px !important;
    }
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
        margin: 5px 10px;
        overflow-wrap: break-word;
    }
`;

export const A = styled.a`
    color: ${LINK};
`;

export const ButtonHeaderWidget = styled.div`
    padding-right: 8px;
`;

export const TextFieldDatePicker = styled(TextField)`
    margin: 8px 5px !important;
`;
