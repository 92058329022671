import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import '@mui/lab';
import styled from 'styled-components';
import { ThemeMode } from '../../../../../../states/global/Theme';
import { useTheme } from '@mui/material';

export const DatePickerContentEl = styled.div`
    display: inline-block;

    @media (max-width: 767px) {
        display: block;
    }

    ${() => {
        return `
            &&& { 
                .MuiTextField-root {
                    margin: 8px;
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
        `;
    }}
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)<{ $noMargin?: boolean }>`
    &.MuiFormControl-root {
        margin: ${(props) => (props.$noMargin ? '0 !important' : '0 3px 3px 0 !important')};
    }
    @media (max-width: 426px) {
        &.MuiFormControl-root {
            margin: 0 3px 10px 0 !important;
        }
    }
`;
