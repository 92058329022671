import { StaticDateTimePicker, StaticDatePicker } from '@mui/x-date-pickers-pro';
import '@mui/lab';
import styled from 'styled-components';
import { ThemeMode } from '../../../../states/global/Theme';
import { useTheme } from '@mui/material';

export const UiDateRangePicker2El = styled.div`
    display: inline-block;

    @media (max-width: 767px) {
        margin-top: 15px;
    }

    ${() => {
        return `
            &&& { 
                .MuiTextField-root {
                    margin: 8px;
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
        `;
    }}
`;

export const CustomStaticDatePicker = styled(StaticDatePicker)`
    height: 400px;
`;

export const CustomStaticDateTimePicker = styled(StaticDateTimePicker)`
    height: 400px;
`;
