import { TableCell, TableHead, styled } from '@mui/material';

export const TableHeadEl = styled(TableHead)({
    marginTop: '10px'
});

export const HeaderCell = styled('span')``;

export const ActionHederText = styled('span')({
    display: 'flex',
    justifyContent: 'center'
});

export const TableCellEl = styled(TableCell)({
    fontSize: 'inherit !important'
});

export const ResizeSeparator = styled('div')``;
