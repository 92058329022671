import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { Alert, FormControlLabel, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { BACKGROUND_DARK_MODE, PRIMARY, WHITE } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';

export const VehicleDetailsSettingsViewContent = styled.div`
    && .MuiTypography-root {
        height: auto;
    }
`;

export const CustomTextField = styled(TextField)`
    &.MuiTextField-root {
        width: 190px;
        margin: 16px;
        margin-left: 0px;

        ${() => {
            return `
                    && label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? '#29313b' : WHITE};
                    } 
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -10px) scale(0.8);
                    }
                `;
        }}

        & svg {
            fill: #999999;
        }
    }
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-labelPlacementTop {
        margin-left: 40px;
    }
`;

export const SaveButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
`;

export const SetSensorTypes = styled.div`
    padding-left: 20px;
`;

export const UnderlineOptions = styled.div`
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
`;

export const LoadingDiv = styled.div`
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
`;

export const Loading = styled(CircularProgress)({
    color: `${PRIMARY} !important`,
    width: '50px !important',
    height: '50px !important'
});

export const Checkboxes = styled.div`
    display: flex;
    padding-top: 10px;
`;

export const CustomAlert = styled(Alert)`
    & .MuiAlert-root {
        font-size: 1.1rem;
    }
`;

export const OutsideDiv = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

export const InsideDiv = styled.div`
    width: 100%;
`;

export const ImageDetailSetting = styled.div`
    display: inline-flex;
`;

export const ImageDetailSettingContent = styled.div<{ $widthImage: string }>`
    width: 65px;
    height: 65px;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            dark: `background: ${BACKGROUND_DARK_MODE};`,
            light: 'background-color: #f2f2f2;'
        });
    }}
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    img {
        width: ${({ $widthImage }) => $widthImage};
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        width: 45px;
        height: 45px;
    }
`;
