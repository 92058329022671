import React from 'react';
import { useQuery } from '@tanstack/react-query';
import SystemNotification from 'api/SystemNotification';
import { SystemNotificationProps } from './SystemNotification.type';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';
import { SystemNotificationQueryKeys, SystemNotificationTableModel } from 'models/SystemNotification.type';
import { getFromLocalStorage, setToLocalStorage } from 'helpers';

const systemNotificationApi = new SystemNotification();

export const GetSystemNotification: React.FC<SystemNotificationProps> = ({ children }): JSX.Element => {
    const { user } = useRecoilValue(UserInfo);

    const useQueryProperties = useQuery<boolean, Error, SystemNotificationTableModel>(
        [SystemNotificationQueryKeys.getByCriteria],
        () =>
            systemNotificationApi.getByCriteria({
                criteria: {
                    limit: 100,
                    active: 1,
                    customerId: user?.customer?.id ?? NaN
                }
            }),
        {
            refetchInterval: 8000,
            enabled: Boolean(user?.customer?.id),
            staleTime: 0,
            cacheTime: 0,
            retry: false,
            onSuccess: () => !getFromLocalStorage<boolean>('isOnline') && setToLocalStorage<boolean>('isOnline', true),
            onError: (error) => error.message === 'Network Error' && setToLocalStorage<boolean>('isOnline', false)
        }
    );

    return <>{children(useQueryProperties)}</>;
};
