import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ElevationHeatmapViewContent } from './ElevationHeatmap.style';
import { ElevationHeatmapViewProps } from './ElevationHeatmap.type';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '../../states/global/User';
import { HeatmapKeys, MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import { MapAction, MapActionData } from '../../states/global/Map';
import Map from '../../components/Map';
import Widget from '../../components/Ui/Components/UiWidget';
import MapLegend from '../../components/Ui/Components/UiMapComponent';
import HeatmapLegend from '../../components/Maps/HeatmapLegend';
import HeatmapTopbar from '../../components/Maps/HeatmapTopBar';
import { HeatmapSettings } from '../../components/HeatmapPlayground/HeatmapPlayground.state';
import { applyStyleByMode } from 'helpers';
import useConverter from '../../components/CustomHooks/Converter/Converter';
import { faMountain } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const ElevationHeatmapContent: React.FC<ElevationHeatmapViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { convertType, fromServerToUserUnit } = useConverter();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const userInfo = useRecoilValue(UserInfo);
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const heatmapSettingsState = useRecoilValue(HeatmapSettings);
    const heatmapSettings = heatmapSettingsState();
    const ThemeMode = useRecoilValue(Theme);
    const heatmapLimit = useMemo(() => {
        return {
            min: +fromServerToUserUnit({ type: convertType.altitude, value: props.elevationLimits.min }),
            max: +fromServerToUserUnit({ type: convertType.altitude, value: props.elevationLimits.max })
        };
    }, [fromServerToUserUnit, props.elevationLimits]);

    const heatmap = useMemo(() => {
        const zoom = Math.max(mapActionData.zoomLevel || 13, 13);
        return {
            dotSize: heatmapSettings[zoom].dotSize,
            innerRadius: heatmapSettings[zoom].innerRadius,
            outerRadius: heatmapSettings[zoom].outerRadius,
            innerOpacity: heatmapSettings[zoom].innerOpacity,
            outerOpacity: heatmapSettings[zoom].outerOpacity,
            enableBlur: !heatmapSettings[zoom].disableBlur
        };
    }, [mapActionData.zoomLevel]);

    return (
        <ElevationHeatmapViewContent data-testid='ElevationHeatmapContent'>
            <HeatmapTopbar
                showDatetimePicker={true}
                showVehiclePicker={false}
                onApply={(dateRange) => {
                    if (dateRange) {
                        setMapActionData((currentMapActionData) => {
                            return {
                                ...currentMapActionData,
                                heatmapLimit,
                                heatmapDateRange: dateRange,
                                ...heatmap,
                                heatmapKey: HeatmapKeys.ELEVATION
                            };
                        });
                        setMapAction({
                            action: MapActionsEnum.DRAW_HEATMAP
                        });
                    }
                }}
            />
            <Widget
                title={translate('t.elevation_heatmap')}
                collapseButton={false}
                fullScreenButton={true}
                {...props}
                avatar={
                    <UiIcon
                        icon={faMountain}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                scrollbar={false}
                $contentHeight={75}
                marginBottom={false}
                applyPadding={false}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                unit='vh'
                padding={0}
            >
                <MapLegend>
                    <HeatmapLegend
                        from={heatmapLimit.min}
                        to={heatmapLimit.max}
                        unit={userInfo.user?.userSetting.altitudeUnit}
                    />
                </MapLegend>
                <Map mapMode={MapModes.ELEVATION_HEATMAP} />
            </Widget>
        </ElevationHeatmapViewContent>
    );
};
