import React, { useCallback, useEffect, useState } from 'react';
import { HubGeneralInfoContent } from './HubGeneralInfo.view';
import { HubGeneralInfoProps } from './HubGeneralInfo.type';
import DeviceApi from 'api/Device';
import { DeviceModel, DeviceQueryKeys, PatchDeviceModel } from 'models/Device.type';
import DeviceFaultyStatusApi from 'api/DeviceFaultyStatus';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import AppRegion from 'api/AppRegion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import {
    DeviceFaultyStatusModel,
    DeviceFaultyStatusQueryKeys,
    PatchDeviceFaultyStatus
} from 'models/DeviceFaultyStatus.type';
import { cacheTimeToMilliseconds, getCache, isCacheAvailable } from 'helpers/cache';
import { AppRegionModel, AppRegionQueryKeys, ChangeAppRegion } from 'models/AppRegion.type';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { Wrapper } from 'helpers/wrapper';

const Device = new DeviceApi();
const AppRegionApi = new AppRegion();
const DeviceFaultyStatus = new DeviceFaultyStatusApi();

const HubGeneralInfo: React.FC<HubGeneralInfoProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryCache = useQueryClient().getQueryCache();
    const [hubFaultyStatus, setHubFaultyStatus] = useState<DeviceFaultyStatusModel[]>([]);
    const [comment, setComment] = useState<string>('');
    const [appRegionCurrent, setAppRegionCurrent] = useState<AppRegionModel | null>(null);
    const [hubData, setHubData] = useState<DeviceModel | undefined>();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const [appRegions, setAppRegions] = useState<AppRegionModel[] | undefined>();

    const { isLoading: DeviceDataLoading } = useQuery<DeviceModel | undefined>(
        [DeviceQueryKeys.getDeviceById, props.hubId],
        () => {
            return Device.getDeviceById(props.hubId);
        },
        {
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            onSuccess: (data) => {
                setHubData(data);
                data?.appRegion && setAppRegionCurrent(data.appRegion);
            }
        }
    );

    const { isLoading: AppRegionDataLoading } = useQuery<AppRegionModel[] | undefined>(
        [AppRegionQueryKeys.getAllAsCodebook, props.hubId],
        () => {
            return AppRegionApi.getAllAsCodebook();
        },
        {
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours'),
            onSuccess: (data) => {
                setAppRegions(data);
            }
        }
    );

    useEffect(() => {
        isCacheAvailable([DeviceQueryKeys.getDeviceById, props.hubId], queryCache) &&
            setHubData(
                getCache([DeviceQueryKeys.getDeviceById, props.hubId], queryCache)[0]?.state?.data as DeviceModel
            );
    }, [props.hubId]);

    const { isLoading: patchIsLoading, mutate: patchHub } = useMutation<
        { device: DeviceModel },
        Error,
        PatchDeviceModel
    >({
        mutationFn: (hubData) => {
            return Device.patchById({ id: props.hubId, data: hubData });
        },
        mutationKey: [`patchHub-${props.hubId}`],
        onSuccess: (data: { device: DeviceModel }) => {
            Success({
                text: `${translate('t.hub')} ${translate('p.has_been_updated')}`,
                customSetting: {
                    position: 'bottom-right',
                    duration: 3000
                }
            });
            modalDispach({
                type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                ModalPropsPayload: {
                    isOpen: false
                }
            });
            setHubData(data?.device);
            appRegionCurrent && patchAppRegion(appRegionCurrent);
        }
    });

    const { mutate: patchAppRegion } = useMutation<DefaultRequestPropsType, Error, AppRegionModel>({
        mutationFn: (appRegion) => {
            const value: ChangeAppRegion | undefined = appRegion ? { appRegionId: appRegion.id } : undefined;

            return Device.patchAppRegion({ id: props.hubId, data: value });
        },
        mutationKey: [`patchAppregion-${props.hubId}`],
        onSuccess: () => {
            Success({
                text: translate('t.edited_region'),
                customSetting: {
                    position: 'bottom-right',
                    duration: 3500
                }
            });
        }
    });

    const { isLoading: faultyReasonLoading, data: faultyStatusOptions } = useQuery(
        [DeviceFaultyStatusQueryKeys.getCodebook, props.hubId],
        () => {
            return DeviceFaultyStatus.getCodebook({});
        },
        {
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    const changeFaultyStatus = (
        event: React.SyntheticEvent<Element, Event>,
        value: DeviceFaultyStatusModel[]
    ): void => {
        setHubFaultyStatus(value);
    };

    const changeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const changeAppRegion = (appRegion: AppRegionModel) => {
        appRegion && setAppRegionCurrent(appRegion);
    };

    const getUpdatedFaultyStatus = (newStatus: DeviceFaultyStatusModel[]): PatchDeviceFaultyStatus => {
        const originalStatus = hubData?.faultyStatus || [];
        let data: PatchDeviceFaultyStatus = [];
        for (let i = 0, length = newStatus.length; i < length; i++) {
            const found = originalStatus.filter((status) => status.status.id === newStatus[i].id);
            if (found.length) {
                data.push({ id: found[0].id });
            } else {
                data.push({ status: newStatus[i].id });
            }
        }
        return data;
    };

    useEffect(() => {
        if (hubData) {
            setComment(hubData?.comment || '');
            setHubFaultyStatus(
                hubData.faultyStatus?.map((status) => {
                    return status.status;
                }) || []
            );
        }
    }, [hubData]);

    useEffect(() => {
        return () => {
            setHubData(undefined);
            setComment('');
        };
    }, []);

    const filteredOptions = useCallback(() => {
        const usedIds = hubFaultyStatus?.map((option) => option.id);
        return faultyStatusOptions?.filter((option) => !usedIds?.includes(option.id)) || [];
    }, [faultyStatusOptions, hubFaultyStatus]);

    return (
        <HubGeneralInfoContent
            data-testid='HubGeneralInfo-testid'
            hubData={hubData}
            faultyReasons={filteredOptions}
            hubFaultyStatus={hubFaultyStatus}
            changeFaultyStatus={changeFaultyStatus}
            comment={comment}
            changeComment={changeComment}
            patchHub={patchHub}
            isLoading={DeviceDataLoading && faultyReasonLoading}
            patchIsLoading={patchIsLoading}
            getUpdatedFaultyStatus={getUpdatedFaultyStatus}
            changeAppRegion={changeAppRegion}
            appRegionCurrent={appRegionCurrent}
            AppRegionDataLoading={AppRegionDataLoading}
            appRegions={appRegions}
            patchAppRegion={patchAppRegion}
        />
    );
};

export default Wrapper(HubGeneralInfo);
