import styled from 'styled-components';
import { Popover } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import { useTheme } from '@mui/material';
import {
    BACKGROUND_BLUE,
    BACKGROUND_GREY,
    DARK_STEEL_GREY,
    PRIMARY,
    PRIMARY_SEMI_LIGHT,
    SECONDARY,
    WHITE
} from '../colors';
import { CommonTypeTheme } from '../Theme';

export const FilterColumnsContent = styled.div`
    display: inline-block;
`;

export const FilterColumnCover = styled.div`
    display: grid;
    grid-column-gap: 10px;
    padding-top: 1px;
    padding-top: 1px;
`;

export const LegendContainer = styled.div<{ extraWidth?: boolean }>`
    font-size: 0.875rem;
    margin-left: auto;
    margin: 0px 5px;
    padding: 6px;
    text-transform: capitalize;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 36.5px;
    width: ${({ extraWidth }) => (extraWidth ? '93.34px' : '64px')};
`;

export const TextContainer = styled.div`
    font-size: 12.96px;
    font-weight: 500;

    svg {
        margin-left: 5px;
    }
`;

export const CustomPopover = styled(Popover)<{
    width?: number;
}>`
    &.MuiPopover-root {
        color: ${PRIMARY};
        & p {
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `
                    font-size: ${common.titleSize};
                `;
            }}
        }

        & .MuiPaper-root {
            min-width: 250px !important;
            ${({ width }) => {
                return `
                    width: ${width ? `${width}px` : 'auto'};
                    max-width: ${width ? `${width}px` : 'auto'};
                `;
            }}

            ${() => useTheme().breakpoints.between('xs', 'md')} {
                width: calc(100vw - 31px);
            }
        }
        & .legend-header {
            color: ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_BLUE : DARK_STEEL_GREY)};
            padding: 8px 0px 4px 0px !important;
            border-bottom: solid 1px ${() => (useTheme().palette.mode === ThemeMode.dark ? BACKGROUND_GREY : SECONDARY)};
            line-height: 2;
            min-height: 25px;
            width: 100% !important;
            display: inline-flex !important;
            font-weight: 600;
            align-items: center;

            button {
                color: ${() => (useTheme().palette.mode === ThemeMode.dark ? SECONDARY : PRIMARY_SEMI_LIGHT)};
                font-size: 0.875rem;
                margin-left: auto;
            }
            button:hover {
                background: ${() =>
                    useTheme().palette.mode === ThemeMode.dark ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'};
                color: ${() => (useTheme().palette.mode === ThemeMode.dark ? '#fff' : PRIMARY)};
                transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
            }
        }
        & .legend-body {
            padding: 10px !important;
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `font-size: ${common.textSize};`;
            }}
            min-height: auto;
            color: ${() => (useTheme().palette.mode === ThemeMode.dark ? WHITE : PRIMARY)};
        }
    }
`;
