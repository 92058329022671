import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export const GlobalAdminNotifyTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 100%;
    && .MuiCardContent-root {
        padding: 0px 10px 10px 10px;
        font-size: 13px !important;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
        margin: 2px 2px;
    }
`;

export const CustomTextField = styled(TextField)``;
