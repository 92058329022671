import React from 'react';
import Widget from '../../components/Ui/Components/UiWidget/UiWidget';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AnnouncementsTable } from '../../components/AnnouncementsTable/AnnouncementsTable';
import { AnnouncementsViewStyle } from './Announcements.style';
import { applyStyleByMode } from 'helpers';
import { faMessageExclamation } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const AnnouncementsContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <AnnouncementsViewStyle>
            <Widget
                testid='annoucements-widget'
                title={translate('t.announcements')}
                id={'announcements-table'}
                collapse={false}
                avatar={
                    <UiIcon
                        icon={faMessageExclamation}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={<AnnouncementsTable />}
            />
        </AnnouncementsViewStyle>
    );
};
