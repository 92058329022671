import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';

export const TemperatureExposureReportContainer = styled.div``;

export const TooltipContentEl = styled.div`
    padding: 10px;
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background: rgb(255, 255, 255);',
            dark: 'background: rgb(29 38 48 / 85%);'
        });
    }}
    text-align: left;
    p {
        margin: 2px;
    }
`;

export const BarChartTooltip = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

export const BarChartTooltipLine = styled.li<{ color: string }>`
    &:before {
        content: '';
        background: ${(props) => props.color};
        padding: 0px 13px;
        margin: 0 10px 0 0;
    }
    margin-bottom: 3px;
`;
