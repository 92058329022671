import React, { useEffect } from 'react';
import { Wrapper } from 'helpers/wrapper';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import UiWidget from 'components/Ui/Components/UiWidget';
import DataExport from 'components/Mixs/DataExport';
import { TempPressNotificationsContainer } from './TempPressNotifications.style';
import TyreNotification from 'api/TyreNotification';
import { TyreNotificationPreviewModel } from 'models/TyreNotification.type';
import { ParseFunctionReturnType } from './TempPressNotifications.type';
import { applyStyleByMode } from 'helpers';
import { useQuery } from '@tanstack/react-query';
import { Success } from 'components/Popup';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import NotificationEvent from 'api/NotificationEvent';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { TyreNotificationQueryKeys } from 'models/TyreNotification.type';

const tyreNotificationsAPI = new TyreNotification();
const notificationEventAPI = new NotificationEvent();

const TempPressNotifications: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { fromUTCtoUserTimezone, convertType, fromServerToUserUnit } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    const getMessage = (data: TyreNotificationPreviewModel): string => {
        let params = {
            tyre_position: data.tyreCustomPosition || data?.customPosition,
            vehicle: data.vehicleName
        };
        if (data.type === 'temperature_prediction' && data.criticalTemperature && data.criticalTemperatureAt) {
            params['criticalTemperature'] = fromServerToUserUnit({
                type: convertType.temperature,
                value: data.criticalTemperature,
                displayUnits: true,
                fixed: 1
            });
            params['criticalTemperatureAt'] = fromUTCtoUserTimezone({
                date: data.criticalTemperatureAt,
                format: 'dateTime'
            });
        }
        if (data.type === 'temperature' && (data.temperatureLimitFrom || data.limitFrom != null)) {
            params['limit'] = fromServerToUserUnit({
                type: convertType.temperature,
                value: data.temperatureLimitFrom || data.limitFrom,
                displayUnits: true,
                fixed: 1
            });
            params['actual_temperature'] = fromServerToUserUnit({
                type: convertType.temperature,
                value: data.temperature,
                displayUnits: true,
                fixed: 1
            });
        } else {
            params['actual_pressure'] = fromServerToUserUnit({
                type: convertType.pressure,
                value: data.type === 'pressure' ? data.coldPressure : data.hotPressure,
                displayUnits: true,
                fixed: 1
            });
            params['limit'] = fromServerToUserUnit({
                type: convertType.pressure,
                value:
                    data.type2 === 'low'
                        ? data.pressureLimitTo || data?.limitTo
                        : data.pressureLimitFrom || data?.limitFrom,
                displayUnits: true,
                fixed: 1
            });
        }

        return translate(`notification.message.${data.type}_${data.type2}`, params);
    };

    const { refetch } = useQuery(
        [TyreNotificationQueryKeys.getTyreNotificationsReportsCsv],
        () =>
            tyreNotificationsAPI.getTyreNotificationsReportsCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO()
            ),
        {
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: () => Success({ text: translate('p.report_success_message') })
        }
    );

    const getValue = (data: TyreNotificationPreviewModel): string => {
        switch (data.type) {
            case 'pressure':
                return fromServerToUserUnit({
                    type: convertType.pressure,
                    value: data.coldPressure,
                    displayUnits: true,
                    fixed: 1
                });
            case 'pressure_hot':
                return fromServerToUserUnit({
                    type: convertType.pressure,
                    value: data.hotPressure,
                    displayUnits: true,
                    fixed: 1
                });
            case 'temperature':
            case 'temperature_prediction':
                return fromServerToUserUnit({
                    type: convertType.temperature,
                    value: data.temperature,
                    displayUnits: true,
                    fixed: 1
                });
            default:
                return '';
        }
    };

    const parseData = (props: TyreNotificationPreviewModel[]): ParseFunctionReturnType[] => {
        const data = [...props];
        let returnData: ParseFunctionReturnType[] = [];
        if (data.length) {
            for (let i = 0, length = data.length; i < length; i++) {
                let row = data[i];
                returnData.push({
                    vehicleName: row.vehicleName,
                    position: row.tyreCustomPosition || (row.customPosition as number),
                    serialNumber: row.sensorSerialNumberHex,
                    createdAt: fromUTCtoUserTimezone({ date: row.measuredAt, format: 'dateTime' }),
                    level: row.level,
                    message: getMessage(row),
                    type:
                        row.type === 'pressure' && row.type2 === 'low'
                            ? translate(`t.${row.type2}_cold_${row.type}`)
                            : translate(`t.${row.type2}_${row.type}`),
                    value: getValue(row)
                });
            }
        }
        return returnData;
    };

    const columns = [
        {
            header: translate('t.vehicle_name'),
            accessor: 'vehicleName'
        },
        {
            header: translate('t.position'),
            accessor: 'position'
        },
        {
            header: translate('t.serial_number'),
            accessor: 'serialNumber'
        },
        {
            header: translate('t.message'),
            accessor: 'message'
        },
        {
            header: translate('t.type'),
            accessor: 'type'
        },
        {
            header: translate('t.level'),
            accessor: 'level'
        },
        {
            header: translate('t.created_at'),
            accessor: 'createdAt'
        },
        {
            header: translate('t.value'),
            accessor: 'value'
        }
    ];

    useEffect(() => {
        setPageHelpButton({ article: 'data-exports-tab' });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <TempPressNotificationsContainer>
            <UiWidget
                title={translate('t.temperature_pressure_notifications')}
                id='temp-press-notifications-widget'
                avatar={
                    <UiIcon
                        icon={faBullhorn}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={notificationEventAPI.getPreview}
                        downloadFn={refetch}
                        queryKey='TempPressNotifications'
                        parseData={parseData}
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO()
                        }}
                    />
                }
            />
        </TempPressNotificationsContainer>
    );
};

export default Wrapper(TempPressNotifications);
