import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContainerIconStatus, ColumnStickyBody, CustomTooltip } from './TemperaturePressureTableVehicleInfo.style';
import { TemperaturePressureTableVehicleInfoProps } from './TemperaturePressureTableVehicleInfo.type';
import { useTranslation } from 'react-i18next';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { Theme } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';
import { DARK_STEEL_GREY, WHITE } from 'components/Ui/colors';
import { faThermometerThreeQuarters, faTruckClock, faWrench, faChartArea } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { CustomerSettings } from 'states/global/CustomerSettings';

export const TemperaturePressureTableVehicleInfo: React.FC<TemperaturePressureTableVehicleInfoProps> = ({
    vehicle,
    rowNumber
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    const vehicleLink = () => (
        <UiLink
            color={applyStyleByMode({ theme: ThemeMode?.mode, light: DARK_STEEL_GREY, dark: '#40FFA6' })}
            $fontWeight='bold'
            content={vehicle.name}
            title={`${translate('t.hub')} Id: ${vehicle?.deviceId ?? '---'}`}
            url={`/vehicle-status?vehicle=${vehicle.id}`}
            testId={`LinkToVehicleDetails-Buton-${rowNumber}`}
        />
    );

    const chartLink = () => (
        <UiLink
            color={applyStyleByMode({ theme: ThemeMode?.mode, light: DARK_STEEL_GREY, dark: '#40FFA6' })}
            content={<UiIcon icon={faChartArea} size='lg' />}
            title={translate('t.show_statistics')}
            url={`/vehicle/${vehicle.id}/statistics`}
            testId='LinkToVehicleStatistics-Buton'
        />
    );
    const outOfServiceIcon = () =>
        !!vehicle.outOfService && (
            <CustomTooltip title={translate('t.vehicle_settings_service_off_success_title')} disableInteractive>
                <UiIcon
                    icon={faTruckClock}
                    size='lg'
                    color={applyStyleByMode({ theme: ThemeMode?.mode, dark: WHITE, light: '#0000008a' })}
                />
            </CustomTooltip>
        );
    const maintenanceIcon = () =>
        !!vehicle.maintenance && (
            <CustomTooltip title={translate('t.vehicle_in_maintenance')} disableInteractive>
                <UiIcon
                    icon={faWrench}
                    size='lg'
                    color={applyStyleByMode({ theme: ThemeMode?.mode, light: '#0000008a', dark: WHITE })}
                />
            </CustomTooltip>
        );
    const predictiveTemperatureIcon = () =>
        customerSettings?.temperature_prediction &&
        vehicle.predictionTemperature &&
        !vehicle.genericTemperatureAlert && (
            <CustomTooltip title={'Predictive Temperature'} disableInteractive>
                <span className='fa-layers fa-fw'>
                    <UiIcon
                        icon={faSlashRegular}
                        fontSize='16px'
                        color={applyStyleByMode({ theme: ThemeMode?.mode, dark: WHITE, light: '#787878' })}
                    />
                    <UiIcon
                        icon={faThermometerThreeQuarters}
                        fixedWidth
                        fontSize='16px'
                        color={applyStyleByMode({ theme: ThemeMode?.mode, dark: WHITE, light: '#787878' })}
                    />
                </span>
            </CustomTooltip>
        );

    return (
        <>
            <ColumnStickyBody
                $theme={ThemeMode}
                key='vehicle'
                $lef={0}
                $notBorderRight={true}
                $rowNumber={rowNumber}
                $minWidth='155'
                align='left'
            >
                {vehicleLink()}
            </ColumnStickyBody>
            <ColumnStickyBody $theme={ThemeMode} align='center' $rowNumber={rowNumber} $lef={155} $minWidth={49}>
                <ContainerIconStatus $height={47}>
                    {chartLink()}
                    {outOfServiceIcon()}
                    {maintenanceIcon()}
                    {predictiveTemperatureIcon()}
                </ContainerIconStatus>
            </ColumnStickyBody>
        </>
    );
};
