import styled from 'styled-components';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';

export const UiLoadingContainer = styled.div<{}>`
    && .MuiCircularProgress-root {
        margin-top: 30px;
        margin-left: 15px;
    }
`;

export const TyreLifespanReportViewContent = styled.div``;

export const LazyLoadingGrid = styled(Grid)``;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        margin-left: 10px;
        align-self: center;
    }
`;

export const DropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: center;
`;
