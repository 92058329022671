import React, { useEffect, useState } from 'react';
import { StatisticsTopBarViewProps } from './StatisticsTopBar.type';
import { ActionCover, AllHubsReadingCheckbox, StatisticsTopBarContainer, WarningCover } from './StatisticsTopBar.style';
import Menu from '../Ui/Components/UiMenu';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AllHubsReading, Collapse, DateTimePicker, SelectedVehicle } from '../../states/global/Statistics';
import Checkbox from '../Ui/Components/UiCheckbox';
import { getImgSource } from 'helpers/image';
import { useTranslation } from 'react-i18next';
import UiButton from '../Ui/Components/UiButton';
import { UserInfo } from '../../states/global/User';
import { Fab, Grid, Hidden, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import { applyStyleByMode } from 'helpers';
import { DatePickerAction } from '../../pages/SeverityRank/atom';
import { BLACK, INACTIVE_LIST, WHITE } from '../Ui/colors';
import UiIcon from '../Ui/Components/UiIcon/UiIcon';
import { Theme } from '../../states/global/Theme';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { generatePath, useNavigate } from 'react-router-dom';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { RedirectParams, RedirectParamsType } from 'pages/Redirect/Redirect.atom';

export const StatisticsTopBarContent: React.FC<StatisticsTopBarViewProps> = (props): JSX.Element => {
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);
    const [selectedVehicle, setSelectedVehicle] = useRecoilState(SelectedVehicle);
    const [originalVehicle, setOriginalVehicle] = useState<number | undefined>(undefined);
    const [allHubsReading, setAllHubsReading] = useRecoilState(AllHubsReading);
    const [collapse, setCollapse] = useRecoilState(Collapse);
    const setRedirectParams = useSetRecoilState(RedirectParams);
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const navigate = useNavigate();

    useEffect(() => {
        userInfo.user?.userSetting.allHubsChecked
            ? setAllHubsReading(userInfo.user?.userSetting.allHubsChecked)
            : setAllHubsReading(false);
    }, [userInfo.user?.userSetting.allHubsChecked]);

    useEffect(() => {
        !originalVehicle && setOriginalVehicle(selectedVehicle.id);
    }, [selectedVehicle.id]);

    return (
        <StatisticsTopBarContainer data-testid='StatisticsTopBarContent'>
            <>
                <Grid container spacing={1} justifyContent='center'>
                    <Grid item>
                        <Tooltip title={translate('t.go_back')} placement='bottom' arrow disableInteractive>
                            <Fab
                                size='small'
                                color='primary'
                                aria-label='Go Back'
                                onClick={() => {
                                    navigate(`/vehicle-status?id=${originalVehicle}`);
                                }}
                                data-testid='Statistics-GoBack-Button'
                            >
                                <UiIcon icon={['fas', 'arrow-left']} size='lg' fixedWidth color={WHITE} />
                            </Fab>
                        </Tooltip>

                        <Menu
                            rootEl={
                                <UiButton
                                    color='primary'
                                    variant={'outlined'}
                                    endIcon={<ExpandMore />}
                                    style={{
                                        color: applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        }),
                                        marginTop: '1px'
                                    }}
                                    testid='Statistics-VehicleNameList-Button'
                                >
                                    {selectedVehicle.name}
                                </UiButton>
                            }
                            items={props.vehicleList?.map((vehicle) => {
                                return {
                                    title: vehicle.name,
                                    value: vehicle.id,
                                    style: {
                                        color: applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: vehicle.active ? BLACK : INACTIVE_LIST,
                                            dark: vehicle.active ? WHITE : INACTIVE_LIST
                                        })
                                    },
                                    icon: getImgSource(vehicle.icon),
                                    action: () => {
                                        setRedirectParams((current) => {
                                            const newParams = { ...current.params } as RedirectParamsType['params'];
                                            if (newParams?.fastLeak) {
                                                newParams.fastLeak = undefined;
                                            }
                                            return {
                                                ...current,
                                                params: newParams
                                            };
                                        });

                                        setSelectedVehicle({
                                            id: vehicle.id,
                                            name: vehicle.name,
                                            icon: vehicle.icon
                                        });

                                        navigate(
                                            generatePath('/vehicle/:id/statistics', {
                                                id: `${vehicle.id}`
                                            }),
                                            { replace: true }
                                        );
                                    }
                                };
                            })}
                        />
                    </Grid>
                    <Hidden only={['xl', 'lg', 'md']}>
                        <Grid item>
                            <Tooltip
                                title={collapse.all ? translate('t.expand_all') : translate('t.collapse_all')}
                                arrow
                                placement='bottom'
                            >
                                <Fab
                                    sx={{ marginRight: 1 }}
                                    size='small'
                                    color='primary'
                                    aria-label='Expand All / Collapse All '
                                    onClick={() => {
                                        setCollapse((current) => ({ ...current, all: !current.all, last: 'all' }));
                                    }}
                                    data-testid='Statistics-ExpandAllOrCollapseAll-Button'
                                    disabled={props.disableButton}
                                >
                                    <UiIcon
                                        icon={['fas', collapse.all ? 'maximize' : 'minimize']}
                                        size='lg'
                                        fixedWidth
                                        color={WHITE}
                                    />
                                </Fab>
                            </Tooltip>

                            {!props.isBesGraph && (
                                <Tooltip
                                    title={
                                        collapse.secondary
                                            ? translate('t.expand_secondary')
                                            : translate('t.collapse_secondary')
                                    }
                                    arrow
                                    placement='bottom'
                                >
                                    <Fab
                                        size='small'
                                        color='primary'
                                        aria-label='Expand All / Collapse All '
                                        onClick={() => {
                                            setCollapse((current) => ({
                                                ...current,
                                                secondary: !current.secondary,
                                                last: 'secondary'
                                            }));
                                        }}
                                        data-testid='Statistics-ExpandSecondaryOrCollapseSecondary-Button'
                                        disabled={props.disableButton}
                                    >
                                        <UiIcon
                                            icon={[
                                                'fas',
                                                collapse.secondary ? 'arrow-down-from-line' : 'arrow-up-to-line'
                                            ]}
                                            size='lg'
                                            fixedWidth
                                            color={WHITE}
                                        />
                                    </Fab>
                                </Tooltip>
                            )}
                        </Grid>
                    </Hidden>

                    <Grid item>
                        <UiDateRangePicker2
                            adjustToPeriod={{ period: 15, periodType: PeriodTypeEnum.DAYS }}
                            dateFrom={globalDateTimePicker.current.dateFrom}
                            dateTo={globalDateTimePicker.current.dateTo}
                            dateFromLabel={translate('t.date_from')}
                            dateToLabel={translate('t.date_to')}
                            onDateChange={(dateRange) => {
                                const isValidDataRange =
                                    dateRange != null &&
                                    !dateRange[1].invalid &&
                                    !dateRange[0].invalid &&
                                    dateRange[1] != '' &&
                                    dateRange[0] != '' &&
                                    dateRange[1].valueOf() <= DateTime.now().valueOf();

                                if (isValidDataRange) {
                                    setGlobalDateTimePicker({
                                        current: { dateTo: dateRange[1], dateFrom: dateRange[0] },
                                        original: { ...globalDateTimePicker.current },
                                        action: DatePickerAction.APPLY
                                    });
                                }

                                props.setShowWarningMsg(!isValidDataRange);
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <AllHubsReadingCheckbox
                            control={
                                <Checkbox
                                    checked={!!allHubsReading}
                                    onChange={(e, checked) => {
                                        setAllHubsReading(checked);
                                    }}
                                    disabled={props.disableButton}
                                    name='allHubsReading'
                                    color='primary'
                                    testid='Statistics-AllHubsReading-Checkbox'
                                />
                            }
                            label={translate('t.all_sensor_readings')}
                        />
                    </Grid>
                    <Hidden only={['sm', 'xs']}>
                        <Grid item sx={{ pb: 1 }}>
                            <Tooltip
                                title={collapse.all ? translate('t.expand_all') : translate('t.collapse_all')}
                                arrow
                                placement='bottom'
                            >
                                <Fab
                                    sx={{ marginRight: 1 }}
                                    size='small'
                                    color='primary'
                                    aria-label='Expand All / Collapse All '
                                    onClick={() => {
                                        setCollapse((current) => ({ ...current, all: !current.all, last: 'all' }));
                                    }}
                                    data-testid='Statistics-ExpandAllOrCollapseAll-Button'
                                    disabled={props.disableButton}
                                >
                                    <UiIcon
                                        icon={['fas', collapse.all ? 'maximize' : 'minimize']}
                                        size='lg'
                                        fixedWidth
                                        color={WHITE}
                                    />
                                </Fab>
                            </Tooltip>

                            {!props.isBesGraph && (
                                <Tooltip
                                    title={
                                        collapse.secondary
                                            ? translate('t.expand_secondary')
                                            : translate('t.collapse_secondary')
                                    }
                                    arrow
                                    placement='bottom'
                                >
                                    <Fab
                                        size='small'
                                        color='primary'
                                        aria-label='Expand All / Collapse All '
                                        onClick={() => {
                                            setCollapse((current) => ({
                                                ...current,
                                                secondary: !current.secondary,
                                                last: 'secondary'
                                            }));
                                        }}
                                        data-testid='Statistics-ExpandSecondaryOrCollapseSecondary-Button'
                                        disabled={props.disableButton}
                                    >
                                        <UiIcon
                                            icon={[
                                                'fas',
                                                collapse.secondary ? 'arrow-down-from-line' : 'arrow-up-to-line'
                                            ]}
                                            size='lg'
                                            fixedWidth
                                            color={WHITE}
                                        />
                                    </Fab>
                                </Tooltip>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </>

            <ActionCover style={{ height: 'auto' }}>
                {props.zoom.zoomLevels.length > 0 && (
                    <>
                        <UiButton
                            testid='Statistics-PreviousZoom-Button'
                            variant='outlined'
                            onClick={props.previousZoom}
                        >
                            {translate('t.previous_zoom')}
                        </UiButton>
                        <UiButton variant='outlined' testid='Statistics-ResetZoom-Button' onClick={props.resetDateTime}>
                            {translate('t.reset_zoom')}
                        </UiButton>
                    </>
                )}
            </ActionCover>
            <WarningCover>{props.showWarningMsg && translate('p.days_period_usupported')}</WarningCover>
        </StatisticsTopBarContainer>
    );
};
