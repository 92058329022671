import React from 'react';
import { useRecoilValue } from 'recoil';
import { VehicleMapPopupStatisticsViewProps } from './VehicleMapPopupStatistics.type';
import { MapActionData } from '../../states/global/Map';
import { useTranslation } from 'react-i18next';
import { VehicleMapPopupStatisticsContentView, StatisticsFooter } from './VehicleMapPopupStatistics.style';
import { CustomPopupTable } from '../VehicleMapPopup/VehicleMapPopup.style';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import UiCircularProgress from '../Ui/Components/UiCircularProgress';
import useConverter from '../CustomHooks/Converter/Converter';
import UiLink from '../Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { DARK_STEEL_GREY, BACKGROUND_BLUE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

export const VehicleMapPopupStatisticsContent: React.FC<VehicleMapPopupStatisticsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const mapActionData = useRecoilValue(MapActionData);
    const { fromServerToUserUnit, convertType } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    return props.isLoading ? (
        <UiCircularProgress />
    ) : (
        <VehicleMapPopupStatisticsContentView data-testid='VehicleMapPopupStatisticsContent'>
            <TableContainer>
                <CustomPopupTable size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell>{translate('t.average_speed')}</TableCell>
                            <TableCell align='right' data-testid='VehicleMapPopupStatisticsContent-TableCell-avgSpeed'>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: props.statisticsData?.avgSpeed || 0,
                                    displayUnits: true,
                                    fixed: 2
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.max_speed')}</TableCell>
                            <TableCell align='right' data-testid='VehicleMapPopupStatisticsContent-TableCell-maxSpeed'>
                                {fromServerToUserUnit({
                                    type: convertType.speed,
                                    value: props.statisticsData?.maxSpeed || 0,
                                    displayUnits: true,
                                    fixed: 2
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.max_breaking')}</TableCell>
                            <TableCell align='right' data-testid='VehicleMapPopupStatisticsContent-TableCell-breaking'>
                                {fromServerToUserUnit({
                                    type: convertType.acceleration,
                                    value: props.statisticsData?.breaking || 0,
                                    displayUnits: true,
                                    fixed: 2
                                })}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{translate('t.max_acceleration')}</TableCell>
                            <TableCell
                                align='right'
                                data-testid='VehicleMapPopupStatisticsContent-TableCell-acceleration'
                            >
                                {fromServerToUserUnit({
                                    type: convertType.acceleration,
                                    value: props.statisticsData?.acceleration || 0,
                                    displayUnits: true,
                                    fixed: 2
                                })}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </CustomPopupTable>
                <StatisticsFooter>
                    <UiLink
                        data-testid='VehicleMapPopupStatisticsContent-UiLink-statisticsGraphs'
                        $padding='0'
                        content={translate('t.statistics_graphs')}
                        url={`/vehicle/${mapActionData?.clickedVehicle}/statistics`}
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: DARK_STEEL_GREY,
                            dark: BACKGROUND_BLUE
                        })}
                    />
                </StatisticsFooter>
            </TableContainer>
        </VehicleMapPopupStatisticsContentView>
    );
};
