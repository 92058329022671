import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../../states/global/Theme';

export const TooltipContent = styled.div<{ showAlienData?: boolean }>`
    padding: 10px;
    background-color: ${(props) => (props.showAlienData ? 'rgba(255, 251, 233, 0.9)' : 'rgba(255, 255, 255, 0.9)')};
    ${({ showAlienData }) => {
        const backgroundTheme: string =
            useTheme().palette.mode === ThemeMode.light ? 'rgba(255, 255, 255, 0.9)' : 'rgb(29 38 48 / 85%)';
        const background: string = showAlienData ? 'rgba(255, 251, 233, 0.9)' : backgroundTheme;

        return applyStyleByMode({
            styleJade: `background-color: ${background};`
        });
    }}
    text-align: left;
    border: solid thin transparent;
    display: table;
    border-color: ${(props) => (props.showAlienData ? 'rgba(224, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.9)')};
    table {
        float: left;
    }
    table:nth-child(2) {
        margin-left: 20px;
    }

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        max-width: 200px;
        padding: 05px 0 5px 10px;
    }
`;

export const ValueTd = styled.td`
    padding-left: 10px;
`;

export const ColoredTr = styled.tr<{ $color?: string }>`
    color: ${(props) => props.color || '#000'};
`;
