import { ERROR, ERROR_DARK } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'states/global/Theme';

export const LinkContainer = styled.div`
    width: 100%;
    text-align: center;
`;

export const SensorRedIcon = styled(UiIcon)`
    color: ${() => `${useTheme().palette.mode === ThemeMode.dark ? ERROR_DARK : ERROR}`} !important;
`;
