import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    IconContainer,
    IconTemperatureAlertDisabledContainer,
    LegendIcon,
    LegendItemContainer,
    LegendListContainer
} from './TemperaturePressureTableLegend.style';
import { TemperaturePressureTableLegendProps } from './TemperaturePressureTableLegend.type';
import { useTranslation } from 'react-i18next';
import Legend from 'components/Ui/Components/UiLegend';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { BACKGROUND_PAPER_DARK_MODE, BACKGROUND_TABLE_DARK, WHITE } from 'components/Ui/colors';
import {
    faChartMixedUpCircleCurrency,
    faTruckClock,
    faThermometerThreeQuarters
} from '@fortawesome/pro-solid-svg-icons';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const createListLegent = (title: string, icon: JSX.Element): JSX.Element => (
    <LegendItemContainer>
        {icon} {title}
    </LegendItemContainer>
);

export const TemperaturePressureTableLegend: React.FC<TemperaturePressureTableLegendProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    const contentUiLegend = (): JSX.Element => {
        return (
            <LegendListContainer>
                {createListLegent(
                    `${translate('t.alert_level')} 1`,
                    <LegendIcon $background='#fffda2' $borderColor='#C6C71F' />
                )}
                {createListLegent(
                    `${translate('t.alert_level')} 2`,
                    <LegendIcon $background='#fbdaa8' $borderColor='#FFA559' />
                )}
                {createListLegent(
                    `${translate('t.alert_level')} 3`,
                    <LegendIcon $background='#f9d7d7' $borderColor='#FA000C' />
                )}
                {createListLegent(
                    `${translate('p.measured_more_30m_ago')}`,
                    <LegendIcon $background='#8496af' $borderColor='#8496af' />
                )}
                {createListLegent(
                    `${translate('t.no_wheel')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: 'rgb(210, 210, 210)',
                            dark: BACKGROUND_TABLE_DARK
                        })}
                        $borderColor={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: 'rgb(210, 210, 210)',
                            dark: BACKGROUND_TABLE_DARK
                        })}
                    />
                )}
                {createListLegent(
                    `${translate('t.there_no_data')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='#bdbdbd'
                        $borderWidth='1px'
                    />
                )}
                {createListLegent(
                    `${translate('t.measured_data')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#bdbdbd',
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderWidth='1px'
                    >
                        125
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('t.hot_pressure')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='#FF1616'
                        $borderWidth='1px'
                    >
                        959
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('t.cold_pressure')}`,
                    <LegendIcon
                        $background={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: WHITE,
                            dark: BACKGROUND_PAPER_DARK_MODE
                        })}
                        $borderColor='blue'
                        $borderWidth='1px'
                    >
                        265
                    </LegendIcon>
                )}
                {createListLegent(
                    `${translate('p.measured_more_1d_ago')}`,
                    <IconContainer>
                        <UiIcon icon={['far', 'stopwatch']} size='lg' />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.vehicle_in_maintenance')}`,
                    <IconContainer>
                        <UiIcon
                            icon={['fas', 'wrench']}
                            size='lg'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.out_of_service')}`,
                    <IconContainer>
                        <UiIcon
                            icon={faTruckClock}
                            size='lg'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.show_statistics')}`,
                    <IconContainer>
                        <UiIcon
                            icon={['fas', 'chart-area']}
                            size='lg'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </IconContainer>
                )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('t.has_temperature_prediction')}`,
                        <IconContainer>
                            <UiIcon
                                size='lg'
                                icon={faChartMixedUpCircleCurrency}
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </IconContainer>
                    )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('p.temperature_prediction_not_working')}`,
                        <IconContainer>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon
                                    size='lg'
                                    icon={['fas', 'slash']}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                                <FontAwesomeIcon
                                    size='lg'
                                    icon={['fas', 'p']}
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: '#0000008a',
                                        dark: WHITE
                                    })}
                                />
                            </span>
                        </IconContainer>
                    )}
                {createListLegent(
                    `${translate('p.faulty_sensor_value')}`,
                    <IconContainer>
                        <UiIcon icon={['fas', 'exclamation']} size='lg' className='exclamation-icon' color='red' />
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.hidden_cold_pressure')} | ${translate('t.hidden_external_sensor_temperature')}`,
                    <IconContainer>
                        <strong>X</strong>
                    </IconContainer>
                )}
                {createListLegent(
                    `${translate('t.external_sensor')}`,
                    <IconContainer>
                        <ExternalIcon
                            style={{
                                width: ThemeMode.easyReadingMode ? '21px' : '11px',
                                height: ThemeMode.easyReadingMode ? '21px' : '11px',
                                fill: applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })
                            }}
                        />
                    </IconContainer>
                )}
                {customerSettings.temperature_prediction &&
                    createListLegent(
                        `${translate('t.temperature_alert_disabled')}`,
                        <IconTemperatureAlertDisabledContainer>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon
                                    icon={faSlashRegular}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                                <FontAwesomeIcon
                                    icon={faThermometerThreeQuarters}
                                    fontSize='16px'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                            </span>
                        </IconTemperatureAlertDisabledContainer>
                    )}
            </LegendListContainer>
        );
    };

    return <Legend minWidth={450} title={translate('t.legend')} content={contentUiLegend()} />;
};
