import { Paginator } from './Paginator.type';
import { ITimeZone, TimezoneModel } from './TimeZone.type';
import { ExternalSystemModel } from './ExternalSystem.type';

export type CustomerModel = {
    name: string;
    enabled: boolean;
    logo: string;
    updatedAt: string;
    externalTemperature: boolean;
};

export type CustomerTableModel = {
    items: CustomerExtendedModel[];
    paginator: Paginator;
};

export type CustomerExtendedModel = {
    externalTemperatureEnabled: boolean;
    jiraOrganisationId: number | null;
    hostname: string;
    hasLogo?: boolean;
    registeredAt: Date;
    enabled: boolean;
    updatedAt: Date;
    timezone: TimezoneModel;
    serviceLevel: number | null;
    externalTemperatureEnable: number;
    timezoneOffset: string;
    timezoneOffset2: string;
    deviceSerialConnectionType: {
        id: number;
        connectionType: string;
    } | null;
    logo?: string;
    updatedAtFrom?: string;
    updatedAtTo?: string;
    registeredAtFrom?: string;
    registeredAtTo?: string;
    disabledOrActiveTerminatedAt: boolean;
} & CustomerCodebook;
export interface CustomerEdit extends Omit<CustomerExtendedModel, 'timezone' | 'deviceSerialConnectionType'> {
    timezone: TimezoneModel | { id: number };
    activeTerminated: boolean;
    internalTestSite: boolean;
    hubsMaintenanceConnection: string | boolean | null;
    deviceSerialConnectionType?: {
        id: number;
    } | null;
}

export type CustomerAccess = {
    id: number;
    customer: CustomerExtendedModel;
};

export type CustomerCodebook = {
    id: number;
    hostname?: string;
    name?: string;
    region?: Region | null;
};

export type LogoData = {
    logo: string;
};

export type CustomerCodebookResponse = CustomerCodebook[];

export type Region = {
    id: number;
    shortName: string;
    translationString: string;
};

export type CustomerByIdResponse = {
    customer: CustomerForm & { hasExternalSystem: { id: number; externalSystem: ExternalSystemModel }[] };
};

export interface CustomerForm
    extends Pick<CustomerExtendedModel, 'id' | 'name' | 'enabled' | 'externalTemperatureEnabled'> {
    timezone?: ITimeZone;
    registeredAt?: string;
    dbAppRegion: string;
    repAppRegion: string;
    hostname: string;
    autoGenerateCmd7?: boolean;
    autoGenerateApn?: boolean;
    activeTerminatedAt: string | null;
    hubsMaintenanceConnection: string | boolean | null;
    internalTestSite: boolean;
    deviceSerialConnectionTypeId: number | null;
    timezoneId: number | null;
    deviceSerialConnectionType?: {
        id: number;
        connectionType: string;
    } | null;
    hasExternalSystem?: ExternalSystemModel[];
    jiraOrganisationId: null | number;
    itrackStockPrimaryDeliveryLocationId: number | null;
    disabledOrActiveTerminatedAt?: boolean;
}

export interface CustomerFormToSend extends Omit<CustomerForm, 'hasExternalSystems'> {
    hasExternalSystems?: { id?: number; externalSystem?: number }[];
    reEnableCustomer?: boolean;
}

export type editLogoProps = { id: number; data: string | ArrayBuffer };

export type editLogoData = {
    logo: editLogoProps['data'];
};

export type editCheckProps = {
    id: number;
    externalTemperatureEnabled: boolean;
};

export type editCheckData = {
    externalTemperatureEnabled: editCheckProps['externalTemperatureEnabled'];
};

export enum CustomerQueryKeys {
    getLogo = 'customer-getLogo',
    getAllAsCodebook = 'customer-getAllAsCodebook',
    getAvailableAsCodebook = 'customer-getAvailableAsCodebook',
    getCodebook = 'customer-getCodebook',
    getById = 'customer-getById',
    getByCriteria = 'customer-getByCriteria',
    getCustomersByRegion = 'customer-getCustomersByRegion'
}
