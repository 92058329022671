import { Badge, Tab } from '@mui/material';
import styled from 'styled-components';

export const AlertsTabContainer = styled.div``;

export const AlertsTabCustomer = styled(Tab)<{ $badge?: boolean }>`
    &.MuiButtonBase-root {
        flex-flow: row wrap;
        flex-direction: row-reverse !important;
        ${({ $badge }) => `
            &.MuiTab-root {
                padding: ${$badge ? '0px 28px 0px 15px' : '0px 15px'};
            }
            & .MuiTab-wrapper {
                flex-direction: row;
                align-items: end;
                svg {
                    margin-right: 3px;
                }
            }
        `}
    }
`;

export const AlertBadge = styled(Badge)<{ $color?: string }>`
    .MuiBadge-badge {
        min-width: 13px !important;
        height: 17px;
        background: ${({ $color }) => $color || '#0091d9'} !important;
        color: #fff !important;
        right: -10px !important;
        top: 2px !important;
        padding: 0px 5px;
        border-radius: 4px;
    }
`;
