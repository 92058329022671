import { WheelPrediction } from '../TemperaturePredictionPopover/TemperaturePredictionPopover.atom';

export type TemperaturePredictionActionMenuProps = {
    wheelPredictions?: Partial<WheelPrediction>[];
};

export enum AlertLevels {
    LEVEL_0 = 0,
    LEVEL_1 = 1,
    LEVEL_2 = 2,
    LEVEL_3 = 3
}
