import { TextField } from '@mui/material';
import styled from 'styled-components';

export const ContainerFormChangePassword = styled.div`
    min-height: 137px;
    max-width: 25%;
    margin: 0 auto;
    padding-top: 35px;

    @media (max-width: 1440px) {
        max-width: 55%;
    }

    @media (max-width: 769px) {
        max-width: 95%;
    }
`;

export const ChangePasswordFormContent = styled.div``;

export const CustomTextField = styled(TextField)``;

export const ContainerButton = styled.div`
    float: right;
    padding-right: 10px;
`;
