import styled from 'styled-components';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { TextField } from '@mui/material';

export const HeatmapTopBarContainer = styled.div<{ height?: string }>`
    display: flex;
    justify-content: center;
    align-items: flex-start;

    ${(props) => {
        return `
                height: ${props.height ? props.height : 'auto'};
                padding-top: 5px;
                @media (max-width: 426px) {
                    height: auto;
                    margin-bottom: 5px;
                    display: block;
                    .MuiButton-root{
                        margin-top: 5px;
                    }
                }
        `;
    }}
`;

export const DateTimePicker = styled(DatePicker)``;

export const Button = styled(UiButton)``;

export const StyledTextField = styled(TextField)`
    &.MuiFormControl-root {
        padding: 0 3px 0 0;
    }
`;
