import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export type TemperaturePressureTableProps = {};

export type TemperaturePressureTableViewProps = {
    tempAndPressState: IinicialStateTempPressTable;
    setTableDisplay: (value: TableViewTypeActionsEnum) => void;
    handleRefresh: (value: boolean) => void;
    setFilterByAlert: (value: number) => void;
    setValueInputSearch: (value: string) => void;
    componentRef: React.MutableRefObject<React.ReactInstance | null>;
    showScroll: boolean;
    setShowScroll: (value: boolean) => void;
    sortingByTable: (column: string, order: 'desc' | 'asc') => void;
    setFilterVehicleGroup: (value: number | string, title: string | JSX.Element) => void;
    tempPredServiceProblem: boolean;
    setTempPredServiceProblem: (value: boolean) => void;
};

export enum TempePressTableActionsEnum {
    SET_FILTER_BY_VEHICLE_NAME = 'SET_FILTER_BY_VEHICLE_NAME',
    SET_FILTER_ALERT_LEVEL = 'SET_ALERT_LEVEL',
    SET_TABLE_VIEW_TYPE = 'SET_TABLE_VIEW_TYPE',
    SET_REFRESH = 'SET_REFRESH',
    SET_FILTER_VEHICLE_GROUP = 'SET_FILTER_VEHICLE_GROUP'
}

export enum TableViewTypeActionsEnum {
    COLD = 'cold',
    COLD_OPT = 'cold_opt',
    HOT = 'hot',
    HOT_OPT = 'hot_opt',
    ALL = 'all',
    DIFF = 'diff'
}

export enum AlertLevelEnum {
    ALERT_LEVEL_0 = 0,
    ALERT_LEVEL_1 = 1,
    ALERT_LEVEL_2 = 2,
    ALERT_LEVEL_3 = 3
}

export type FilterVehicleGroupType = {
    title: string;
    value: number;
};

export type ActionButtonInfoType = {
    title: string;
    icon: IconDefinition;
    onClick: () => void;
};

export type IinicialStateTempPressTable = {
    filterVehicleName: string;
    filterAlertLevel: number;
    tableViewType: TableViewTypeActionsEnum;
    filterVehicleGroup: FilterVehicleGroupType;
    refresh: boolean;
};

export type SetFilterTempPress = {
    type: TempePressTableActionsEnum.SET_FILTER_BY_VEHICLE_NAME;
    payload: string;
};

export type SetFilterTempAlertPress = {
    type: TempePressTableActionsEnum.SET_FILTER_ALERT_LEVEL;
    payload: number;
};

export type SetTableViewType = {
    type: TempePressTableActionsEnum.SET_TABLE_VIEW_TYPE;
    payload: TableViewTypeActionsEnum;
};

export type SetRefreshType = {
    type: TempePressTableActionsEnum.SET_REFRESH;
    payload: boolean;
};

export type SetVehicleGroupType = {
    type: TempePressTableActionsEnum.SET_FILTER_VEHICLE_GROUP;
    payload: FilterVehicleGroupType;
};

export type TempPressActionType =
    | SetFilterTempPress
    | SetTableViewType
    | SetFilterTempAlertPress
    | SetRefreshType
    | SetVehicleGroupType;
