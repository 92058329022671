import React from 'react';
import { CustomerReportWidgetStyle } from './CustomerReportWidget.style';
import UiWidget from 'components/Ui/Components/UiWidget';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { CustomerReportWidgetViewProps } from './CustomerReportWidget.type';
import CustomerReportWidgetFormik from './CustomerReportWidget.formik';
import GetCustomerReportData from 'components/Queries/CustomerReport';
import { UserInfo } from 'states/global/User';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const CustomerReportWidgetView: React.FC<CustomerReportWidgetViewProps> = ({ mutation }) => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <CustomerReportWidgetStyle>
            <GetCustomerReportData
                customerId={userInfo.user?.customer.id || 0}
                options={{
                    staleTime: cacheTimeToMilliseconds(5, 'minutes'),
                    cacheTime: cacheTimeToMilliseconds(5, 'minutes')
                }}
            >
                {({ isLoading, data }) => (
                    <UiWidget
                        title={translate('t.customer_report')}
                        testid='Customer-UiWidget'
                        applyPadding={false}
                        loading={isLoading}
                        avatar={
                            <UiIcon
                                data-testid='CustomerTableAvatar'
                                icon={faUser}
                                size='lg'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        }
                    >
                        {data ? <CustomerReportWidgetFormik data={data} mutation={mutation} /> : <></>}
                    </UiWidget>
                )}
            </GetCustomerReportData>
        </CustomerReportWidgetStyle>
    );
};

export default CustomerReportWidgetView;
