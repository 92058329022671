import styled from 'styled-components';
import { TextField } from '@mui/material';

export const HeatMapSpeedIncidentViewContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 10px 0;
    @media (max-width: 426px) {
        height: 115px;
        display: block;

        .MuiButton-root {
            margin-top: 5px;
        }
    }
`;

export const LoadedStatus = styled(TextField)`
    &.MuiTextField-root {
        width: 200px;
        margin-left: 4px;
    }
`;
