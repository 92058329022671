import { TableCell, TableRow, Tooltip } from '@mui/material';
import { BACKGROUND_PAPER_DARK_MODE, BACKGROUND_TABLE } from 'components/Ui/colors';

import styled from 'styled-components';
import { ThemeMode, ThemeType } from 'states/global/Theme';

export const TempAndPressListBodyViewContent = styled.div``;

export const CustomTableRow = styled(TableRow)``;
export const CustomTooltip = styled(Tooltip)``;

export const ColumnStickyBody = styled(TableCell)<{
    $lef?: string | number;
    $minWidth?: string | number;
    $rowNumber: number;
    $notBorderRight?: boolean;
    $theme?: ThemeType;
    align: string;
}>`
    ${({ align }) => (align ? `text-align: ${align} !important; ` : '')}

    ${({ $notBorderRight }) => ($notBorderRight ? 'border-right: none !important;' : '')}

    ${({ $theme, $minWidth }) => {
        return `
            min-width: ${$minWidth ? `${$minWidth}px` : '0px'};
            background-color: ${$theme?.mode === ThemeMode.light ? BACKGROUND_TABLE : BACKGROUND_PAPER_DARK_MODE};
        `;
    }}

    @media (min-width: 768px) {
        position: sticky;
        left: ${(props) => `${props.$lef ?? '0'}px`} !important;
        z-index: 1;
    }
`;

export const ContainerIconStatus = styled.div<{ $height: number }>`
    display: inline-flex;
    margin: 0 auto;
    width: max-content;
    min-width: 55px;
    min-height: ${({ $height }) => $height}px;
    line-height: ${({ $height }) => $height}px;
    align-items: center;
`;
