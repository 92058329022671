import styled from 'styled-components';
import { TableCell, TableHead, TableRow, TableSortLabel, useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import { BACKGROUND_DARK_MODE, WHITE } from 'components/Ui/colors';

export const TempAndPressListHeaderViewContent = styled.div``;

export const CustomTableCell = styled(TableCell)<{ $maxWidth?: number; $minWidth?: number }>`
    max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : 'auto')};
    min-width: ${({ $minWidth }) => ($minWidth ? `${$minWidth}px` : 'auto')};
`;
export const CustomTableHead = styled(TableHead)``;
export const CustomTableRow = styled(TableRow)``;

export const TableCellSubHeaderSticky = styled(TableCell)<{
    $lef?: string | number;
    $minWidth?: string | number;
    $tableDisplay?: string;
    $isPortalOld?: boolean;
}>`
    top: ${(props) => (props.$isPortalOld ? '32px' : '43px')} !important;
    text-transform: uppercase;
    left: 0;
    z-index: 2;
    position: sticky;
    min-width: 15px;

    ${(props) => {
        const colorsDark = {
            cold: '#00647a',
            cold_opt: '#00647a',
            hot: '#8d0000',
            hot_opt: '#8d0000',
            diff: '#00647a'
        };

        const colors = {
            cold: '#e8f5f3',
            cold_opt: '#e8f5f3',
            hot: '#FFE3E3',
            hot_opt: '#FFE3E3',
            diff: '#e8f5f3'
        };

        return `
            background: ${
                useTheme().palette.mode === ThemeMode.light
                    ? `${props.$tableDisplay ? colors[props.$tableDisplay] : WHITE} !important;`
                    : `${props.$tableDisplay ? colorsDark[props.$tableDisplay] : BACKGROUND_DARK_MODE} !important;`
            }
        `;
    }}
`;

export const ColumnStickyHeader = styled(TableCell)<{ $lef?: string | number; $minWidth?: string | number }>`
    z-index: 3 !important;
    min-width: ${(props) => `${props.$minWidth}px !important` ?? '0px !important'};
    max-width: 100px !important;

    ${() => useTheme().breakpoints.between('sm', 'xl')} {
        position: sticky;
        left: ${(props) => `${props.$lef}px` ?? '0px'} !important;
    }
`;

export const ColumnTableSortLabel = styled(TableSortLabel)<{ $active?: boolean }>`
    svg {
        ${(props) => {
            const color = {
                light: '#b3b3b3',
                dark: 'rgba(255, 255, 255, 0.7)'
            };

            const colorActive = {
                light: '#000',
                dark: '#40ffa6'
            };

            return `
                color: ${
                    props.$active ? colorActive[useTheme().palette.mode] : color[useTheme().palette.mode]
                } !important;
            `;
        }}

        @media only screen and (max-width: 1535px) {
            margin-top: -2px;
        }

        @media only screen and (min-width: 1536px) {
            margin-top: -3px;
        }
    }
`;
