import { VehicleModel } from './Vehicle.type';

export type VehicleGroupModel = {
    icon: string | null;
    id: number;
    maxSpeed: number | null;
    name: string;
    maxSpeedTemperatureL1: number;
    maxSpeedTemperatureL2: number;
    maxSpeedTemperatureL3: number;
    temperatureBasedMaxSpeed: boolean;
    vehicle: Pick<VehicleModel, 'id' | 'name'>[];
};

export enum VehicleGroupQueryKeys {
    get = 'VehicleGroup-get',
    getCodebook = 'VehicleGroup-getCodebook',
    getFile = 'VehicleGroup-getFile',
    getTable = 'VehicleGroup-getTable',
    getById = 'VehicleGroup-getById',
    getByCriteria = 'VehicleGroup-getByCriteria',
    patchById = 'VehicleGroup-patchById',
    putById = 'VehicleGroup-putById'
}

export type DropdownModel = Omit<VehicleGroupModel, 'vehicle'>;

export type VehicleGroupsCodeBook = VehicleGroupModel[];

export type TableModelResponse = {
    items: VehicleGroupModel[];
};
