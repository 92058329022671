import { TyreTempPressReport } from 'models/Tyre.type';
import { FilterVehicleGroupType } from '../TemperaturePressureTable/TemperaturePressureTable.type';

export type TemperaturePressureListProps = {
    tableDisplay: string;
    valueInputSearch: string;
    filterByAlert: number;
    handleRefresh: (value: boolean) => void;
    refresh: boolean;
    setFilterByAlert: (value: number) => void;
    showScroll: boolean;
    filterVehicleGroup: FilterVehicleGroupType;
    setTempPredServiceProblem: React.Dispatch<boolean>;
};

export type TemperaturePressureListViewProps = {
    tableDisplay: string;
    vehiclesList: TyreTempPressReport[];
    containsRecomValues: () => boolean;
    orderDirection: Sorting | null;
    handleSortRequest: (column: string, order: 'desc' | 'asc' | null, date?: boolean) => void;
    maxPosition: number;
    showScroll: boolean;
};

export enum TemperaturePressureActionsEnum {
    SET_VEHICLES_LIST = 'SET_VEHICLES_LIST',
    SET_ORDER_DIRECTION = 'SET_ORDER_DIRECTION',
    SET_LOADING = 'SET_LOADING',
    SET_WET_TYRES = 'SET_WET_TYRES',
    SET_MAX_POSITION = 'SET_MAX_POSITION'
}

export type IinicialStateTemperaturePressure = {
    vehiclesList: TyreTempPressReport[];
    maxPosition: number;
    orderDirection: Sorting | null;
    loandingContent: boolean;
    wetTyres: boolean;
};

export type ITemperaturePressure = {
    vehicles: TyreTempPressReport[];
    maxPosition: number;
};

export type Sorting = {
    column: string;
    order: 'desc' | 'asc' | null;
};

export type SetTemperaturePressureListType = {
    type: TemperaturePressureActionsEnum.SET_VEHICLES_LIST;
    payload: TyreTempPressReport[];
};

export type SetOrderDirectionType = {
    type: TemperaturePressureActionsEnum.SET_ORDER_DIRECTION;
    payload: Sorting | null;
};

export type SetMaxPosition = {
    type: TemperaturePressureActionsEnum.SET_MAX_POSITION;
    payload: number;
};

export type SetLoadingOrWetTyres = {
    type: TemperaturePressureActionsEnum.SET_LOADING | TemperaturePressureActionsEnum.SET_WET_TYRES;
    payload: boolean;
};

export type TemperaturePressureActionType =
    | SetTemperaturePressureListType
    | SetOrderDirectionType
    | SetLoadingOrWetTyres
    | SetMaxPosition;
