import styled from 'styled-components';
import { Table, TableCell, TableHead, useTheme } from '@mui/material';
import { ThemeMode } from '../../../../../states/global/Theme';
import {
    BACKGROUND_TABLE,
    WHITE,
    BACKGROUND_PAPER_DARK_MODE,
    ALERT_LEVEL_1,
    ALERT_LEVEL_2,
    ALERT_LEVEL_3,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3_DARK
} from '../../../../Ui/colors';
import { SIZE_TEXT } from '../../../../Ui/variables';

const backgroundColor = {
    light: WHITE,
    dark: BACKGROUND_PAPER_DARK_MODE
};

const alertColors = {
    light: {
        1: ALERT_LEVEL_1,
        2: ALERT_LEVEL_2,
        3: ALERT_LEVEL_3
    },
    dark: {
        1: ALERT_LEVEL_1_DARK,
        2: ALERT_LEVEL_2_DARK,
        3: ALERT_LEVEL_3_DARK
    }
};

export const MainDetailsContent = styled.div`
    margin-top: 10px;
`;

export const AlertsTableContent = styled.div`
    padding: 30px 15px !important;
`;

export const TableCustom = styled(Table)`
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: ${SIZE_TEXT} !important;

    & tbody td:first-child {
        font-weight: bold;
        text-align: center;
    }
    & tbody #default {
        text-align: left !important;
        padding-left: 10px !important;
    }
    & tr td {
        padding: 8px 10px !important;
        font-size: ${SIZE_TEXT} !important;
        vertical-align: middle !important;
        border-left: 1px solid red !important;
    }
`;

export const TableLegend = styled(Table)`
    fontsize: '14px';
    width: '300px';
`;

export const SimpleCell = styled(TableCell)<{ alertLevel: number; borderGap?: number }>`
    border: 2px solid ${() => backgroundColor[useTheme().palette.mode]};
    border-right: ${(props) => (props.borderGap ? props.borderGap : 2)}px solid
        ${() => backgroundColor[useTheme().palette.mode]};
    &.MuiTableCell-root {
        &.numbers {
            text-align: right;
        }
    }

    &.MuiTableCell-head {
        background-color: ${(props) => alertColors[useTheme().palette.mode][props.alertLevel]} !important;
    }
`;

export const CustomTableHead = styled(TableHead)`
    & .MuiTableCell-head {
        background-color: ${() => (useTheme().palette.mode === ThemeMode.light ? BACKGROUND_TABLE : 'transparent')};
    }
`;

export const HeaderTable = styled(TableCell)<{ $minWidth?: number; borderGap?: number }>`
    font-weight: 600;
    min-width: ${({ $minWidth }) => `${$minWidth ?? '0'}px`};
    padding: 0 !important;
    padding-bottom: 5px !important;
    font-weight: bold;
    text-align: center;
    line-height: 25px;
    border: 2px solid ${() => backgroundColor[useTheme().palette.mode]};
    border-right: ${(props) => (props.borderGap ? props.borderGap : 2)}px solid
        ${() => backgroundColor[useTheme().palette.mode]};
    p::first-letter {
        text-transform: uppercase;
    }
    text-align: center;
`;
