import Fab from '@mui/material/Fab';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { BACKGROUND_PAPER_DARK_MODE, PRIMARY, PRIMARY_LIGHT, SECONDARY, WHITE } from 'components/Ui/colors';
import { Box, Button, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { Chip } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const AlertCustomContent = styled.div<{ $displayBody?: boolean; $disableRedirect?: boolean }>`
    .popUpAlert {
        padding: 10px;
        border-radius: 15px;
        min-height: ${(props) => (props.$displayBody ? 100 : 0)}px;
        padding-bottom: 10px;
        box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.25);
        margin-bottom: 15px;
    }
    text-align: left;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
        td {
            border-bottom: solid thin #d1d1d1;
            font-size: ${common.textSize};
        }
        `;
    }}

    ${(props) => !props.$disableRedirect && 'cursor: pointer;'}
`;

export const AlertContent = styled.div<{ level: number; $displayBody?: boolean; $float?: boolean }>`
    padding: 12px;
    box-shadow: 4px 4px 10px #0000001a;
    border-radius: 4px;
    min-height: ${(props) => (props.$displayBody ? 100 : 0)}px;
    padding-bottom: 10px;
    margin-bottom: ${(props) => (props.$float ? 0 : 15)}px;
    background-image: ${(props) => {
        const alertColors = {
            light: {
                1: 'rgb(255, 237, 158) 9%, rgb(255, 251, 233) 62%',
                2: 'rgb(255, 208, 163) 9%, rgb(255, 232, 209) 62%',
                3: 'rgb(255, 159, 159) 9%, rgb(255, 204, 204) 62%'
            },
            dark: {
                1: 'rgb(118 98 0) 9%,rgb(161 133 0) 62%',
                2: 'rgb(145 72 0) 9%,rgb(213 107 0) 62%',
                3: 'rgb(106 2 2) 9%,rgb(179 15 15) 62%'
            }
        };
        return `linear-gradient(to right, ${alertColors[useTheme().palette.mode][props.level]})`;
    }};

    & table {
        border-spacing: 0px;
    }

    & td {
        padding: 3px 0 2px 0;
        border-bottom: ${(props) => {
            switch (props.level) {
                case 0:
                    return 'transparent';
                case 1:
                    return '1px solid rgb(255 227 107)';
                case 2:
                    return '1px solid rgb(255 186 120)';
                case 3:
                    return '1px solid rgb(255 128 128)';
            }
        }};
    }
`;

export const ChipLevelContainer = styled.div`
    display: table;
    margin: 0 auto !important;
`;

export const ChipLevel = styled(Chip)<{ level: number; clickable?: boolean }>`
    border-radius: 4px !important;
    min-width: 60px !important;
    background-color: ${(props) => {
        switch (props.level) {
            case 0:
                return PRIMARY;
            case 1:
                return 'rgb(159, 148, 21)';
            case 2:
                return 'rgb(205, 110, 10)';
            case 3:
                return 'rgb(224, 0, 0)';
        }
    }} !important;
    color: #fff !important;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            font-size: ${common.textSize} !important;
        `;
    }}
    line-height: 14px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;

    &:hover {
        ${({ clickable, level }) => {
            if (clickable) {
                switch (level) {
                    case 0:
                        return `
                            background-color: ${PRIMARY} !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 1:
                        return `
                            background-color: #7e7611 !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 2:
                        return `
                            background-color: #a3590b !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 3:
                        return `
                            background-color: #b10a0a !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                }
            }
            return '';
        }}
    }

    ${() => {
        if (useTheme().palette.mode === ThemeMode.dark) {
            return `
                border: 1px solid rgb(255 255 255 / 50%);
            `;
        }
    }}
`;

export const AlertFooter = styled.div`
    text-align: right;
    padding-top: 5px;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            font-size: ${common.textSize};
        `;
    }}

    ${() => {
        if (useTheme().palette.mode === ThemeMode.light) {
            return `color: ${PRIMARY};`;
        }
    }}
`;

export const SettingButton = styled.button`
    svg {
    }
`;

export const AlertBtnSetting = styled.div`
    float: right;
    margin-right: 5px;
    margin-top: -3px;
    button {
        background: transparent;
        border: none;
        margin-top: -2px;
        padding-right: 0;
        margin-right: -3px;
        widht: 20px;
        height: 20px;
        svg {
            font-size: 24px;
            ${() => {
                return `
                    color: ${useTheme().palette.mode === ThemeMode.light ? '#9c9c9c' : '#3a414a'} !important;
                `;
            }}
            cursor: pointer;

            &:hover {
                ${() => {
                    return `
                        color: ${useTheme().palette.mode === ThemeMode.light ? '#4e4e4e' : '#fff'} !important;
                    `;
                }}
            }
        }
    }
`;

export const AlertsByVehicleContent = styled.div`
    padding: 0px !important;
    padding: 0px;
    width: 100%;
    margin: auto;
    & .MuiCardContent-root {
        padding: 0px !important;
    }
`;

export const SectionHeader = styled(Typography)`
    border-bottom: solid thin #ebebeb;
`;

export const CustomTable = styled.table`
    min-width: 100%;
    tr {
        td {
            width: 50%;
        }
        td:last-child {
            position: relative;
            padding-left: 1em;
        }
    }
    .copy-code {
        background: ${PRIMARY};
        color: ${SECONDARY};
        padding: 5px 10px;
        height: 51px;
        border-radius: 4px;
        :hover {
            cursor: pointer;
            &:after {
                right: 2em;
                top: 50%;
                padding: 0 10px;
                transform: translateY(-50%);
                position: absolute;
                content: 'Click to copy';
                background: ${PRIMARY};
            }
        }
        pre {
            line-height: 2em;
        }
    }
`;

export const GroupOption = styled.div`
    width: 100%;
    border: thin #bfbfbf solid;
    position: relative;
    margin-top: 17px;
    margin-bottom: 10px;
    border-radius: 4px;
    height: 120px;
`;

export const AlertRefresh = styled.div`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'background: #fdfdfd !important;',
            dark: `background: ${BACKGROUND_PAPER_DARK_MODE} !important;`
        });
    }}

    text-align: center;
    border-radius: 0px 0px 4px 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    box-shadow: 6px 6px 20px #0000001a;
    padding: 0px !important;
    margin-top: -25px;
`;

export const FabCustom = styled(Fab)`
    background-color: transparent !important;
    box-shadow: none !important;
`;

export const ButtonRefresh = styled(Button)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${PRIMARY_LIGHT} !important;`,
            dark: `color: ${WHITE} !important;`
        });
    }}

    border-radius: none !important;
    padding: 2px 6px !important;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize || '13px'} !important;`;
    }}
    svg {
        color: #0b9444 !important;
        margin-right: 4px !important;
    }
`;

export const ModalContent = styled.div`
    padding: 10px 0px;
    font-size: 13px;
    text-align: center;
`;

export const LoadingProgress = styled(CircularProgress)({
    color: `${PRIMARY} !important`
});

export const PopUpFilterContainer = styled.div`
    display: inline;
    width: 100px;
`;

export const FilterLabel = styled.span`
    position: absolute;
    margin-top: -11px;
    margin-left: 8px;
    padding: 0 5px;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            background: ${useTheme().palette.mode === ThemeMode.light ? WHITE : '#37414c'};
            color: ${useTheme().palette.mode === ThemeMode.light ? 'rgba(0, 0, 0, 0.54)' : WHITE};
            font-size: ${common.textSize};
        `;
    }}
`;

export const FilterCover = styled(Box)`
    &.MuiBox-root {
        display: inline-block;
        width: 100%;
    }
`;

export const FilterContent = styled.div`
    margin: 10px;
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;
`;

export const CustomIconButton = styled(UiIconButton)``;
