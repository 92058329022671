import React, { useMemo } from 'react';
import { Table } from '@mui/material';
import {
    LeftActionBar,
    RightActionBar,
    TableCover,
    TableLoader,
    TableTopbar,
    UiTableMainViewContent,
    UiTableViewContent
} from './UiTable.style';
import UiTableExportDropdownContent from '../UiTableExportDropdown/UiTableExportDropdown';
import UiTableHideColumns from '../UiTableHideColumns/UiTableHideColumns';
import UiTableRefreshBtn from '../UiTableRefreshBtn/UiTableRefreshBtn';
import UiTableAction from '../UiTableAction/UiTableAction';
import UiTableFooter from '../UiTableFooter/UiTableFooter';
import UiTableSkeletonLoader from '../UiTableSkeletonLoader/UiTableSkeletonLoader';
import UiTablePrintWrapper from '../UiTablePrintWrapper/UiTablePrintWrapper';
import UiTableFilterColumnsBtn from '../UiTableFilterColumnsBtn/UiTableFilterColumnsBtn';
import UiTableInstance from '../UiTableInstance/UiTableInstance';
import UiTableChipBar from '../UiTableChipBar/UiTableChipBar';

export const UiTableContent = <K extends ({ ...args }) => ReturnType<K>>(props): JSX.Element => {
    const skeletonLoader = useMemo(() => <UiTableSkeletonLoader />, []);

    return (
        <UiTableViewContent data-testid={`${props.queryKey}-UiTableContent`}>
            {props.isLoading && skeletonLoader}
            {!props.isLoading && (
                <>
                    <TableTopbar
                        container
                        marginBottom={1}
                        spacing={1}
                        data-testid={`TableView-Topbar-${props.queryKey}Table`}
                    >
                        <LeftActionBar
                            item
                            xs={12}
                            md={7}
                            data-testid={`TableView-LeftActionBar-${props.queryKey}Table`}
                        >
                            {props.exportFn && (
                                <UiTableExportDropdownContent
                                    exportFn={props.exportFn}
                                    queryKey={props.queryKey}
                                    exportName={props.exportName}
                                    additionalProps={props.additionalProps}
                                    onlyCSVExport={props.onlyCSVExport}
                                />
                            )}
                            <UiTableAction actionBtns={props.leftActionBtns} queryKey={props.queryKey} />
                            {!props.useColumnFiltering && !props.hideFilterAction && (
                                <UiTableChipBar queryKey={props.queryKey} />
                            )}
                        </LeftActionBar>
                        <RightActionBar
                            data-testid={`TableView-RightActionBar-${props.queryKey}Table`}
                            item
                            sx={{
                                justifyContent: { sm: 'left', md: 'right' },
                                textAlign: { sm: 'left', md: 'right' }
                            }}
                        >
                            <UiTableAction actionBtns={props.rightActionBtns} queryKey={props.queryKey} />
                            <UiTableRefreshBtn
                                queryKey={props.queryKey}
                                onlyIconBtns={props.onlyIconsForDefActionBtns}
                            />
                            {!props.useColumnFiltering && !props.hideFilterAction && (
                                <UiTableFilterColumnsBtn
                                    queryKey={props.queryKey}
                                    onlyIconBtns={props.onlyIconsForDefActionBtns}
                                />
                            )}
                            <UiTableHideColumns
                                queryKey={props.queryKey}
                                hiddenColumns={props.hiddenColumns}
                                onlyIconBtns={props.onlyIconsForDefActionBtns}
                            />
                        </RightActionBar>
                    </TableTopbar>
                    <TableCover>
                        {props.isLoadingFetching && (
                            <TableLoader data-testid={`TableView-TableLoader-${props.queryKey}Table`} />
                        )}
                        <UiTableMainViewContent data-testid={`TableView-MainViewContent-${props.queryKey}Table`}>
                            <UiTablePrintWrapper
                                queryKey={props.queryKey}
                                exportName={props.exportName}
                                minHeight={props.minHeight}
                                maxHeight={props.maxHeight}
                            >
                                {props.data && (
                                    <UiTableInstance
                                        {...props}
                                        data={props.data}
                                        pageCount={props.pageCount}
                                        paginator={props?.paginator}
                                        isLoadingFetching={props.isLoadingFetching}
                                        isLoading={props.isLoading}
                                        data-testid={`TableView-TableInstance-${props.queryKey}Table`}
                                    />
                                )}
                            </UiTablePrintWrapper>
                        </UiTableMainViewContent>
                        <Table>
                            <UiTableFooter key={`UiTableFooter-${props.queryKey}`} queryKey={props.queryKey} />
                        </Table>
                    </TableCover>
                </>
            )}
        </UiTableViewContent>
    );
};
