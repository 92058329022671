import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { LINK } from '../../Ui/colors';
import { FormControl } from '@mui/material';

export const AdministrationTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 100%;
    && .MuiCardContent-root {
        padding: 0px 10px 10px 10px;
        font-size: 13px !important;
    }
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
        margin: 5px 10px;
        overflow-wrap: break-word;
    }
`;

export const A = styled.a`
    color: ${LINK};
`;

export const ButtonHeaderWidget = styled.div`
    padding-right: 8px;
`;

export const InfoIconContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding-left: 3px;

    & .MuiSvgIcon-root {
        font-size: 18px;
    }
`;

export const LabelIconInfoContainer = styled.div`
    display: inline-flex;
    align-content: center;
`;

export const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin: 4px 0 8px 0 !important;
    }
`;
