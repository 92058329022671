import { VehicleModel } from 'models/Vehicle.type';
import { VehicleGroupModel } from 'models/VehicleGroup.type';
import { atom } from 'recoil';

type VehicleTypeAddProps = Partial<VehicleGroupModel & { enableTemperatureCheckbox: boolean }> & { action: string };

export const VehicleTypeAddState = atom<VehicleTypeAddProps>({
    key: 'VehicleTypeAddState',
    default: {
        action: '',
        id: 0,
        name: '',
        icon: 'adt1_Yellow.png',
        maxSpeed: 0,
        maxSpeedTemperatureL1: 0,
        maxSpeedTemperatureL2: 0,
        maxSpeedTemperatureL3: 0,
        vehicle: [],
        enableTemperatureCheckbox: false
    }
});
