import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const UiTableColumnFilterContent = styled.div``;

export const FilterTextField = styled(TextField)`
    &.MuiTextField-root {
        margin: 4px 0 8px 0;
        margin-left: 0px;
        && label {
            padding: 0 5px 0 0;
        }

        & svg {
            fill: #999999;
        }
    }
`;
