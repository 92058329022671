import styled from 'styled-components';
import { Grid, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { BACKGROUND_BLUE, SUCCESS, WHITE } from '../../Ui/colors';
import { ThemeMode } from '../../../states/global/Theme';

export const ExportedReportsContainer = styled.div`
    width: 100%;
    & .MuiCardContent-root {
        font-size: 13px;
        padding: 0px !important;
    }
`;

export const ExportedReportsList = styled(Grid)`
    & .MuiTableCell-root {
        padding: 0px !important;
    }
    & .MuiTableCell-body {
        ${() => {
            return `color: ${useTheme().palette.mode === ThemeMode.light ? '#3a3633' : WHITE} !important;`;
        }}
    }
    & .MuiTableCell-sizeSmall {
        padding: 0px !important;
    }
    & .MuiTableHead-root {
        & .MuiTableCell-head {
            padding: 6px 24px 6px 16px !important;
        }
    }
`;

export const CustomCell = styled.div<{ background: string; $selected?: boolean }>`
    padding: 6px 24px 6px 16px;
    line-height: 30px !important;
    ${({ background }) => {
        return `background-color: ${useTheme().palette.mode === ThemeMode.light ? background : BACKGROUND_BLUE};`;
    }}
    filter: ${(props) => (props.$selected ? 'brightness(0.9)' : 'brightness(1)')};
    button {
        ${() => {
            return `color: ${useTheme().palette.mode === ThemeMode.light ? '#3a3633' : WHITE} !important;`;
        }}
    }
`;

export const ReportName = styled.div`
    height: 30px;
    text-overflow: ellipsis !important;
    overflow: hidden;
`;

export const IconStatus = styled.span<{ color?: string }>`
    margin-left: 10px;
    ${() => {
        return `color: ${useTheme().palette.mode === ThemeMode.light ? '#3a3633' : WHITE} !important;`;
    }}
`;

export const ExportedReportsDetail = styled(Grid)`
    & .MuiCardContent-root {
        padding: 0px 10px 10px 10px !important;
    }
`;
export const ButtonAction = styled(Button)<{ skin?: string; $marginLeft?: boolean }>`
    ${(props) => {
        if (!props.disabled) {
            return `color: ${useTheme().palette.mode === ThemeMode.light ? '#3a3633' : WHITE} !important;`;
        }
    }}

    ${(props) =>
        props?.skin === 'success' &&
        `
                border-color: ${SUCCESS} !important;
                color: ${SUCCESS} !important;
            `}

    ${(props) =>
        props?.$marginLeft &&
        `
                margin-left: 10px !important;
            `}

    font-size: 11px !important;
    & svg {
        font-size: 14px !important;
        ${(props) => {
            if (!props.disabled) {
                return `color: ${useTheme().palette.mode === ThemeMode.light ? '#3a3633' : WHITE} !important;`;
            }
        }}
    }
`;
