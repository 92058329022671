import PortalApi from 'helpers/api/PortalApiClient';
import { NotificationAssignationModel } from '../models/NotificationAssignation.type';
import { getParams } from 'helpers';
import { GetParamsProps } from 'models/Table.type';

export default class NotificationAssignation extends PortalApi<NotificationAssignationModel> {
    route = 'notification-assignation';

    getAllExport = (props: GetParamsProps): Promise<string> => {
        const params = getParams(props);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { page, limit, ...Newparams } = params;

        return this.getByCriteria({
            extendUrl: 'all/export',
            criteria: Newparams
        });
    };
}
