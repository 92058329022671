import { useTheme } from '@mui/material';
import styled from 'styled-components';
import Button from '../../Ui/Components/UiButton';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleStatsContent = styled.div`
    & .MuiTableCell-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `
                font-size: ${common.textSize} !important; 
            `;
        }}
    }

    &&.MuiCard-root {
        margin-button: 0px;
    }
`;

export const ContainerTemporal = styled.div<{ width?: string; height?: number }>`
    width: ${(props) => props.width};
    max-height: ${(props) => props.height}px;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const ContainerActionsButtonsHeaderWidget = styled.div`
    display: flex;
    padding: 0px 0px;
    align-items: center;
`;

export const TitleMainSubTable = styled.span`
    font-weight: 600;
    padding: 10px;
    display: block;
`;

export const LegendBody = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
    font-weight: 500;
    width: 310px;
    padding-left: 10px;
`;

export const ContainerMessageServerError = styled.div`
    width: 100%;
    text-align: center;
`;

export const CustomButton = styled(Button)``;

export const LegendListContainer = styled.ul`
    list-style: none;
    padding: 0px;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize} !important;`;
    }}
    display: inline-grid;
    line-height: 1.7;
`;

export const LegendItemContainer = styled.li`
    display: inline-flex;
    width: 100%;
`;

export const TemperatureLegendIcon = styled.i`
    padding-right: 16px;
    padding-top: 3px;
`;
