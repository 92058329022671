import styled from 'styled-components';
import { ERROR } from '../../components/Ui/colors';
import UiButton from '../../components/Ui/Components/UiButton';

export const CustomButton = styled(UiButton)``;

export const HeaderRefetchTime = styled.div`
    width: 100px;
`;

export const NoRevisionLabel = styled('span')`
    color: ${ERROR};
`;
