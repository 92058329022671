import styled from 'styled-components';
import { Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { DARK_STEEL_GREY } from 'components/Ui/colors';

export const CustomerRedirectionFormContainer = styled.div`
    padding: 15px;
`;

export const StyledCard = styled(Card)`
    width: auto;
    max-width: 360px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;

export const StyledCardHeader = styled(CardHeader)`
    background-color: ${DARK_STEEL_GREY};
    &&& span {
        color: #fff;
    }
`;

export const StyledCardContent = styled(CardContent)`
    padding: 16px;
`;

export const StyledCardActions = styled(CardActions)`
    justify-content: flex-end;
`;

export const ContainerText = styled.div`
    margin-top: 10px;
`;
