import React from 'react';
import Modal from '../Modal';
import { useRecoilValue } from 'recoil';
import { VehicleTypeAddState } from 'states/component/VehicleType';
import * as Yup from 'yup';
import Button from '../Ui/Components/UiButton';
import { Form, Formik } from 'formik';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import {
    AddVehicleTypeContainer,
    CustomCheckbox,
    CustomTextField,
    RemoveTextBox,
    Text,
    TextDiv
} from './AddVehicleType.style';
import { AddVehicleTypeViewProps } from './AddVehicleType.type';
import { ERROR, SUCCESS } from '../Ui/colors';
import IconPicker from '../Ui/Components/UiIconPicker';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { SIZE_BUTTON, VARIANT_BUTTON } from '../Ui/variables';
import useConverter from '../CustomHooks/Converter/Converter';

export const AddVehicleTypeContent: React.FC<AddVehicleTypeViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const addVehicleTypeState = useRecoilValue(VehicleTypeAddState);
    const modalLabel = {
        add: translate('t.vehicle_group_add'),
        edit: translate('t.vehicle_group_edit'),
        remove: `${translate('t.vehicle_group_remove')} - ${addVehicleTypeState.name}`
    };
    const isRemoveAction: boolean = addVehicleTypeState.action === 'remove';
    const isEditAction: boolean = addVehicleTypeState.action === 'edit';
    const basicColors: string[] = ['Darkgrey', 'White', 'Red', 'Blue', 'Yellow'];
    const vehicleURL = 'mining/48x48';
    const { displayUserUnits } = useConverter();

    const button = (): JSX.Element => {
        const textButton: string = isEditAction ? 'save' : 'add';
        const buttonType: string = isRemoveAction ? 'error' : 'success';

        return (
            <Button
                testid={`VehicleType-${buttonType}-${textButton}-action-button`}
                skin={buttonType}
                size={SIZE_BUTTON}
                variant={VARIANT_BUTTON}
                color={isRemoveAction ? 'error' : 'primary'}
                form='vehicle-type-form'
                type='submit'
                disabled={false}
                loading={props.isLoading}
                onClick={() => {
                    isRemoveAction && props.removeVehicleType();
                }}
            >
                {isRemoveAction ? translate('t.remove') : translate(`t.${textButton}`)}
            </Button>
        );
    };

    const FormSchema = Yup.object().shape({
        name: Yup.string().min(2, 'Too Short!').required(translate('t.required')),
        maxSpeed: Yup.number().required(translate('t.required'))
    });

    const createRemoveMessage = (): JSX.Element => {
        return (
            <RemoveTextBox>
                <TextDiv>
                    <Text>{translate('p.are_you_sure_to_delete')}</Text>
                </TextDiv>
            </RemoveTextBox>
        );
    };

    const content: JSX.Element = (
        <Formik
            initialValues={props.initValues}
            validationSchema={FormSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                props.sendData(values);
            }}
        >
            {({ errors, touched, handleChange, values, setFieldValue }) => {
                return (
                    <Form id='vehicle-type-form'>
                        <Grid paddingTop={2} container sx={{ marginBottom: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={8}>
                                    <CustomTextField
                                        data-testid='vehicle-group'
                                        id='vehicle-type'
                                        name='name'
                                        error={!!errors.name && touched.name}
                                        label={translate('t.group')}
                                        value={values.name}
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        helperText={errors.name && touched.name ? errors.name : ''}
                                        onChange={handleChange}
                                        inputProps={{
                                            form: {
                                                autocomplete: 'off'
                                            },
                                            'data-testid': 'vehicle-group-form-Group-input'
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomTextField
                                        datetest-id='vehicle-group-max-speed'
                                        id='vehicle-max-speed'
                                        name='maxSpeed'
                                        error={!!errors.maxSpeed && touched.maxSpeed}
                                        label={translate('t.max_speed')}
                                        value={values.maxSpeed}
                                        variant='outlined'
                                        size='small'
                                        fullWidth
                                        helperText={errors.maxSpeed && touched.maxSpeed ? errors.maxSpeed : ''}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>{displayUserUnits.speed}</InputAdornment>
                                            )
                                        }}
                                        type='number'
                                        inputProps={{
                                            step: 0.1,
                                            min: 0,
                                            'data-testid': 'vehicle-group-form-Max-Speed-input'
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sm={2} md={2} lg={2}>
                                    <IconPicker
                                        testid='Vehicle-Group-Add-Type-IconPicker'
                                        defaultIcon={props.initValues.icon || ''}
                                        data={[
                                            {
                                                group: `${vehicleURL}/adt1_Yellow.png`,
                                                colors: basicColors
                                            },
                                            {
                                                group: `${vehicleURL}/adt2_Yellow.png`,
                                                colors: basicColors
                                            },
                                            {
                                                group: `${vehicleURL}/adt_Yellow.png`,
                                                colors: basicColors
                                            },
                                            {
                                                group: `${vehicleURL}/dumptruck_Yellow.png`,
                                                colors: basicColors.concat(['Pink', 'Rainbow'])
                                            },
                                            {
                                                group: `${vehicleURL}/grader_Yellow.png`,
                                                colors: basicColors
                                            },
                                            {
                                                group: `${vehicleURL}/loader_Yellow.png`,
                                                colors: basicColors
                                            },
                                            {
                                                group: `${vehicleURL}/undeground_Yellow.png`,
                                                colors: basicColors
                                            }
                                        ]}
                                        style={{
                                            margin: '8px',
                                            marginLeft: '0'
                                        }}
                                        onIconSelect={(icon) => {
                                            //props.setIcon(icon);
                                            setFieldValue('icon', icon);
                                        }}
                                    />
                                </Grid>
                                {isEditAction && (
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CustomCheckbox
                                            testid='vehicle-group-enable-temperature-checkbox'
                                            name='enableTemperatureCheckbox'
                                            label={translate('t.temperature_on_speed_alerts')}
                                            skin='primary'
                                            skinlabel={true}
                                            checked={values.enableTemperatureCheckbox}
                                            onChange={handleChange}
                                        />
                                        <Typography marginBottom={1}>
                                            {translate('t.max_speed_for_temperature')}
                                        </Typography>

                                        <Grid container spacing={1}>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextField
                                                    datetest-id='max-speed-temperature-1'
                                                    id='maxSpeedTemperatureL1'
                                                    name='maxSpeedTemperatureL1'
                                                    error={!!errors.name && touched.name}
                                                    label={`${translate('t.level')} 1`}
                                                    variant='outlined'
                                                    size='small'
                                                    fullWidth
                                                    type='number'
                                                    defaultValue={0}
                                                    value={values.maxSpeedTemperatureL1}
                                                    disabled={!values.enableTemperatureCheckbox}
                                                    helperText={errors.name && touched.name ? errors.name : ''}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextField
                                                    datetest-id='max-speed-temperature-2'
                                                    id='maxSpeedTemperatureL2'
                                                    name='maxSpeedTemperatureL2'
                                                    error={!!errors.name && touched.name}
                                                    label={`${translate('t.level')} 2`}
                                                    variant='outlined'
                                                    size='small'
                                                    defaultValue={0}
                                                    value={values.maxSpeedTemperatureL2}
                                                    disabled={!values.enableTemperatureCheckbox}
                                                    fullWidth
                                                    type='number'
                                                    helperText={errors.name && touched.name ? errors.name : ''}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <CustomTextField
                                                    datetest-id='max-speed-temperature-3'
                                                    id='maxSpeedTemperatureL3'
                                                    name='maxSpeedTemperatureL3'
                                                    error={!!errors.name && touched.name}
                                                    label={`${translate('t.level')} 3`}
                                                    variant='outlined'
                                                    size='small'
                                                    defaultValue={0}
                                                    value={values.maxSpeedTemperatureL3}
                                                    disabled={!values.enableTemperatureCheckbox}
                                                    fullWidth
                                                    type='number'
                                                    helperText={errors.name && touched.name ? errors.name : ''}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                {displayUserUnits.speed}
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    inputProps={{
                                                        form: {
                                                            autocomplete: 'off'
                                                        },
                                                        step: 0.1,
                                                        min: 0
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );

    return (
        <AddVehicleTypeContainer data-testid='AddVehicleTypeContent'>
            <Modal
                testid='vehicle-group-modal'
                id='add-vehicle-type-modal'
                type='ShowVehicleTypesAddModal'
                leftTitle={modalLabel[addVehicleTypeState.action]}
                content={isRemoveAction ? createRemoveMessage() : content}
                width={isRemoveAction ? 420 : isEditAction ? 750 : undefined}
                buttons={button()}
                cancelColor={isRemoveAction ? ERROR : SUCCESS}
                onClose={() => {
                    props.resetValues();
                }}
                forceClose={props.forceCLose}
            />
        </AddVehicleTypeContainer>
    );
};
