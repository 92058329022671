import { styled } from '@mui/system';
import { Box, TableCell, TableContainer, TableRow } from '@mui/material';

export const VehicleStatsTableContent = styled(Box)`
    & .MuiTableCell-root {
        border-bottom: none;
    }

    & .MuiTableContainer-root {
        overflow-x: auto;
        overflow-y: auto;
    }

    & .MuiCardContent-root {
        padding: 5px 0px !important;
    }
`;

export const TableContainerVehicle = styled(TableContainer)`
    max-height: 465px;
    min-height: 465px;
`;

export const HeaderTable = styled(TableCell)<{ $minWidth?: number }>`
    font-weight: 600;
    min-width: ${({ $minWidth }) => `${$minWidth ?? '0'}px`};
`;

export const RowHeaderTable = styled(TableRow)`
    & .MuiTableCell-root {
        border-bottom: thin solid #c0c0c0;
        padding: 5px 8px !important;
    }
`;
