import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GlobalAdminContent } from './GlobalAdmin.view';
import { GlobalAdminProps } from './GlobalAdmin.type';
import { Cell, CustomLoading, TempCheckBox, Text, UiButton, ImageCell } from './GlobalAdmin.style';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import Menu from 'components/Ui/Components/UiMenu';
import {
    Autocomplete,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useTranslation } from 'react-i18next';
import { CustomerExtendedModel, CustomerQueryKeys } from 'models/Customer.type';
import CustomerApi from 'api/Customer';
import { Success } from 'components/Popup/Popup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import AddGlobalAdmin from '../AddGlobalAdmin/AddGlobalAdmin';
import LogoGlobalAdmin from './Components/LogoGlobalAdmin/LogoGlobalAdmin';
import { EditLogoAtom } from './Components/LogoGlobalAdmin/LogoGlobalAdmin.atom';
import useConverter from 'components/CustomHooks/Converter/Converter';
import SetupMapArea from 'components/SetupMapArea/SetupMapArea';
import Region from 'api/Region';
import DeviceSerialConnectionTypeApi from 'api/DeviceSerialConnectionType';
import { SIZE_BUTTON, SIZE_TEXT_VARIANT } from 'components/Ui/variables';
import { parseData } from '../AddGlobalAdmin/AddGlobalAdmin';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { DeviceSerialConnectionTypeList } from 'models/Device.type';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import useModal from 'components/CustomHooks/ModalHook';
import EnableCustomer from 'components/Customer/EnableCustomer';
import EnableButton from 'components/Ui/Components/UiButton';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { RegionQueryKeys } from 'models/Region.type';
import { DeviceSerialConnectionQueryKeys } from 'models/DeviceSerialConnection.type';
import Device from 'api/Device';
import ReleaseAllHubs from '../ReleaseAllHubs/ReleaseAllHubs';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import { CurrentExternalSystems } from 'models/ExternalSystem.type';

const customerApi = new CustomerApi();
const deviceApi = new Device();
const regionApi = new Region();
const deviceSerialConnectionType = new DeviceSerialConnectionTypeApi();

const GlobalAdmin: React.FC<GlobalAdminProps> = (): JSX.Element => {
    const queryClient = useQueryClient();
    const [customerCheckId, setCustomerCheckId] = useState<number | null>(null);
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const [deviceSerialList, setDeviceSerialList] = useState<optionsCodeBook[]>([]);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`globalAdminCustomers-QueryFn`));
    const setEditLogoTableAtom = useSetRecoilState(EditLogoAtom);
    const refEditCustomer = useRef<HTMLButtonElement>(null);
    const { fromUTCToTimezone } = useConverter();
    const { setModalState } = useModal();

    const updateModal = (isOpen?: boolean) => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: (
                    <ModalButton skin='success' data-testid='updateModal-ModalButton'>
                        <CircularProgress size='1.75em' />
                    </ModalButton>
                ),
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const { isLoading: regionLoading, data: regionData } = useQuery(
        [RegionQueryKeys.getAllAsCodebook],
        () => regionApi.getAllAsCodebook(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const { isLoading: serialConnectionLoading } = useQuery(
        [DeviceSerialConnectionQueryKeys.getDeviceSerialConnectionType],
        () => deviceSerialConnectionType.getDeviceSerialConnectionType(),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours'),
            onSuccess: (results) => setDeviceSerialList(parseData(results))
        }
    );

    const { mutate: removeCustomerMutate, isLoading: removeCustomerLoading } = useMutation(customerApi.removeCustomer, {
        onSuccess: () => {
            Success({
                text: translate('t.removed_customer')
            });
        },
        onSettled: () => {
            updateModal(false);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            setEditLogoTableAtom(true);
        }
    });

    const handleModalRemove = (id: number, name: string) => {
        const contentModalDelete = {
            id: 'DeleteAdminCustomersActions',
            leftTitle: `${translate('t.remove_customer')}`,
            content: (
                <Text variant='h6' data-testid='handleModalRemove-Text'>
                    {translate('p.are_you_sure_to_delete')} {'-'} {name} {'-'}
                </Text>
            ),
            buttons: (
                <ModalButton
                    skin='error'
                    color='error'
                    variant='contained'
                    data-testid='handleModalRemove-ModalButton'
                    onClick={() => {
                        removeCustomerMutate(id);
                    }}
                >
                    {removeCustomerLoading ? (
                        <CircularProgress data-testid='handleModalRemove-CircularProgress-remove' size='1.75em' />
                    ) : (
                        translate('t.remove')
                    )}
                </ModalButton>
            ),
            width: 600,
            height: 170,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalDelete,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalDelete,
                isOpen: true
            }
        });
    };

    const {
        mutate: getCustomerByIdMutate,
        isLoading: getCustomerByIdLoading,
        data: getCustomerByIdData
    } = useMutation(customerApi.getCustomerById);

    const { mutate: editCustomerMutate, isLoading: editCustomerLoading } = useMutation(customerApi.editCustomer, {
        onSuccess: () => {
            Success({
                text: translate('t.edited_customer')
            });
        },
        onSettled: () => {
            updateModal(false);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            setEditLogoTableAtom(true);
        }
    });

    const { mutate: releaseAllHubsMutate } = useMutation(deviceApi.unassignAll, {
        onSuccess: () => {
            Success({
                text: translate('t.hubs_released')
            });
        },
        onSettled: () => {
            updateModal(false);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            setEditLogoTableAtom(true);
        }
    });

    const updateModalEdit = (isOpen?: boolean) => {
        const contentModalEdit = {
            id: 'EditCustomerActions',
            leftTitle: `${translate('t.edit_customer')}`,
            content: (
                <>
                    {getCustomerByIdLoading ? (
                        <UiLoadingPage size='25px' data-testid='updateModalEdit-CircularProgress-AddGlobalAdmin' />
                    ) : (
                        <AddGlobalAdmin
                            data-testid='updateModalEdit-AddGlobalAdmin'
                            customerValues={{
                                id: getCustomerByIdData?.customer?.id || 0,
                                name: getCustomerByIdData?.customer?.name || '',
                                registeredAt: getCustomerByIdData?.customer?.registeredAt || '',
                                dbAppRegion: getCustomerByIdData?.customer?.dbAppRegion || '',
                                repAppRegion: getCustomerByIdData?.customer?.repAppRegion || '',
                                hostname: getCustomerByIdData?.customer?.hostname || '',
                                enabled: getCustomerByIdData?.customer?.enabled || false,
                                timezone: getCustomerByIdData?.customer?.timezone,
                                externalTemperatureEnabled:
                                    getCustomerByIdData?.customer?.externalTemperatureEnabled || false,
                                autoGenerateCmd7: getCustomerByIdData?.customer?.autoGenerateCmd7 || false,
                                activeTerminatedAt: getCustomerByIdData?.customer?.activeTerminatedAt || null,
                                hubsMaintenanceConnection:
                                    getCustomerByIdData?.customer?.hubsMaintenanceConnection || null,
                                internalTestSite: getCustomerByIdData?.customer?.internalTestSite || false,
                                deviceSerialConnectionTypeId:
                                    getCustomerByIdData?.customer?.deviceSerialConnectionType?.id || null,
                                timezoneId: getCustomerByIdData?.customer?.timezone?.id || null,
                                hasExternalSystem: getCustomerByIdData?.customer?.hasExternalSystem.map(
                                    (externalSystem: CurrentExternalSystems) => {
                                        return externalSystem.externalSystem;
                                    }
                                ),
                                jiraOrganisationId: getCustomerByIdData?.customer?.jiraOrganisationId || null,
                                itrackStockPrimaryDeliveryLocationId:
                                    getCustomerByIdData?.customer.itrackStockPrimaryDeliveryLocationId || null
                            }}
                            oldExternalSystems={getCustomerByIdData?.customer?.hasExternalSystem}
                            refEditCustomer={refEditCustomer}
                            editCustomerMutate={editCustomerMutate}
                        />
                    )}
                </>
            ),
            buttons: (
                <>
                    <SetupMapArea
                        customerId={getCustomerByIdData?.customer?.id || 0}
                        data-testid='updateModalEdit-SetupMapArea'
                    />
                    <ModalButton
                        data-testid='updateModalEdit-ModalButton'
                        skin='success'
                        variant='contained'
                        color='primary'
                        size={SIZE_BUTTON}
                        onClick={() => {
                            refEditCustomer?.current && refEditCustomer?.current.click();
                        }}
                    >
                        {editCustomerLoading ? (
                            <CircularProgress
                                size='1.75em'
                                data-testid='updateModalEdit-CircularProgress-SaveCustomer'
                            />
                        ) : (
                            translate('t.save_customer')
                        )}
                    </ModalButton>
                </>
            ),
            width: 750,
            height: 420,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalEdit,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalEdit,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const handleModalEdit = () => {
        updateModalEdit(true);
    };

    const { mutate: handleChecked, isLoading: checkedLoading } = useMutation(customerApi.editCheck, {
        onSuccess: () => {
            Success({
                text: `${translate('t.edited_customer')} - ${translate('t.external_temperature')}`
            });
        },
        onSettled: () => {
            updateModal(false);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        }
    });

    useQuery<DeviceSerialConnectionTypeList>( //todo why is duplicated
        [DeviceSerialConnectionQueryKeys.getDeviceSerialConnectionType],
        () => deviceSerialConnectionType.getDeviceSerialConnectionType(),
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const columns: Column<CustomerExtendedModel>[] = [
        {
            Header: translate('t.name'),
            accessor: 'name',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => <>{props.row.original.name ?? '-'}</>
        },
        {
            Header: translate('t.region'),
            accessor: 'region',
            disableSortBy: true,
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) =>
                props.row.original.region?.translationString ? (
                    <>{translate(`t.${props.row.original.region?.translationString}`)}</>
                ) : (
                    <>-</>
                ),
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <>
                        {!regionLoading ? (
                            <Autocomplete
                                data-testid='GlobalAdmin-Region-Autocomplete'
                                options={regionData ?? []}
                                getOptionLabel={(region) => (region ? translate(`t.${region.translationString}`) : '')}
                                onChange={(e, newValue) => {
                                    const regionValue = newValue?.id
                                        ? {
                                              name: translate('t.region'),
                                              value: `${newValue?.id}`,
                                              humanValue: translate(`t.${newValue.translationString}`)
                                          }
                                        : undefined;
                                    setFilterValue(regionValue);
                                }}
                                size='small'
                                defaultValue={
                                    regionData && regionData.find((value) => value.id === Number(cellValue?.value))
                                }
                                sx={{ marginTop: '5px' }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        data-testid='GlobalAdmin-Region-TextField'
                                        label={translate(`t.region`)}
                                        variant='outlined'
                                        name='region'
                                        value={column.filterValue?.value || ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            <>
                                <CircularProgress size='1.75em' data-testid='GlobalAdmin-Region-CircularProgress' />
                            </>
                        )}
                    </>
                );
            }
        },
        {
            Header: translate('t.enabled'),
            accessor: 'enabled',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) =>
                props.row.original.enabled ? (
                    <Cell enabled={props.row.original.enabled} data-testid='GlobalAdmin-Enabled-Cell'>
                        {' '}
                        {translate('t.enabled')}
                    </Cell>
                ) : (
                    <Cell enabled={false} data-testid='GlobalAdmin-Disabled-Cell'>
                        {' '}
                        {translate('t.disabled')}
                    </Cell>
                ),
            Filter: ({ column, setFilter, ...filterProps }) => {
                const options = [
                    { label: 'enabled', value: 'true' },
                    { label: 'disabled', value: 'false' }
                ];

                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl
                        variant='outlined'
                        size='small'
                        sx={{ marginTop: '10px' }}
                        data-testid='GlobalAdmin-Enabled-FormControl'
                    >
                        <InputLabel htmlFor='enabled-action' shrink data-testid='GlobalAdmin-Enabled-InputLabel'>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid='GlobalAdmin-Enabled-Select'
                            label={<>{column.Header}</>}
                            value={cellValue?.value || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                    ? { name: column.Header, value: e.target.value }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'enabled',
                                id: 'enabled-action'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem value={option.value} key={index} data-testid='GlobalAdmin-Enabled-MenuItem'>
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.logo'),
            accessor: 'logo',
            disableFilters: true,
            disableSortBy: true,
            width: 110,
            align: 'center',
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => {
                return (
                    <ImageCell data-testid='GlobalAdmin-Logo-ImageCell'>
                        <LogoGlobalAdmin
                            data-testid='GlobalAdmin-Logo-GlobalAdmin'
                            maxWidth='100px'
                            maxHeight='37px'
                            customerId={props.row.original?.id}
                        />
                    </ImageCell>
                );
            }
        },
        {
            Header: translate('t.serial_connection'),
            accessor: 'deviceSerialConnectionType',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => (
                <>{props.row.original.deviceSerialConnectionType?.connectionType ?? '-'}</>
            ),
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <>
                        {!serialConnectionLoading ? (
                            <Autocomplete
                                data-testid='GlobalAdmin-SerialConnection-Autocomplete'
                                options={deviceSerialList ?? []}
                                getOptionLabel={(serialConnection) => serialConnection.name as string}
                                onChange={(e, newValue) => {
                                    const serialConnectionValue = newValue?.id
                                        ? {
                                              name: translate('t.serial_connection'),
                                              value: `${newValue?.id}`,
                                              humanValue: newValue?.name
                                          }
                                        : undefined;
                                    setFilterValue(serialConnectionValue);
                                }}
                                size='small'
                                sx={{ marginTop: '10px' }}
                                defaultValue={
                                    deviceSerialList &&
                                    deviceSerialList.find((value) => value.id === Number(cellValue?.value))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        data-testid='GlobalAdmin-SerialConnection-Textfield'
                                        {...params}
                                        label={translate(`t.serial_connection`)}
                                        variant='outlined'
                                        name='serial_connection'
                                        value={cellValue?.value || ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            <>
                                <CircularProgress
                                    size='1.75em'
                                    data-testid='GlobalAdmin-SerialConnection-CircularProgress'
                                />
                            </>
                        )}
                    </>
                );
            }
        },
        {
            Header: translate('t.updated_at'),
            accessor: 'updatedAt',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => (
                <>{fromUTCToTimezone(props.row.original.updatedAt || '', true) ?? '-'}</>
            ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        data-testid='GlobalAdmin-UpdatedAtFrom-LocalizationProvider'
                    >
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='UpdatedAtFrom'
                            keyFilter='updatedAtFrom'
                            label={`${translate('t.updated_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='UpdatedAtTo'
                            keyFilter='updatedAtTo'
                            label={`${translate('t.updated_at')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.registered_at'),
            accessor: 'registeredAt',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => (
                <>{fromUTCToTimezone(props.row.original.registeredAt || '', true) ?? '-'}</>
            ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider
                        dateAdapter={AdapterLuxon}
                        data-testid='GlobalAdmin-RegisteredAt-LocalizationProvider'
                    >
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='RegisteredAtFrom'
                            keyFilter='registeredAtFrom'
                            label={`${translate('t.registered_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='RegisteredAtTo'
                            keyFilter='registeredAtTo'
                            label={`${translate('t.registered_at')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.show_external_sensor_temperature'),
            accessor: 'externalTemperatureEnabled',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: CustomerExtendedModel } }) => (
                <>
                    {checkedLoading && props.row.original.id === customerCheckId ? (
                        <CustomLoading
                            size={SIZE_TEXT_VARIANT(10)}
                            data-testid='GlobalAdmin-ExternalTemperatureEnabled-CustomLoading'
                        />
                    ) : (
                        <TempCheckBox
                            id='temperature'
                            data-testid='GlobalAdmin-ExternalTemperatureEnabled-TempCheckBox'
                            checked={!!props.row.original.externalTemperatureEnabled}
                            onChange={() => {
                                setCustomerCheckId(props.row.original.id);
                                handleChecked({
                                    id: props.row.original.id,
                                    externalTemperatureEnabled: !props.row.original.externalTemperatureEnabled
                                });
                            }}
                        />
                    )}
                </>
            ),
            Filter: ({ column, setFilter, ...filterProps }) => {
                const options = [
                    { label: 'yes', value: 'true' },
                    { label: 'no', value: 'false' }
                ];

                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl
                        variant='outlined'
                        size='small'
                        sx={{ marginTop: '5px' }}
                        data-testid='GlobalAdmin-ExternalTemperatureEnabled-FormControl'
                    >
                        <InputLabel
                            htmlFor='temperature-action'
                            shrink
                            data-testid='GlobalAdmin-ExternalTemperatureEnabled-InputLabel'
                        >
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            data-testid='GlobalAdmin-ExternalTemperatureEnabled-Select'
                            variant='outlined'
                            value={cellValue?.value || ''}
                            input={
                                <OutlinedInput
                                    notched
                                    label={<>{column.Header}</>}
                                    data-testid='GlobalAdmin-ExternalTemperatureEnabled-OutlinedInput'
                                />
                            }
                            onChange={(e) => {
                                const value = e.target.value
                                    ? { name: column.Header, value: e.target.value }
                                    : undefined;
                                setFilterValue(value);
                            }}
                            inputProps={{
                                name: 'temperature',
                                id: 'temperature-action'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    value={option.value}
                                    key={index}
                                    data-testid='GlobalAdmin-ExternalTemperatureEnabled-MenuItem'
                                >
                                    {translate(`t.${option.label}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.actions'),
            accessor: 'id',
            width: 80,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: CustomerExtendedModel }; value: number }) => (
                <span>
                    <Menu
                        data-testid='GlobalAdmin-Actions'
                        items={
                            props.row.original.enabled
                                ? [
                                      {
                                          title: translate('t.edit'),
                                          value: props.value,
                                          action: () => {
                                              getCustomerByIdMutate(props.value);
                                              handleModalEdit();
                                          }
                                      }
                                  ]
                                : [
                                      {
                                          title: translate('t.remove'),
                                          value: props.value,
                                          action: () => {
                                              handleModalRemove(props.value, props.row.original.name || '');
                                          }
                                      },
                                      {
                                          title: translate('t.release_all_hubs'),
                                          value: props.value,
                                          action: () => {
                                              setModalState({
                                                  leftTitle: translate('p.release_all_hubs_of', {
                                                      customer: props.row.original.name
                                                  }),
                                                  isOpen: true,
                                                  width: 450,
                                                  height: 175,
                                                  content: (
                                                      <ReleaseAllHubs customerName={props.row.original.name || ''} />
                                                  ),
                                                  buttons: (
                                                      <EnableButton
                                                          skin='success'
                                                          variant='contained'
                                                          testid='enable-button'
                                                          onClick={() => {
                                                              releaseAllHubsMutate({
                                                                  id: props.value
                                                              });
                                                              setModalState({});
                                                          }}
                                                      >
                                                          {translate('t.yes')}
                                                      </EnableButton>
                                                  )
                                              });
                                          }
                                      },
                                      {
                                          title: translate('t.enable'),
                                          value: props.value,
                                          action: () => {
                                              setModalState({
                                                  leftTitle: `${translate('t.enable')} ${props.row.original.name}`,
                                                  isOpen: true,
                                                  width: 450,
                                                  height: 175,
                                                  content: (
                                                      <EnableCustomer customerName={props.row.original.name || ''} />
                                                  ),
                                                  buttons: (
                                                      <EnableButton
                                                          skin='success'
                                                          variant='contained'
                                                          testid='enable-button'
                                                          onClick={() => {
                                                              editCustomerMutate({
                                                                  id: props.value,
                                                                  reEnableCustomer: true
                                                              });
                                                              setModalState({});
                                                          }}
                                                      >
                                                          {translate('t.enable')}
                                                      </EnableButton>
                                                  )
                                              });
                                          }
                                      }
                                  ]
                        }
                        rootEl={
                            <UiButton
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                data-testid='GlobalAdmin-Actions-UiButton'
                            >
                                <SettingsIcon data-testid='GlobalAdmin-Actions-SettingsIcon' />
                            </UiButton>
                        }
                    />
                </span>
            )
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'updatedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'updatedAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'registeredAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'registeredAtTo'
        }
    ];

    useEffect(() => {
        updateModal();
    }, [editCustomerLoading, removeCustomerLoading]);

    useEffect(() => {
        updateModalEdit();
    }, [getCustomerByIdLoading]);

    useEffect(() => {
        return () => {
            queryClient.removeQueries([CustomerQueryKeys.getLogo]);
        };
    }, []);

    return <GlobalAdminContent data-testid={'GlobalAdminContent-testid'} columns={columns} />;
};

export default Wrapper(GlobalAdmin);
