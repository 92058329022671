import React, { useMemo } from 'react';
import { AreasTableContent } from './AreasTable.view';
import { AreasTableProps } from './AreasTable.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SettingsIcon from '@mui/icons-material/Settings';
import { CircularProgress } from '@mui/material';
import { AreaColorBackground, AreaColorIcon, AreaName } from './AreasTable.style';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum } from '../../Map/Map.type';
import Menu from '../../Ui/Components/UiMenu';
import Button from '@mui/material/Button';
import { ModalAtom } from 'states/global/Modal';
import { CreateConfirmtionMessage } from '../../StatedModal/ConfirmationMessage';
import { AxiosResponse } from 'axios';
import { AreaFeaturesResponse } from 'models/Area.type';
import { useMutation } from '@tanstack/react-query';
import AreaApi from 'api/Area';

import { ROLES } from 'variables';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from '../../CustomHooks/Converter/Converter';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import { checkRole } from 'helpers/authentication';

const Area = new AreaApi();

const AreasTable: React.FC<AreasTableProps> = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const setMapAction = useSetRecoilState(MapAction);
    const setModalState = useSetRecoilState(ModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`Areas-QueryFn`));
    const { fromUTCToTimezone } = useConverter();

    const { mutate: removeArea } = useMutation<AxiosResponse<{ area: AreaFeaturesResponse }>, Error, number>(
        (areaId) => Area.delete({ id: areaId }),
        {
            onSuccess: () => {
                setMapAction({
                    action: MapActionsEnum.DRAW_AREAS
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
                setModalState({ isOpen: false });
            }
        }
    );

    const columns = useMemo((): Column<AreaFeaturesResponse>[] => {
        let responseColumns: Column<AreaFeaturesResponse>[] = [
            {
                Header: translate('t.name'),
                accessor: 'name',
                width: 100,
                height: 20,
                Cell: (props) => (
                    <AreaName
                        data-testid='AreasTable-AreaName'
                        role='button'
                        tabIndex={0}
                        onClick={() => {
                            setMapAction({
                                action: MapActionsEnum.DRAW_AREA,
                                params: {
                                    data: props.row.original
                                }
                            });
                        }}
                        title={props.value}
                    >
                        {props.value}
                    </AreaName>
                )
            },
            {
                Header: translate('t.created_at'),
                accessor: 'createdAt',
                width: 100,
                height: 20,
                Cell: (props) => {
                    return <>{fromUTCToTimezone(props.value, false)}</>;
                },
                Filter: ({ setFilter, state, ...props }) => {
                    return (
                        <LocalizationProvider
                            dateAdapter={AdapterLuxon}
                            adapterLocale={'en-gb'}
                            data-testid={'AreasTable-LocalizationProvider-createdAt'}
                        >
                            <UiDatePickerFilter
                                applyFiltersFlag={props.applyFiltersFlag}
                                state={state}
                                setFilter={setFilter}
                                testId='CreatedAtFrom'
                                keyFilter='createdAtFrom'
                                label={`${translate('t.created_at_from')}`}
                            />
                            <UiDatePickerFilter
                                applyFiltersFlag={props.applyFiltersFlag}
                                state={state}
                                setFilter={setFilter}
                                testId='CreatedAtTo'
                                keyFilter='createdAtTo'
                                label={`${translate('t.created_at_to')}`}
                            />
                        </LocalizationProvider>
                    );
                }
            },
            {
                Header: `${translate('t.color')}`,
                accessor: 'color',
                width: 50,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => {
                    if (props.value) {
                        return (
                            <AreaColorBackground>
                                <AreaColorIcon
                                    backgroundColor={props.value}
                                    borderColor={props.value.replace(/0\.[0-9]/, '1')}
                                    data-testid={'AreasTable-AreaColorIcon-color'}
                                />
                            </AreaColorBackground>
                        );
                    }
                    return <>-</>;
                }
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'createdAtFrom'
            },
            {
                Header: '',
                disableFilters: true,
                disableSortBy: true,
                accessor: 'createdAtTo'
            },
            {
                Header: translate('t.actions'),
                accessor: 'id',
                width: 50,
                height: 20,
                disableFilters: true,
                disableSortBy: true,
                Cell: (props) => (
                    <Menu
                        data-testid={'AreasTable-Menu-actions'}
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                isLink: true,
                                url: `/area-editor?mode=edit&id=${props.value}`
                            },
                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    const confirmationMessage = CreateConfirmtionMessage({
                                        translate: translate,
                                        messageType: 'DELETE_ITEM',
                                        onClickYes: () => {
                                            removeArea(props.value);

                                            setModalState(
                                                CreateConfirmtionMessage({
                                                    translate: translate,
                                                    messageType: 'DELETE_ITEM',
                                                    butttonTextYes: <CircularProgress size='1.75em' />,
                                                    disabledButttonYes: true,
                                                    onClickYes: () => {
                                                        removeArea(props.value);
                                                    },
                                                    onClickNo: () => {
                                                        setModalState({ isOpen: false });
                                                    }
                                                })
                                            );
                                        },
                                        onClickNo: () => {
                                            setModalState({ isOpen: false });
                                        }
                                    });
                                    confirmationMessage.leftTitle += `${props.row.original.name}`;
                                    setModalState(confirmationMessage);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                data-testid='AreasTable-Button-actions'
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                )
            }
        ];

        if (!checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN])) {
            responseColumns.pop();
        }

        return responseColumns;
    }, []);

    return <AreasTableContent data-testid='AreasTable-testid' columns={columns} />;
};

export default Wrapper(AreasTable);
