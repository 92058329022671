import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import UiCheckbox from '../Ui/Components/UiCheckbox';
export const AddVehicleTypeContainer = styled.div``;

export const RemoveTextBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const TextDiv = styled.div`
    margin: 21px 0;
`;

export const Text = styled(Typography)``;

export const CustomTextField = styled(TextField)``;

export const CustomCheckbox = styled(UiCheckbox)``;
