import styled from 'styled-components';
import { Tab, Tabs } from '@mui/material';

export const DeviceDetailsTableContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 98%;
    && .MuiCardContent-root {
        font-size: 13px !important;
    }

    & .MuiTableHead-root {
        border-bottom: 1px solid #474544;
        background: #f9f9f9;
    }
    margin-top: 10px;
`;

export const DeviceDetailsTabsContainer = styled.div`
    display: inline-block !important;
    width: 100% !important;
`;

export const DeviceDetailsTabs = styled(Tabs)`
    border: none !important;
    & .MuiTab-root {
        min-width: 13% !important;
        text-transform: none;
        font-size: 1.1rem;
        font-weight: 600;
    }
    & .MuiTab-wrapper {
        flex-direction: row;
    }
    & .MuiTab-labelIcon {
        min-height: auto;
    }
    & .MuiTabs-scroller {
        overflow-x: auto !important;
    }
    & .MuiTabs-flexContainer {
        padding-right: 12px;
        justify-content: flex-end;
    }
`;

export const DeviceTab = styled(Tab)`
    &.MuiTab-root {
        font-size: 16px;
    }
`;

export const LastTransmittedHeader = styled.span`
    margin: 0px 5px;
`;
