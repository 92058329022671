import { Alert, Paper } from '@mui/material';
import styled from 'styled-components';
import { styled as styleMui } from '@mui/system';

export const SetAlertsTempPressureWidgetContainer = styled(Paper)``;

export const DefaultAlertInfo = styleMui(Alert)({
    width: '100%',
    minHeight: 36,
    padding: '0 16px',
    marginBottom: '10px'
});
