import styled from 'styled-components';
import { Card } from '@mui/material';

export const SensorsTablesContainer = styled.div`
    overflow-y: auto;
    width: 100%;
    .MuiTab-root {
        padding: 0 10px !important;
    }

    & .MuiTab-root {
        min-width: 100px;
    }

    & .MuiTab-root:nth-child(2) {
        min-width: 165px;
    }

    && .MuiTable-root {
        min-width: 100%;
    }

    && .MuiCardContent-root {
        padding: 10px 0px;
    }
`;

export const ContainerExtraFilter = styled.div`
    padding-right: 23px;
`;

export const CustomCardTab = styled(Card)`
    &&& {

        & .MuiTabs-root {
            border-bottom none !important;
            min-height: 30px;
        }

        & .MuiCardHeader-root {
            padding: 0px;
            border-bottom: 1px solid #c0c0c0;
        }

        & .MuiTabScrollButton-root {
            display: none;
        }


    }
`;

export const SensorTableContainer = styled.div`
    padding: 10px;
`;
