import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { PRIMARY } from '../../Ui/colors';

export const VehicleDetailsSchemaViewContent = styled.div`
    width: 100%;
`;

export const LoadingDiv = styled.div`
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
`;

export const Loading = styled(CircularProgress)({
    color: `${PRIMARY} !important`,
    width: '50px !important',
    height: '50px !important'
});

export const SchemaDiv = styled.div<{ noBorder?: boolean }>`
    @media (max-width: 599px) {
        height: 120%;
    }
`;
