import React, { useCallback, useMemo, useState } from 'react';
import {
    ButtonsCover,
    ContainerButton,
    CustomBodyCell,
    CustomBodyRow,
    CustomTable,
    LisPageContent,
    ListItemCustom,
    NotificationsSettingsFormContent as NotificationsSettingsFormContentStyle
} from './NotificationsSettingsForm.style';
import {
    Alert,
    Box,
    Checkbox,
    Collapse,
    Grid,
    ListItemText,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Button from '../../Ui/Components/UiButton';
import { useTranslation } from 'react-i18next';
import ButtonsToTestAlerts from '../ButtonsToTestAlerts/ButtonsToTestAlerts';
import { ContainerBodyTab } from '../ProfileTabManager/ProfileTabManager.style';
import {
    CheckboxSetting,
    FirstCellType,
    NotificationDeviceType,
    NotificationsSettingsFormViewProps,
    RowData,
    SettingGroupProps
} from './NotificationsSettingsForm.type';
import { Type2Enum, TypeEnum } from 'models/Notification.type';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useRecoilValue } from 'recoil';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { hasAccessToCustomer } from 'helpers/authentication';

const SettingCheckbox = ({ value, allowed, deviceType, setData, type, index, type2 }: CheckboxSetting): JSX.Element => {
    return (
        <Checkbox
            color='primary'
            checked={value}
            disabled={!allowed}
            onChange={(e) => {
                const value = e.target.checked;
                setData((current) => {
                    let a = { ...current };
                    if (deviceType === NotificationDeviceType.MOBILE && value) {
                        a[type][type2][index][NotificationDeviceType.WHATSAPP]['value'] = false;
                    }
                    if (deviceType === NotificationDeviceType.WHATSAPP && value) {
                        a[type][type2][index][NotificationDeviceType.MOBILE]['value'] = false;
                    }
                    a[type][type2][index][deviceType]['value'] = value;
                    return a;
                });
            }}
            data-testid={`ButtonToActive-NotificationsBy-Portal-in-${type}-${type2}`}
        />
    );
};

const FirstCell = ({ index, type, type2, groupCount, notificationLabel }: FirstCellType): JSX.Element => {
    const { t: translate } = useTranslation();
    if (groupCount > 1) {
        if (index === 0) {
            return (
                <CustomBodyCell align='center' colSpan={3} rowSpan={groupCount}>
                    {translate(notificationLabel(type, type2))}
                </CustomBodyCell>
            );
        }
        return <></>;
    }
    return (
        <CustomBodyCell align='center' colSpan={3}>
            {translate(notificationLabel(type, type2))}
        </CustomBodyCell>
    );
};

const SettingRow = ({ notificationLabel, rowData, setData }: RowData): JSX.Element => {
    const groupCount = rowData.length;
    return (
        <>
            {rowData.map((row, index) => {
                return (
                    <CustomBodyRow key={index}>
                        <FirstCell
                            index={index}
                            type={row.type}
                            type2={row.type2}
                            notificationLabel={notificationLabel}
                            groupCount={groupCount}
                        />
                        <CustomBodyCell align='center' hiddenBorderLeft={!(+(row?.level ?? 0) > 0)}>
                            {row.level || ''}
                        </CustomBodyCell>
                        <CustomBodyCell align='center'>
                            <SettingCheckbox
                                {...row.portal}
                                type={row.type}
                                type2={row.type2}
                                index={index}
                                deviceType={NotificationDeviceType.PORTAL}
                                setData={setData}
                            />
                        </CustomBodyCell>
                        <CustomBodyCell align='center'>
                            <SettingCheckbox
                                {...row.email}
                                type={row.type}
                                type2={row.type2}
                                index={index}
                                deviceType={NotificationDeviceType.EMAIL}
                                setData={setData}
                            />
                        </CustomBodyCell>
                        <CustomBodyCell align='center'>
                            <SettingCheckbox
                                {...row.mobile}
                                type={row.type}
                                type2={row.type2}
                                index={index}
                                deviceType={NotificationDeviceType.MOBILE}
                                setData={setData}
                            />
                        </CustomBodyCell>
                        <CustomBodyCell align='center'>
                            <SettingCheckbox
                                {...row.whatsapp}
                                type={row.type}
                                type2={row.type2}
                                index={index}
                                deviceType={NotificationDeviceType.WHATSAPP}
                                setData={setData}
                            />
                        </CustomBodyCell>
                    </CustomBodyRow>
                );
            })}
        </>
    );
};

const SettingGroup = ({
    groupSettings,
    title,
    translate,
    setData,
    notificationLabel,
    additionalGroup
}: SettingGroupProps): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const customerSettings = useRecoilValue(CustomerSettings);

    const getTitle = useMemo(() => {
        if (title === TypeEnum.MDD_BES_TYRE_LOG) {
            if (customerSettings.mdd_bes) {
                return `t.${customerSettings.mdd_bes_display_name.toLocaleLowerCase()}_severity_data_update`;
            }
            return `t.severity_data_update`;
        }
        return `t.${title === TypeEnum.PRESSURE ? 'pressures' : title}`;
    }, []);

    const data = useCallback(() => {
        let data: JSX.Element[] = [];
        Object.keys(groupSettings)
            .sort((a, b) => (title === TypeEnum.PRESSURE ? (a === Type2Enum.NORMAL ? 1 : a > b ? -1 : 1) : 1))
            .forEach((key, index) => {
                data.push(
                    <SettingRow
                        key={index}
                        rowData={groupSettings[key]}
                        notificationLabel={notificationLabel}
                        setData={setData}
                    />
                );
            });
        if (additionalGroup) {
            Object.keys(additionalGroup).forEach((key, index) => {
                data.push(
                    <SettingRow
                        key={index}
                        rowData={additionalGroup[key]}
                        notificationLabel={notificationLabel}
                        setData={setData}
                    />
                );
            });
        }
        return data;
    }, [groupSettings]);
    return (
        <>
            <ListItemCustom
                isActive={true}
                data-testid={`Profile-ButtonToShowAreaNotifications-${1}`}
                onClick={() => {
                    setExpanded((current) => !current);
                }}
            >
                <ListItemText primary={translate(getTitle)} />
                {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemCustom>
            <Collapse in={expanded} timeout='auto' unmountOnExit sx={{ overflow: 'auto' }}>
                <LisPageContent>
                    <CustomTable aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center' colSpan={3}>
                                    {translate('t.notification')}
                                </TableCell>
                                <TableCell align='center'>{translate('t.level')}</TableCell>
                                <TableCell align='center'>{translate('t.portal')}</TableCell>
                                <TableCell align='center'>{translate('t.email')}</TableCell>
                                <TableCell align='center'>{translate('t.text_sms')}</TableCell>
                                <TableCell align='center'>WhatsApp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{data()}</TableBody>
                    </CustomTable>
                </LisPageContent>
            </Collapse>
        </>
    );
};

export const NotificationsSettingsFormContent: React.FC<NotificationsSettingsFormViewProps> = ({
    userData,
    checkPermissionsUser,
    data,
    isCurrentUser,
    handleSubmit,
    setData,
    notificationLabel
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <NotificationsSettingsFormContentStyle data-testid='NotificationsSettingsFormContent'>
            <ContainerBodyTab>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Box flexGrow={1} />
                            <Typography variant='h6'>{translate('t.notification_settings')}</Typography>

                            <Box flexGrow={1} display='flex' justifyContent='flex-end'>
                                {checkPermissionsUser ? (
                                    <UiLegend
                                        title={translate('t.be_aware')}
                                        width={400}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                        content={
                                            <Typography variant='subtitle1' sx={{ fontSize: '14px' }} component='h4'>
                                                {translate('p.information_in_notification_settings')}.
                                            </Typography>
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </Box>
                        {checkPermissionsUser && !hasAccessToCustomer(userData, userData?.customer.id ?? 0) ? (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert
                                    variant='standard'
                                    sx={{ marginTop: 2, marginBottom: 2 }}
                                    severity='info'
                                    data-testid='User-without-access-to-customer'
                                >
                                    {translate('p.user_without_access_to_customer', {
                                        customerName: userData?.customer.name
                                    })}
                                </Alert>
                            </Grid>
                        ) : (
                            <>
                                {Object.entries(data || {})
                                    .filter((a) => {
                                        return a[0] !== TypeEnum.PRESSURE_HOT;
                                    })
                                    .map(([key, value], groupIndex) => {
                                        return (
                                            <SettingGroup
                                                key={groupIndex}
                                                title={key}
                                                translate={translate}
                                                groupSettings={value}
                                                setData={setData}
                                                notificationLabel={notificationLabel}
                                                additionalGroup={
                                                    key === TypeEnum.PRESSURE
                                                        ? data
                                                            ? data[TypeEnum.PRESSURE_HOT]
                                                            : undefined
                                                        : undefined
                                                }
                                            />
                                        );
                                    })}
                                <Grid container>
                                    <Grid item lg={8} md={12} sm={12} xs={12}>
                                        <ButtonsCover>
                                            <ButtonsToTestAlerts
                                                isCurrentUser={isCurrentUser}
                                                disabledSMSAlert={!(userData && userData.phoneNumber)}
                                                emailTestId='Profile-Notifications-EmailAlert-Button'
                                                smsTestId='Profile-Notifications-SMSAlert-Button'
                                                whatsappTestId='Profile-Notifications-WhatsappAlert-Button'
                                            />
                                        </ButtonsCover>
                                    </Grid>
                                    <Grid item lg={4} md={12} sm={12} xs={12}>
                                        <ContainerButton>
                                            <Button
                                                testid='Save-Form-Button-NotificationsSettings'
                                                variant='contained'
                                                skin='success'
                                                type='button'
                                                onClick={handleSubmit}
                                            >
                                                {translate('t.save')}
                                            </Button>
                                        </ContainerButton>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </ContainerBodyTab>
        </NotificationsSettingsFormContentStyle>
    );
};
