import React from 'react';
import { TemperaturePressureTableTransmissionDistanceProps } from './TemperaturePressureTableTransmissionDistance.type';
import { Wrapper } from 'helpers/wrapper';
import { TableCell } from '@mui/material';
import { UITableCell } from './TemperaturePressureTableTransmissionDistance.style';
import useConverter from 'components/CustomHooks/Converter/Converter';

const TemperaturePressureTableTransmissionDistance: React.FC<TemperaturePressureTableTransmissionDistanceProps> = ({
    vehicle
}): JSX.Element => {
    const { fromServerToUserUnit, fromUTCToTimezone, convertType } = useConverter();

    const tempDifferenceCell = (): JSX.Element => {
        const formatResult = (num: number): string => (num === 0 ? '0.0' : num.toFixed(1));
        let valueDiff = 0;
        let pos1Value = 0;
        let pos2Value = 0;

        if (
            vehicle.wheels?.length > 1 &&
            vehicle.wheels[0].temperature &&
            vehicle.wheels[1].temperature &&
            vehicle.wheels[0].temperature.toString() !== 'sl1.sensor_temperature' &&
            vehicle.wheels[1].temperature.toString() !== 'sl1.sensor_temperature'
        ) {
            pos1Value = parseFloat(
                fromServerToUserUnit({
                    type: convertType.temperature,
                    value: vehicle.wheels[0].temperature ?? 0.0,
                    fixed: 1
                })
            );
            pos2Value = parseFloat(
                fromServerToUserUnit({
                    type: convertType.temperature,
                    value: vehicle.wheels[1].temperature ?? 0.0,
                    fixed: 1
                })
            );
            valueDiff = parseFloat((pos1Value - pos2Value).toFixed(1));
        } else {
            return <TableCell key='tempDifference' align='center' />;
        }

        return (
            <UITableCell key='tempDifference' $align='right'>
                {formatResult(valueDiff)}
            </UITableCell>
        );
    };

    return (
        <React.Fragment>
            <UITableCell align='center'>
                {vehicle.lastTransmission && fromUTCToTimezone(vehicle.lastTransmission, false)}
            </UITableCell>
            {tempDifferenceCell()}

            <UITableCell $align='right'>
                {' '}
                {` ${fromServerToUserUnit({
                    type: convertType.altitude,
                    value: vehicle.distanceTraveled ?? 0,
                    displayUnits: false,
                    fixed: 1,
                    toType: convertType.distance
                })}`}
            </UITableCell>
        </React.Fragment>
    );
};

export default Wrapper(TemperaturePressureTableTransmissionDistance);
