import styled from 'styled-components';

export const StatisticsMapContainer = styled.div`
    @media (max-width: 767px) {
        &::-webkit-scrollbar,
        & *::-webkit-scrollbar {
            width: 9px !important;
            height: 14px !important;
        }
    }
`;
