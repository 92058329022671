import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { TemperaturePressureTableContent } from './TemperaturePressureTable.view';
import {
    TableViewTypeActionsEnum,
    TempePressTableActionsEnum,
    TemperaturePressureTableProps
} from './TemperaturePressureTable.type';
import { InicialStateTempPressTable, TempPressTableReducer } from './TemperaturePressureTable.reducer';
import ReactToPrint from 'react-to-print';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TempPressFilters, RefreshPressTempTable } from './TempPressFilters.atom';
import { useTranslation } from 'react-i18next';
import {
    DefaultTempPredictionMenuAtom,
    TemperaturePredictionMenuAtom
} from '../TemperaturePredictionPopover/TemperaturePredictionPopover.atom';
import { Wrapper } from 'helpers/wrapper';
import { PrinterContainer } from './TemperaturePressureTable.style';

const TemperaturePressureTable: React.FC<TemperaturePressureTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tempAndPressTableState, dispachTempAndPressTableState] = useReducer(TempPressTableReducer, {
        ...InicialStateTempPressTable,
        filterVehicleGroup: {
            title: translate('t.all'),
            value: 0
        }
    });
    const componentToPrintRef = useRef<React.ReactInstance | null>(null);
    const buttonToPrintRef = useRef<HTMLButtonElement>(null);
    const [showScroll, setShowScroll] = useState(true);
    const [tempPressFiltersAtom, setTempPressFiltersAtom] = useRecoilState(TempPressFilters);
    const [refreshPressTempTable, setRefreshPressTempTable] = useRecoilState(RefreshPressTempTable);
    const setTemperaturePredictionActionMenuAtom = useSetRecoilState(TemperaturePredictionMenuAtom);
    const [tempPredServiceProblem, setTempPredServiceProblem] = useState<boolean>(false);

    const setTableDisplay = (tableViewType: TableViewTypeActionsEnum): void => {
        dispachTempAndPressTableState({ type: TempePressTableActionsEnum.SET_TABLE_VIEW_TYPE, payload: tableViewType });
    };

    const sortingByTable = (column: string, order: 'desc' | 'asc'): void => {
        setTempPressFiltersAtom({
            ...tempPressFiltersAtom,
            sortBy: { column: column, order: order }
        });
    };

    const setRefresh = (value: boolean): void => {
        dispachTempAndPressTableState({ type: TempePressTableActionsEnum.SET_REFRESH, payload: value });
    };

    const setFilterByAlert = (alert: number): void => {
        dispachTempAndPressTableState({
            type: TempePressTableActionsEnum.SET_FILTER_ALERT_LEVEL,
            payload: alert
        });
    };

    const setValueInputSearch = (name: string): void => {
        dispachTempAndPressTableState({
            type: TempePressTableActionsEnum.SET_FILTER_BY_VEHICLE_NAME,
            payload: name
        });
    };

    const setFilterVehicleGroup = (value: number | string, title: string | JSX.Element): void => {
        dispachTempAndPressTableState({
            type: TempePressTableActionsEnum.SET_FILTER_VEHICLE_GROUP,
            payload: {
                title: title as string,
                value: value as number
            }
        });
    };

    const reactToPrintContent = useCallback(() => {
        return componentToPrintRef.current;
    }, [componentToPrintRef]);

    useEffect(() => {
        if (!showScroll && buttonToPrintRef.current) {
            buttonToPrintRef.current.click();
        }
    }, [showScroll]);

    useEffect(() => {
        InicialStateTempPressTable.tableViewType =
            (localStorage.getItem('temp-and-pressure-view') as TableViewTypeActionsEnum) ||
            TableViewTypeActionsEnum.COLD;
    }, [localStorage.getItem('temp-and-pressure-view')]);

    useEffect(() => {
        if (refreshPressTempTable) {
            setRefresh(true);
            setRefreshPressTempTable(false);
            setTemperaturePredictionActionMenuAtom(DefaultTempPredictionMenuAtom);
        }
    }, [refreshPressTempTable]);

    return (
        <>
            <PrinterContainer>
                <ReactToPrint
                    trigger={() => <button ref={buttonToPrintRef}></button>}
                    content={reactToPrintContent}
                    onAfterPrint={() => setShowScroll(true)}
                    documentTitle='Temp&Press | Bridgestone iTrack - Mining TPMS 24/7'
                />
            </PrinterContainer>

            <TemperaturePressureTableContent
                data-testid={'TemperaturePressureTable-testid'}
                tempAndPressState={tempAndPressTableState}
                setTableDisplay={setTableDisplay}
                handleRefresh={setRefresh}
                setFilterByAlert={setFilterByAlert}
                setValueInputSearch={setValueInputSearch}
                componentRef={componentToPrintRef}
                showScroll={showScroll}
                setShowScroll={setShowScroll}
                sortingByTable={sortingByTable}
                setFilterVehicleGroup={setFilterVehicleGroup}
                tempPredServiceProblem={tempPredServiceProblem}
                setTempPredServiceProblem={setTempPredServiceProblem}
            />
        </>
    );
};

export default Wrapper(TemperaturePressureTable);
