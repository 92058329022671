import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { LazyloadingAutocompleteProps } from './LazyloadingAutocomplete.type';
import {
    AutocompleteCover,
    AutocompleteList,
    AutocompleteListUl,
    GroupHeader,
    NoDataMsg,
    ClearIconButton,
    AutocompleteListMenuItem
} from './LazyloadingAutocomplete.style';
import { CircularProgress, InputAdornment, TextField, debounce, Portal, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Clear from '@mui/icons-material/Clear';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getParams } from 'helpers';
import PortalApi from 'helpers/api/PortalApiClient';
import { FilterProps } from 'components/Ui/UiTable/UiTable.type';
import { useTranslation } from 'react-i18next';
import { AutocompleteValues, AutocompleteValuesEnum } from './LazyloadingAutocomplete.reducer';
import { getCache, cacheTimeToMilliseconds, isCacheAvailable } from 'helpers/cache';
import { Wrapper } from 'helpers/wrapper';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

function LazyloadingAutocomplete<GetTableFn extends PortalApi<unknown>['getTable']>(
    props: LazyloadingAutocompleteProps<GetTableFn>
): JSX.Element {
    const [data, setData] = useState<Record<string, unknown>[] | undefined>();
    const [refetchFlag, setRefetchFlag] = useState<boolean>(false);
    const queryCache = useQueryClient().getQueryCache();
    const texfieldInput = useRef<HTMLInputElement>(null);
    const listElement = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [value2, setValue2] = useReducer(AutocompleteValues, {
        inputValue: null,
        selectedValue: undefined,
        value: null,
        searchValue: undefined,
        origItem: undefined
    });
    const { t: translate } = useTranslation();
    const dataDict: Record<number, unknown[]> = useMemo(() => {
        let dataDictionary = {};
        if (props.groupBy && data) {
            data.map((d) => {
                const key = d[props.groupBy?.key || ''] || props.groupBy?.defaultGroup;
                if (!dataDictionary[key as number | string]) {
                    dataDictionary[key as number | string] = [];
                }

                if (!dataDictionary[key as number | string].includes(d)) {
                    dataDictionary[key as number | string].push(d);
                }
            });
        }
        return dataDictionary;
    }, [data, props.groupBy]);
    const [listCoordinates, setListCoordinates] = useState<{ left: number; top: number; width: number }>({
        left: 0,
        top: 0,
        width: 0
    });

    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (!props.disabled) {
            props.onClick && props.onClick(e);
            if (!open) {
                setOpen(true);
                setValue2({ type: AutocompleteValuesEnum.RESET_SEARCH_VALUE });
            }
        }
    };

    const onFocus = (): void => {
        const element = document.getElementById(`${props.id}-LazyLoading-wrapper`);

        if (element) {
            let { left, bottom, width } = element.getBoundingClientRect();
            if (props.helperText) {
                bottom = bottom - 25;
            }
            setListCoordinates({ left, top: bottom, width });
        }
    };

    const onBlur = (e): void => {
        props.onBlur && props.onBlur(e);

        setTimeout(() => {
            setValue2({ type: AutocompleteValuesEnum.SET_SELECTED_TO_INPUT_VALUE });
            setOpen(false);
        }, 200);
    };

    const onScroll = (e): void => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight) {
            hasMore && keepOrUpdateData();
        }
    };

    const onChange = (e): void => {
        if (!open) setOpen(true);
        setValue2({ type: AutocompleteValuesEnum.SET_INPUT_VALUE, payload: { inputValue: e.target.value } });
    };

    const onClickOption = (optionKey: string, optionValue: number, origItem: unknown): void => {
        setValue2({
            type: AutocompleteValuesEnum.SET_SELECTED_VALUE,
            payload: {
                inputValue: optionKey,
                selectedValue: optionKey,
                value: optionValue,
                origItem
            }
        });
    };

    const resetComponent = (): void => {
        setPage(0);
        setHasMore(false);
    };

    const resetValues = (): void => {
        setValue2({
            type: AutocompleteValuesEnum.RESET
        });
        setValue2({
            type: AutocompleteValuesEnum.RESET_SEARCH_VALUE
        });
    };

    const fetchSearchDelayed = useCallback(
        debounce(() => {
            resetComponent();
            keepOrUpdateData(true);
        }, 300),
        [value2.searchValue]
    );

    useEffect(() => {
        if ((value2.inputValue?.length ?? 0) > 0 && value2.inputValue !== value2.selectedValue) {
            fetchSearchDelayed();
        }
    }, [value2.inputValue, value2.selectedValue]);

    useEffect(() => {
        value2.value !== null &&
            value2.inputValue !== null &&
            props.onValueChange &&
            props.onValueChange(value2.value, value2.inputValue, value2.origItem);
    }, [value2.value, value2.inputValue]);

    useEffect(() => {
        if (!open) {
            resetComponent();
            setData([]);
        }
        if (open) {
            keepOrUpdateData(true);
        }
    }, [open]);

    const { refetch, isFetching, isLoading } = useQuery(
        [
            props.queryId,
            'asyncData',
            page,
            value2?.searchValue?.trim(),
            props.defaultFilter,
            props.optionKey,
            props.querySortBy,
            props.queryFilter,
            props.apiProject,
            props.extendUrl,
            props.version
        ],
        () => {
            if (props.query) {
                let filter: FilterProps[] | [] = props.defaultFilter ? props.defaultFilter : [];
                if (value2.searchValue?.trim().length) {
                    filter = [
                        ...filter,
                        { id: props.optionKey || '', value: { name: 'vehicleName', value: value2.searchValue.trim() } }
                    ];
                }

                let propsParams = getParams({
                    queryPageIndex: page,
                    queryPageSize: 10,
                    queryPageFilter: filter,
                    queryPageSortBy: props.querySortBy
                        ? [props.querySortBy]
                        : props?.disableQueryFilter
                        ? []
                        : [{ id: 'id', desc: false }]
                });

                if (props.queryFilter) {
                    propsParams = {
                        ...propsParams,
                        ...props.queryFilter
                    };
                }

                return props.query({
                    apiProject: typeof props.apiProject === 'string' ? props.apiProject : 'webtrack',
                    extendUrl: props.extendUrl || '',
                    version: props.version || 'v1',
                    criteria: propsParams
                });
            }
        },
        {
            enabled: false,
            staleTime: cacheTimeToMilliseconds(props.cacheTime, props.cacheUnit),
            cacheTime: cacheTimeToMilliseconds(props.cacheTime, props.cacheUnit),
            onSuccess: (dataOnSuccess) => checkData(dataOnSuccess),
            onSettled: () => {},
            onError: (e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    );

    const checkData = (dataToCheck, resetPageOption?: boolean): void => {
        const hasMoreData = (dataToCheck?.paginator?.page ?? 0) < (dataToCheck?.paginator?.pageCount ?? 0);

        if (dataToCheck?.items) {
            if (page === 0 || resetPageOption) {
                let options = dataToCheck.items || [];
                if (props.defaultOption) {
                    options = [props.defaultOption, ...dataToCheck.items];
                }

                setData(options as Record<string, unknown>[] | undefined);
            } else {
                setData((currentData) => [
                    ...(currentData as Record<string, unknown>[]),
                    ...(dataToCheck.items as Record<string, unknown>[])
                ]);
            }
        }

        if (hasMoreData) {
            setPage((current) => current + 1);
        }

        setHasMore(hasMoreData);
    };

    const getQueryKey = (resetPageOption?: boolean): string => {
        return [
            props.queryId,
            'asyncData',
            resetPageOption ? 0 : page,
            value2?.searchValue?.trim(),
            props.defaultFilter,
            props.optionKey,
            props.querySortBy,
            props.queryFilter,
            props.apiProject,
            props.extendUrl,
            props.version
        ].toString();
    };

    const keepOrUpdateData = (resetPageOption?: boolean): void => {
        if (!isCacheAvailable(getQueryKey(resetPageOption), queryCache)) {
            setRefetchFlag(true);
        } else {
            checkData(getCache(getQueryKey(resetPageOption), queryCache)[0]?.state?.data, resetPageOption);
        }
    };

    useEffect(() => {
        //refactor

        if (refetchFlag) {
            refetch();
            setRefetchFlag(false);
        }
    }, [refetchFlag]);

    useEffect(() => {
        if (props.preselectedValue?.label === 'void') {
            return;
        }

        if (props.preselectedValue?.label === 'reset') {
            setValue2({ type: AutocompleteValuesEnum.RESET });
            return;
        }
        if (
            (props.preselectedValue?.value || props.preselectedValue?.value === 0) &&
            props.preselectedValue?.label &&
            props.preselectedValue?.value !== value2.value
        ) {
            setValue2({
                type: AutocompleteValuesEnum.SET_SELECTED_VALUE,
                payload: {
                    inputValue: props.preselectedValue.label,
                    selectedValue: props.preselectedValue.label,
                    value: props.preselectedValue.value,
                    origItem: props.preselectedValue.origItem
                }
            });
        }
    }, [props.preselectedValue?.value, props.preselectedValue?.label]);

    return (
        <AutocompleteCover
            width={props.width}
            id={`${props.id}-LazyLoading-wrapper`}
            fullWidth={props.fullWidth}
            customMargin={props.customMargin}
        >
            <Box>
                {/*<InputCover>
                    <CustomInputLabel>{props.label}</CustomInputLabel>
                    <InputBase
                        value={value2.inputValue} 
                        fullWidth
                        inputRef={texfieldInput}
                        onClick={onClick}
                        onBlur={onBlur}
                        error={props.error}
                        multiline
                        rows={props.rows || 1}
                        required={props.required}
                        onFocus={onFocus}
                        placeholder={props.placeholder}
                        inputProps={{
                            autoComplete: 'off'
                        }}
                    />
                    </InputCover>*/}

                <TextField
                    {...props}
                    fullWidth
                    id={`${props.id}-lazyLoading-textField`}
                    data-testid={props.$testid || 'LazyLoading-textField'}
                    inputRef={texfieldInput}
                    onClick={onClick}
                    onBlur={onBlur}
                    required={props.required}
                    error={props.error}
                    multiline
                    rows={props.rows || 1}
                    helperText={props.helperText}
                    onFocus={onFocus}
                    placeholder={props.placeholder}
                    InputProps={{
                        // toto treba nanovo
                        startAdornment:
                            props.startAdornment && value2.origItem && Object.keys(value2.origItem)?.length ? (
                                <InputAdornment position='start'>
                                    {props.startAdornment(value2.origItem)}
                                </InputAdornment>
                            ) : undefined,
                        endAdornment: (
                            <InputAdornment position='end'>
                                {isFetching && <CircularProgress size='1.4375em' />}

                                <UiIconButton
                                    aria-label='open autocomplete options'
                                    edge='end'
                                    size='small'
                                    disabled={props.disabled}
                                    onClick={() => {
                                        if (open) {
                                            texfieldInput.current?.blur();
                                        } else {
                                            texfieldInput.current?.focus();
                                        }
                                    }}
                                >
                                    {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </UiIconButton>
                            </InputAdornment>
                        )
                    }}
                    InputLabelProps={{
                        // toto zvlast
                        shrink: true
                    }}
                    inputProps={{
                        autoComplete: 'off'
                    }}
                    onChange={onChange}
                    value={value2.inputValue}
                    label={props.label || 'Label'}
                />
            </Box>

            {props.clearButton && value2.inputValue != ' ' && value2.inputValue != undefined && (
                <InputAdornment position='end'>
                    <ClearIconButton aria-label='delete' size='small' disabled={props.disabled}>
                        <Clear
                            fontSize='inherit'
                            onClick={() => {
                                resetValues();
                            }}
                        />
                    </ClearIconButton>
                </InputAdornment>
            )}
            {(open || isFetching) && (
                <Portal>
                    <AutocompleteList
                        variant='outlined'
                        top={listCoordinates.top}
                        left={listCoordinates.left}
                        onScroll={onScroll}
                        width={listCoordinates.width}
                    >
                        <AutocompleteListUl
                            component='ul'
                            ref={listElement}
                            data-testid={`${props.id}-lazyLoading-list`}
                        >
                            {props.addTopElement?.(value2?.searchValue?.trim() || '')}
                            {props.groupBy
                                ? Object.entries(dataDict).map((entrie, index) => (
                                      <>
                                          <GroupHeader key={index}>{entrie[0]}</GroupHeader>
                                          {entrie[1].map((data, index) => (
                                              <AutocompleteListMenuItem
                                                  key={index}
                                                  disabled={false}
                                                  onClick={() => {
                                                      onClickOption(
                                                          props.getOptionLabel?.(data).text ||
                                                              (data as [])[props.optionKey],
                                                          (data as [])[props.optionValue],
                                                          data
                                                      );
                                                  }}
                                              >
                                                  {props.getOptionLabel
                                                      ? props.getOptionLabel(data)?.html ||
                                                        props.getOptionLabel(data)?.text
                                                      : (data as [])[props.optionKey]}
                                              </AutocompleteListMenuItem>
                                          ))}
                                      </>
                                  ))
                                : data?.map((d, index) => {
                                      const optionLabel: string = (
                                          props.getOptionLabel
                                              ? props.getOptionLabel(d).html || props.getOptionLabel(d).text
                                              : d[props.optionKey]
                                      ) as string;
                                      return (
                                          <AutocompleteListMenuItem
                                              key={index}
                                              disabled={false}
                                              data-testid={`${props.id}-lazyLoading-list-value-${d[props.optionValue]}`}
                                              onClick={() => {
                                                  onClickOption(
                                                      (props.getOptionLabel?.(d).text || d[props.optionKey]) as string,
                                                      d[props.optionValue] as number,
                                                      d
                                                  );
                                              }}
                                          >
                                              {optionLabel}
                                          </AutocompleteListMenuItem>
                                      );
                                  })}
                            {data && !(data as []).length && !(isFetching || isLoading) && (
                                <NoDataMsg data-testid='no_data_message'>{translate('t.there_no_data')}</NoDataMsg>
                            )}
                        </AutocompleteListUl>
                    </AutocompleteList>
                </Portal>
            )}
        </AutocompleteCover>
    );
}

export default Wrapper(LazyloadingAutocomplete);
