import styled from 'styled-components';
import { useTheme } from '@mui/material';

export const Button = styled('button')<{
    $level: number | undefined;
}>`
    border: none;
    width: 100%;
    background-color: transparent;
    text-align: left;
    cursor: ${(props) => (props.$level === 0 ? 'auto' : 'pointer')};
    color: ${(props) => (props.$level === 0 ? '#000' : 'inherit')};
    ${() => {
        return `
            :hover {
                background-color: ${useTheme().palette.background.default};
            }
        `;
    }}
`;
