import styled from 'styled-components';
import { PRIMARY } from '../../Ui/colors';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const TyresSummary = styled.div`
    padding: 0;
`;

export const CustomTableContainer = styled(TableContainer)`
    &.MuiTableContainer-root {
        padding: 10px 0;
    }
`;

export const CustomTable = styled(Table)`
    tbody {
        color: ${PRIMARY};
        tr {
            font-size: 1.1rem;
        }
    }
`;

export const CustomHead = styled(TableHead)`
    tr:nth-child(2) {
        border-bottom: 3px solid rgba(224, 224, 224, 1);
    }
    th {
        font-weight: 700;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
    }
    && .MuiFormControl-root {
        margin: 0 0 0 5px;
    }
`;

export const CustomTableCell = styled(TableCell)<{ $border?: boolean; $header?: boolean }>`
    &.MuiTableCell-root {
        text-align: center;
        border-bottom: none;
        ${(props) => props.$border && 'border-right: 0.5px solid #dfdfdf;'}

        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `padding: 5px 10px; font-size: ${common.textSize};`;
        }}
    }
`;

export const LegendBody = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
    font-weight: 500;
    width: 230px;
`;
