import React, { useCallback } from 'react';
import { ContainerTempExp, TemperatureExposureContainer } from './TemperatureExposure.style';
import { TemperatureExposureViewProps } from './TemperatureExposure.type';
import TemperatureExposureTopbar from 'components/Mixs/TemperatureExposureTopbar';
import Grid from '@mui/material/Grid';
import { useRecoilValue } from 'recoil';
import { SelectedVehicle } from 'states/global/Statistics';
import { VehicleWheels } from './TemperatureExposure.atom';
import { useTranslation } from 'react-i18next';
import TemperatureExposureWheelReport from 'components/Mixs/TemperatureExposureWheelReport/TemperatureExposureWheelReport';
import Alert from '@mui/material/Alert';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import Widget from 'components/Ui/Components/UiWidget';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

export const TemperatureExposureContent: React.FC<TemperatureExposureViewProps> = (props): JSX.Element => {
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const vehicleWheels = useRecoilValue(VehicleWheels);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const generateGraphs = useCallback(() => {
        if (!vehicleWheels.isLoading && selectedVehicle.id !== 0) {
            let graphs: JSX.Element[] = [];
            for (let i = 0; i < vehicleWheels.wheels.length; i++) {
                graphs.push(
                    <TemperatureExposureWheelReport
                        position={vehicleWheels.wheels[i].wheel.customPosition}
                        wheelId={vehicleWheels.wheels[i].wheel.id}
                    />
                );
            }
            return graphs;
        }
        return <></>;
    }, [vehicleWheels, selectedVehicle.id]);

    return (
        <TemperatureExposureContainer data-testid='TemperatureExposureContent'>
            <Widget
                title={translate('t.wheel_temp_levels')}
                avatar={
                    <UiIcon
                        icon={['fas', 'temperature-half']}
                        size='lg'
                        fixedWidth
                        data-testid='WheelTemperatureLevelsAvatar'
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                loading={false}
                content={
                    <ContainerTempExp>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TemperatureExposureTopbar isLoading={props.isLoading} showVehicleLazyloading={true} />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                {vehicleWheels.wheels.length === 0 && !props.isLoading ? (
                                    <Alert severity='info' data-testid='SelectVehicleMessage'>
                                        {translate('p.select_vehicle_preview_temperature_distribution')}
                                    </Alert>
                                ) : (
                                    <></>
                                )}
                                {props.isLoading ? <UiLoadingPage size='30px' /> : generateGraphs()}
                            </Grid>
                        </Grid>
                    </ContainerTempExp>
                }
            />
        </TemperatureExposureContainer>
    );
};
