import React, { useEffect, useState, useCallback } from 'react';
import { SystemNotificationMessageContent } from './SystemNotificationMessage.view';
import { Wrapper } from 'helpers/wrapper';
import { MessageTable } from 'models/SystemNotification.type';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { DateTime, Interval } from 'luxon';
import { SystemNotificationMessageProps } from './SystemNotificationMessage.type';

const SystemNotificationMessage: React.FC<SystemNotificationMessageProps> = ({ data, isRefetching }): JSX.Element => {
    const { user } = useRecoilValue(UserInfo);
    const [displayedNotification, setDisplayedNotification] = useState<MessageTable[] | null>(null);
    const [whatsNewNotification, setWhatsNewNotification] = useState<MessageTable | null>(null);

    const getMessageToDisplay = useCallback((): void => {
        const currentTime = DateTime.now();
        const notifications =
            data?.items.filter((item) => {
                const interval = Interval.fromDateTimes(DateTime.fromISO(item.dateFrom), DateTime.fromISO(item.dateTo));
                return item.active && interval.contains(currentTime) && checkClosedNotification(item);
            }) || [];

        const regularNotification = notifications.filter((item) => !item.helpjuiceId);
        const whatsNewNotification = notifications.filter((item) => item.helpjuiceId);

        setDisplayedNotification(regularNotification);
        if (whatsNewNotification.length) {
            setWhatsNewNotification(whatsNewNotification[0]);
        }
    }, [data]);

    const checkClosedNotification = useCallback(
        (item: MessageTable): boolean => {
            const localStorageString = localStorage.getItem('closedNotification');
            const closedNotifications = localStorageString ? JSON.parse(localStorageString) : [];

            const userClosedNotifications = closedNotifications.find(
                (notification: any) => notification.userId === user?.id
            );

            if (!userClosedNotifications) return true;

            return (
                Array.isArray(userClosedNotifications?.displayedNotification) &&
                !userClosedNotifications?.displayedNotification.some(
                    (notification: MessageTable) => notification.id === item.id
                )
            );
        },
        [user?.id]
    );

    const handleCloseNotification = useCallback(
        (notification: MessageTable): void => {
            const localStorageString = localStorage.getItem('closedNotification');
            const closedNotifications = localStorageString ? JSON.parse(localStorageString) : [];

            const userClosedNotificationsIndex = closedNotifications.findIndex(
                (notification: any) => notification.userId === user?.id
            );

            let whatsNewData = data?.items?.filter((item) => item.helpjuiceId) || [];

            if (userClosedNotificationsIndex > -1) {
                const userClosedNotifications = closedNotifications[userClosedNotificationsIndex];

                if (notification.helpjuiceId) {
                    if (whatsNewData.length) {
                        userClosedNotifications.displayedNotification =
                            userClosedNotifications.displayedNotification.filter(
                                (notification) => !notification.helpjuiceId
                            );
                        userClosedNotifications.displayedNotification = [
                            ...userClosedNotifications.displayedNotification,
                            ...whatsNewData
                        ];
                    }
                } else {
                    userClosedNotifications.displayedNotification = [
                        ...userClosedNotifications.displayedNotification,
                        notification
                    ];
                }
                closedNotifications[userClosedNotificationsIndex] = userClosedNotifications;
            } else {
                closedNotifications.push({
                    userId: user?.id,
                    displayedNotification: [...whatsNewData, notification]
                });
            }

            localStorage.setItem('closedNotification', JSON.stringify(closedNotifications));
            setDisplayedNotification(
                (current) => current?.filter((currentNotification) => currentNotification.id !== notification.id) || []
            );
            setWhatsNewNotification(null);
        },
        [user?.id, data?.items]
    );

    const createMessage = useCallback(
        (notification: MessageTable): string => {
            const { userSetting } = user || {};
            if (userSetting?.language !== 'en_GB') {
                const translation = notification.translation.find((trans) => trans.locale === userSetting?.language);
                if (translation) return translation.content;
            }
            return notification.message;
        },
        [user]
    );

    useEffect(() => {
        if (data && !isRefetching) {
            getMessageToDisplay();
        }
    }, [data, isRefetching]);

    return (
        <SystemNotificationMessageContent
            data-testid='SystemNotificationMessage-testid'
            createMessage={createMessage}
            handleCloseNotification={handleCloseNotification}
            displayedNotification={displayedNotification}
            whatsNewNotification={whatsNewNotification}
        />
    );
};

export default Wrapper(SystemNotificationMessage);
