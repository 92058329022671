import { styled } from '@mui/material';
import UiButton from '../../../Ui/Components/UiButton/UiButton';

export const SensorManagementRowViewContent = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;

    &&& .MuiTextField-root {
        & label {
            padding: 0 5px 0 0;
            background: transparent;
        }
    }
`;

export const Column = styled('div')<{ width: number }>`
    width: ${(props) => props.width}px;
    margin: 0 6px;
`;

export const StyledButton = styled(UiButton)<{ width: number }>`
    &&.MuiButton-root {
        height: 34px;
        padding: 0;
        margin: 0;
        width: ${(props) => props.width}px;
    }
`;
