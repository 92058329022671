import styled from 'styled-components';
import { Button, Tab, Tabs } from '@mui/material';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { PRIMARY } from '../../Ui/colors';
import { BACKGROUND_BLUE } from '../../Ui/colors';
import { InputBase } from '@mui/material';

export const DeviceCommandsTableViewContent = styled.div`
    th,
    td {
        border: none;
    }
    width: 98%;
    && .MuiCardContent-root {
        padding: 16px 0;
        font-size: 13px !important;
        border: none;
        overflow-x: hidden;
    }

    & .MuiTableHead-root {
        border: none;
        background: #f9f9f9;
        overflow-x: hidden;
    }

    && .infinite-scroll-component {
        &::-webkit-scrollbar {
            width: 7px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 7px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.5) !important;
            width: 7px !important;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.7) !important;
        }
    }
    margin-top: 10px;
`;

export const DeviceCommandsTabsContainer = styled.div`
    display: inline-block !important;
    width: 100% !important;
`;

export const ActionButton = styled(Button)`
    min-width: 20px;
    max-width: 20px;
    margin: 0px 20px;
    color: ${PRIMARY};
`;
export const ModalButton = styled(UiButton)``;

export const DeviceCommandsTabs = styled(Tabs)`
    border: none !important;
    & .MuiTab-root {
        min-width: 13% !important;
        text-transform: none;
        font-size: 1.1rem;
        font-weight: 600;
    }
    & .MuiTab-wrapper {
        flex-direction: row;
    }
    & .MuiTab-labelIcon {
        min-height: auto;
    }
    & .MuiTabs-scroller {
        overflow-x: auto !important;
    }

    & .MuiTabs-flexContainer {
        justify-content: flex-end;
    }
`;

export const DeviceCommandsTab = styled(Tab)`
    &.MuiTab-root {
        font-size: 16px;
    }
`;

export const CustomTextfield = styled(InputBase)`
    &.MuiInputBase-root {
        border: 1px solid #565656;
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        padding: 10px 7px;
        margin-top: 20px;
    }
`;

export const TabContentTerminal = styled.div`
    .contentButton {
        min-width: 130px;
        &.MuiButtonBase-root {
            font-size: 13px !important;
            margin: 0;
            padding: 0;

            justify-content: left;
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: ${BACKGROUND_BLUE};
            }
        }
    }

    @keyframes moveToLeft {
        0% {
            transform: translateX(100px);
        }
        100% {
            transform: translateX(0px);
        }
    }

    animation-name: moveToLeft;
    animation-duration: 150ms;
`;

export const TabContentHistory = styled.div`
    td:nth-child(4) {
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        span {
            width: 150px;
        }
    }

    .contentButton {
        &.MuiButtonBase-root {
            font-size: 13px !important;
            min-width: 150px;
        }
    }

    @keyframes moveToRight {
        0% {
            transform: translateX(100px);
        }
        100% {
            transform: translateX(0px);
        }
    }

    animation-name: moveToRight;
    animation-duration: 150ms;
`;

export const TextRegion = styled.span`
    text-transform: uppercase;
`;
