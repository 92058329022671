import React from 'react';
import { SystemTotalsViewProps } from './SystemTotals.type';
import { SystemTotalsContainer } from './SystemTotals.style';
import { useRecoilValue } from 'recoil';
import SystemTotalsLegendView from './Components/SystenTotalsLegend.view';
import NotTransmittingPopover from '../../NotTransmittingPopover';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import { useTranslation } from 'react-i18next';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import SystemTotalsMainTable from './Components/SystemTotalsMainTable';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const SystemTotalsContent: React.FC<SystemTotalsViewProps> = ({ handleStageChange }) => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <SystemTotalsContainer data-testid='SystemTotalsContent'>
            <UiWidget
                title={translate('t.system_totals')}
                testid='Widget-SystemTotals'
                avatar={
                    <UiIcon
                        icon={['fas', 'truck']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                headerAction={
                    <>
                        <UiIconButton testid='SystemTotals-Mode-Button' size='small' onClick={handleStageChange}>
                            <UiIcon icon={['fas', 'ellipsis']} />
                        </UiIconButton>
                        <UiLegend
                            title={translate('t.not_transmitting')}
                            content={<NotTransmittingPopover />}
                            width={300}
                            root={
                                <UiIconButton testid='SystemTotals-Sensors-Button' size='small'>
                                    <UiIcon icon={['fas', 'wifi']} />
                                </UiIconButton>
                            }
                        />
                        <UiLegend
                            width={550}
                            minWidth={550}
                            title={translate('t.legend')}
                            content={<SystemTotalsLegendView />}
                        />
                    </>
                }
                content={<SystemTotalsMainTable />}
            />
        </SystemTotalsContainer>
    );
};
