import styled from 'styled-components';

export const StatisticsContainer = styled.div`
    .MuiTabs-root {
        align-items: center !important;
    }

    .MuiTab-labelIcon {
        min-height: 46px !important;
        height: 46px !important;
    }
`;
