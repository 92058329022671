import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    ActionsHeader,
    ButtonTitle,
    ContainerActionWidget,
    ContainerSelectFilter,
    CustomButton
} from './TemperaturePressureTableActionHeader.style';
import { TemperaturePressureTableViewProps } from './TemperaturePressureTableActionHeader.type';
import { AlertLevelEnum, TableViewTypeActionsEnum } from '../TemperaturePressureTable/TemperaturePressureTable.type';
import { useTranslation } from 'react-i18next';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import UiInputSearch from 'components/Ui/Components/UiInputSearch/UiInputSearch';
import { Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import Menu from 'components/Ui/Components/UiMenu';
import { ExpandMore } from '@mui/icons-material';
import { Item } from 'components/Ui/Components/UiMenu/UiMenu.type';
import { TempPressFilters } from '../TemperaturePressureTable/TempPressFilters.atom';
import FiltersTempAndPress from '../TemperaturePressureTable/FiltersTempAndPress';
import GetVehicleGroupCodebook from 'components/Queries/VehicleGroup/VehicleGroup';

export const TemperaturePressureTableActionHeader: React.FC<TemperaturePressureTableViewProps> = (
    props
): JSX.Element => {
    const { t: translate } = useTranslation();
    const tempPressFiltersAtom = useRecoilValue(TempPressFilters);
    const theme = useTheme();
    const showXs = useMediaQuery(theme.breakpoints.up('xs'));

    const titlesButtonFilter = {
        cold: `${translate('t.temperature')} & ${translate('t.cold_pressure')}`,
        cold_opt: `${translate('t.cold_pressure')} & ${translate('t.optimal_cold_pressure')}`,
        hot: `${translate('t.temperature')} & ${translate('t.hot_pressure')}`,
        hot_opt: `${translate('t.hot_pressure')} & ${translate('t.target_hot_pressure')}`,
        diff: `${translate('t.temperature')} &
        ${translate('t.cold_pressure')} - ${translate('t.tyre_optimal_pressure_diff')}`,
        all: translate('t.temperature_pressure')
    };

    const titlesSortBy = {
        severity_of_alert: translate('t.severity'),
        'severity+name': `${translate('t.severity')} + ${translate('t.alphabetically')}`,
        name: translate('t.alphabetically')
    };

    const alertLevelIcon = {
        0: translate('t.all'),
        1: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#c1c121' transform='up-1 left-2' />,
        2: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#ea9800' transform='up-1 left-2' />,
        3: <UiIcon icon={['fas', 'triangle-exclamation']} size='1x' color='#ff1616' transform='up-1 left-2' />
    };

    const alertLevelMenu = (): Item[] => [
        {
            title: translate('t.all'),
            value: AlertLevelEnum.ALERT_LEVEL_0,
            action: props.setFilterByAlert
        },
        {
            title: (
                <>
                    <UiIcon icon={['fas', 'triangle-exclamation']} size='lg' color='#c1c121' transform='up-3 left-2' />
                    {AlertLevelEnum.ALERT_LEVEL_1}
                </>
            ),
            value: AlertLevelEnum.ALERT_LEVEL_1,
            action: props.setFilterByAlert
        },
        {
            title: (
                <>
                    <UiIcon icon={['fas', 'triangle-exclamation']} size='lg' color='#ea9800' transform='up-3 left-2' />
                    {AlertLevelEnum.ALERT_LEVEL_2}
                </>
            ),
            value: AlertLevelEnum.ALERT_LEVEL_2,
            action: props.setFilterByAlert
        },
        {
            title: (
                <>
                    <UiIcon icon={['fas', 'triangle-exclamation']} size='lg' color='#ff1616' transform='up-3 left-2' />
                    {AlertLevelEnum.ALERT_LEVEL_3}
                </>
            ),
            value: AlertLevelEnum.ALERT_LEVEL_3,
            action: props.setFilterByAlert
        }
    ];

    const filterMenu = (): Item[] => [
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.COLD],
            value: TableViewTypeActionsEnum.COLD,
            action: props.setTableDisplay
        },
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.COLD_OPT],
            value: TableViewTypeActionsEnum.COLD_OPT,
            action: props.setTableDisplay
        },
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.HOT],
            value: TableViewTypeActionsEnum.HOT,
            action: props.setTableDisplay
        },
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.HOT_OPT],
            value: TableViewTypeActionsEnum.HOT_OPT,
            action: props.setTableDisplay
        },
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.ALL],
            value: TableViewTypeActionsEnum.ALL,
            action: props.setTableDisplay
        },
        {
            title: titlesButtonFilter[TableViewTypeActionsEnum.DIFF],
            value: TableViewTypeActionsEnum.DIFF,
            action: props.setTableDisplay
        }
    ];

    const sortBy = (): Item[] => [
        {
            title: titlesSortBy.severity_of_alert,
            value: 'severity_alert:desc',
            action: () => props.sortingByTable('severity_of_alert', 'desc')
        },
        {
            title: titlesSortBy['severity+name'],
            value: 'severity+name',
            action: () => props.sortingByTable('severity+name', 'desc')
        },
        {
            title: titlesSortBy.name,
            value: 'name:asc',
            action: () => props.sortingByTable('name', 'asc')
        }
    ];

    const listVehicleType: Item[] = [
        {
            title: translate('t.all'),
            value: 0,
            action: props.setFilterVehicleGroup
        }
    ];

    return (
        <FiltersTempAndPress>
            <ActionsHeader>
                <ContainerActionWidget>
                    <Grid container spacing={1}>
                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                            <ContainerSelectFilter>
                                <UiInputSearch
                                    inputSearch={{
                                        valueInputSearch: props.tempAndPressState.filterVehicleName,
                                        setValueInputSearch: props.setValueInputSearch
                                    }}
                                    getValueInputSearch={(e: string) => props.setValueInputSearch(e)}
                                    widthInputSearchInit={200}
                                    widthInputSearchActive={350}
                                    searchByEnter={false}
                                    placeholder={`${translate('t.vehicle_name')}, ${translate('t.hub')} Id`}
                                    $testid='Temp&Press-InputFilterByVehicleNameAndHubId-Input'
                                />
                            </ContainerSelectFilter>
                        </Grid>
                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                            <ContainerSelectFilter>
                                <Typography variant='subtitle1'>{translate('t.sort_by') + ': '}</Typography>
                                <Menu
                                    rootEl={
                                        <CustomButton
                                            size='small'
                                            variant='outlined'
                                            color='secondary'
                                            testid='Temp&Press-SortByFilter-Button'
                                        >
                                            {tempPressFiltersAtom.sortBy?.column
                                                ? titlesSortBy[tempPressFiltersAtom.sortBy?.column]
                                                : titlesSortBy.name}
                                            <ExpandMore />
                                        </CustomButton>
                                    }
                                    items={sortBy()}
                                />
                            </ContainerSelectFilter>
                        </Grid>
                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                            <ContainerSelectFilter>
                                <Typography variant='subtitle1'>
                                    {translate(`t.${showXs ? 'level' : 'alert_level'}`) + ': '}
                                </Typography>
                                <Menu
                                    rootEl={
                                        <CustomButton
                                            variant='outlined'
                                            color='secondary'
                                            size='small'
                                            testid='Temp&Press-LevelFilter-Button'
                                        >
                                            {alertLevelIcon[props.tempAndPressState.filterAlertLevel]}
                                            {props.tempAndPressState.filterAlertLevel || ''}
                                            <ExpandMore />
                                        </CustomButton>
                                    }
                                    items={alertLevelMenu()}
                                />
                            </ContainerSelectFilter>
                        </Grid>
                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                            <ContainerSelectFilter>
                                <Typography variant='subtitle1'>{translate('t.view') + ': '}</Typography>
                                <Menu
                                    rootEl={
                                        <CustomButton
                                            variant='outlined'
                                            title={titlesButtonFilter[props.tempAndPressState.tableViewType]}
                                            color='secondary'
                                            testid='Temp&Press-ViewFilter-Button'
                                            sx={{ maxWidth: '180px' }}
                                        >
                                            <ButtonTitle>
                                                {titlesButtonFilter[props.tempAndPressState.tableViewType]}
                                            </ButtonTitle>
                                            <ExpandMore />
                                        </CustomButton>
                                    }
                                    items={filterMenu()}
                                />
                            </ContainerSelectFilter>
                        </Grid>
                        <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                            <ContainerSelectFilter>
                                <Typography variant='subtitle1'>{translate('t.vehicle_group') + ': '}</Typography>
                                <GetVehicleGroupCodebook>
                                    {({ data }) => (
                                        <Menu
                                            rootEl={
                                                <CustomButton
                                                    variant='outlined'
                                                    title={props.tempAndPressState.filterVehicleGroup.title}
                                                    color='secondary'
                                                    sx={{ maxWidth: '130px' }}
                                                >
                                                    <ButtonTitle>
                                                        {props.tempAndPressState.filterVehicleGroup.title}
                                                    </ButtonTitle>
                                                    <ExpandMore />
                                                </CustomButton>
                                            }
                                            items={
                                                data
                                                    ? listVehicleType.concat(
                                                          data.map((vehicleGropu) => {
                                                              return {
                                                                  title: vehicleGropu.name,
                                                                  value: vehicleGropu.id,
                                                                  action: props.setFilterVehicleGroup
                                                              };
                                                          })
                                                      )
                                                    : []
                                            }
                                        />
                                    )}
                                </GetVehicleGroupCodebook>
                            </ContainerSelectFilter>
                        </Grid>
                    </Grid>
                </ContainerActionWidget>
            </ActionsHeader>
        </FiltersTempAndPress>
    );
};
