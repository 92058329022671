import styled from 'styled-components';
import { Typography } from '@mui/material';
import Button from '../../Ui/Components/UiButton/UiButton';

export const CommentUpdateViewContent = styled.div``;

export const CommentContent = styled.div`
    min-height: 60px;
    padding-top: 10px;
`;

export const ShowComment = styled(Typography)`
    text-decoration: underline !important;
    text-transform: capitalize !important;
    cursor: pointer;
`;

export const ButtonDone = styled(Button)`
    margin: 0px 0px 0px 10px !important;
    min-width: 45px !important;
    height: 34px !important;
`;
