import styled from 'styled-components';
import { PRIMARY } from '../../Ui/colors';
import { Button, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { applyStyleByMode } from 'helpers';

export const AddHubContainer = styled.div``;

export const CustomTextfield = styled(TextField)`
    width: 100%;
`;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0px;
    color: ${PRIMARY} !important;
`;

export const RefButton = styled(Button)`
    display: none;
    width: 1px;
    height: 1px;
`;

export const TextDiv = styled.div`
    margin: 5px 0;
`;

export const Text = styled(Typography)``;

export const CustomTextField = styled(TextField)`
    &.MuiTextField-root {
        margin-left: 0px;
        && label {
            ${() => {
                return applyStyleByMode({
                    theme: useTheme().palette.mode,
                    light: 'background: #fff;',
                    dark: 'background: #1d2630;'
                });
            }}
        }

        & svg {
            fill: #999999;
        }
    }
`;

export const GridInput = styled(Grid)`
    margin-bottom: 20px !important;
`;

export const GridContainer = styled(Grid)`
    margin-top: 15px;
`;
