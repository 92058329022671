import PortalApi from 'helpers/api/PortalApiClient';
import { TableModelResponse, VehicleGroupModel } from '../models/VehicleGroup.type';
import { getParams } from 'helpers';

export default class VehicleGroup extends PortalApi<VehicleGroupModel> {
    route = 'vehicle-group';

    getAll = async (props): Promise<TableModelResponse> => {
        return await this.getTable<VehicleGroupModel>({
            criteria: getParams(props)
        });
    };

    getExport = async (props): Promise<string> => {
        return await this.getByCriteria({
            extendUrl: 'export',
            criteria: getParams(props)
        });
    };
}
