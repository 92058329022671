import { yDomainConfig } from 'components/StatisticsGraphs/StatisticsGraphs.type';
import { GetParamsProps, GetParamsReturnProps } from 'models/Table.type';
import { dotToCamel, firstCapital } from './converter/text';
import {
    GpsIconProps,
    GpsIndicatorProps
} from 'components/Vehicle/VehicleStatusWidget/Components/GpsIndicator/GpsIndicator.type';
import { ThemeMode } from 'states/global/Theme';
import { DateTime } from 'luxon';
import { DiagnoseTable } from 'components/Hub/DeviceDiagnoseTable/DeviceDiagnoseTable.type';
import { DiagnoseSensor } from 'models/DeviceDiagnose.type';
import { TableFiltersType } from 'components/Ui/UiTable/TableAtom';
import { CommonDropDown } from 'components/User/GeneralSettingsForm/GeneralSettingsForm.type';
import { GlobalUserInfo } from 'states/global/User';
import { LinkHashModel, RouteHash } from 'models/LinkHash.type';
import { ITimeZone, ITimeZoneFormatted } from 'models/TimeZone.type';
import { UseConverterReturnType } from 'components/CustomHooks/Converter/Converter.type';
import { PaletteMode } from '@mui/material';
import { RANK0, RANK1, RANK2, RANK3, RANK4, RANKNA, RANKNA2 } from 'components/Ui/colors';
import { ExportState } from 'components/Ui/UiTableExportDropdown/UiTableExportDropdown.type';

export const isString = (value): boolean => {
    return typeof value === 'string' || value instanceof String;
};

export const displayDecimal = (value: number, decimals = 1): string => {
    return Number.parseFloat(value.toString()).toFixed(decimals);
};

const hexToRgb = (hex: string): string | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : null;
};

export const isValidColor = (color: string): boolean => {
    let s = new Option().style;
    s.color = color;
    let inputColor: string | null = color;

    if (color.length) {
        if (color[0] === '#') {
            inputColor = hexToRgb(color);
            if (!inputColor) {
                return false;
            }
            s.color = hexToRgb(color) as string;
        }
    }

    return s.color.replaceAll(' ', '') == inputColor?.toLowerCase().replaceAll(' ', '');
};

export const getFilterKeyVariation = (accessor: string, customAccessor: string): string[] => {
    const camelAccessor = dotToCamel(accessor);
    const camelCustomAccessor = dotToCamel(customAccessor);

    const variations = [camelAccessor, camelCustomAccessor].reduce((accumulator, currentValue) => {
        const variations = [
            `not${firstCapital(currentValue)}`,
            currentValue,
            `${currentValue}From`,
            `${currentValue}To`,
            `${currentValue}FullText`,
            `not${firstCapital(currentValue)}`,
            camelAccessor,
            `${camelAccessor}From`,
            `${camelAccessor}To`,
            `${camelAccessor}FullText`
        ];
        return [...accumulator, ...variations];
    }, [] as string[]);
    return variations;
};

export const prepareTableFilterValues = (tableFilter: TableFiltersType) => {
    return Object.entries(tableFilter).map((filter) => {
        return {
            id: filter[0],
            value: {
                humanValue: '',
                name: '',
                value: filter[1].value
            }
        };
    });
};

export const GRAPH_DATA_MARGIN = 1;

export let DEFAULT_SPEED_GRAPH_SCALE = 10;
export let DEFAULT_GRAPH_SCALE = 10;

export const SPEED_STATIONARY_TRESHOLD = 5; // mins

export const closestNumberToDivisibleBy = (number: number, divisibleBy: number, findLower = false): number => {
    const isNegative: boolean = number < 0;
    let q = 1;

    if (isNegative) {
        if (findLower) {
            q = Math.ceil(number / divisibleBy);
        } else {
            q = Math.floor(number / divisibleBy);
        }
    } else {
        if (findLower) {
            q = Math.floor(number / divisibleBy);
        } else {
            q = Math.ceil(number / divisibleBy);
        }
    }

    let n1 = divisibleBy * q;

    let n2 = number * divisibleBy > 0 ? divisibleBy * (q + 1) : divisibleBy * (q - 1);

    if (findLower) {
        return Math.min(n1, n2);
    }

    if (Math.abs(number - n1) < Math.abs(number - n2)) return n1;

    return n2;
};

//todo: look at this
export const getArraysExtremes = (range1?: (number | string)[], range2?: (number | string)[]): number[] => {
    if (typeof range1 === 'string' || typeof range2 === 'string' || !range1?.length || !range2?.length) {
        return [0, 0];
    }
    return [
        Math.min(range1[0] as number, range2[0] as number),
        Math.max(range1[range1.length - 1] as number, range2[range2.length - 1] as number)
    ];
};

export const isInPeriod = (
    dateFrom: DateTime,
    dateTo: DateTime,
    period: number,
    periodType: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months'
) => {
    if (!dateFrom || !dateTo) {
        return false;
    }
    if (!dateFrom.isValid || !dateTo.isValid || dateFrom.valueOf() > dateTo.valueOf()) {
        return false;
    }
    return dateTo.diff(dateFrom, [periodType]).values[periodType] <= period;
};

export function makeObjectNullable<T>(object: T): T {
    object &&
        Object.keys(object).map((key) => {
            if (key !== 'timeKey') {
                return (object[key] = null);
            }
        });
    return object;
}

export function deepCopyObj<T>(object: T): T {
    const toString = Object.prototype.toString;
    let recursive;
    switch (typeof object) {
        case 'object':
            if (object === null) {
                recursive = null;
            } else {
                switch (toString.call(object)) {
                    case '[object Array]':
                        recursive = (object as []).map(deepCopyObj);
                        break;
                    case '[object Date]':
                        recursive = new Date(typeof object == 'string' || object instanceof Date ? object : '');
                        break;
                    case '[object RegExp]':
                        recursive = new RegExp(typeof object == 'string' || object instanceof RegExp ? object : '');
                        break;
                    default:
                        recursive = Object.keys(object).reduce(function (prev, key) {
                            prev[key] = deepCopyObj(object[key]);
                            return prev;
                        }, {});
                        break;
                }
            }
            break;
        default:
            recursive = object;
            break;
    }
    return recursive;
}

export function propertyInObjectArray<T, K extends T[]>(value: string | number, key: string, object: K): boolean {
    if (!object?.length) {
        return false;
    }
    for (let i = 0, l = object.length; i < l; i++) {
        if (object[i][key] === value) {
            return true;
        }
    }
    return false;
}

export const toCamelCase_H = (str: string): string =>
    str.replace(/\w\S*/g, (m) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase()).replace(/(['_'])/g, ' ');

export const calculateTicks = (
    valueFrom: number,
    valueTo: number,
    interval: number = DEFAULT_GRAPH_SCALE,
    numberOfTicks = 1,
    isBar: boolean
): number[] => {
    let ticks: number[] = [];
    let lastCalculated: number = valueFrom;
    const maxTicks = 8;

    ticks.push(lastCalculated);
    while (lastCalculated < valueTo) {
        lastCalculated += interval;
        if (isBar) {
            ticks.push(Number(lastCalculated.toFixed(1)));
        } else {
            ticks.push(lastCalculated);
        }
    }

    if (ticks.length > maxTicks) {
        ticks = calculateTicks(valueFrom, valueTo, 2 * interval, numberOfTicks, isBar);
    }

    const needed: number = numberOfTicks - ticks.length;

    if (needed > 0) {
        for (let i = 0; i < needed; i++) {
            const first: number = ticks[0];
            const last: number = ticks[ticks.length - 1];
            if (i % 2 === 0) {
                ticks.unshift(first - interval);
            } else {
                ticks.push(last + interval);
            }
        }
    }

    return ticks;
};

export const generateYConfig = (
    yDomainConfig: yDomainConfig[],
    scale: number,
    globalYDomain?: { left: number[]; right: number[] },
    isBar = false
): yDomainConfig[] => {
    let numberOfTicksFirst = 1;
    return yDomainConfig.map((config, index) => {
        let newConfig: yDomainConfig = { ...config };
        if (globalYDomain) {
            let domainSource: number[] = globalYDomain.left;
            if (index === 1) {
                domainSource = globalYDomain.right;
            }
            let ticks: number[] = calculateTicks(domainSource[0], domainSource[1], scale, 1, index === 0 && isBar);
            if (numberOfTicksFirst === 1 && !config.isShared) {
                numberOfTicksFirst = ticks.length;
            } else {
                const customScale = config.isShared ? scale : 10;
                ticks = calculateTicks(
                    domainSource[0],
                    domainSource[1],
                    customScale,
                    numberOfTicksFirst,
                    index === 0 && isBar
                );
            }

            newConfig['ticks'] = ticks;
        }

        newConfig.ticks = newConfig.ticks?.map((num) => {
            return num % 1 !== 0 ? Math.round(num * Math.pow(10, isBar ? 3 : 2)) / Math.pow(10, isBar ? 3 : 2) : num;
        });

        return newConfig;
    });
};

export const getParams = (props: GetParamsProps): GetParamsReturnProps => {
    let params = {
        page: props.queryPageIndex + 1,
        limit: props.queryPageSize
    };
    if (props.queryPageSortBy.length > 0) {
        params['order'] = `${props.queryPageSortBy[0].id}:${props.queryPageSortBy[0].desc ? 'desc' : 'asc'}`;
    }
    if (props.queryPageFilter) {
        for (let i = 0; i < props.queryPageFilter.length; i++) {
            if (props.queryPageFilter[i].value.value !== undefined) {
                const filter: string =
                    props.queryPageFilter[i].value.value instanceof DateTime
                        ? props.queryPageFilter[i].value.value.invalid === null
                            ? props.queryPageFilter[i].value.value.toFormat('yyyy-MM-dd')
                            : 'Invalid date'
                        : props.queryPageFilter[i].value.value.isValid
                        ? props.queryPageFilter[i].value.value.toFormat('yyyy-MM-dd')
                        : props.queryPageFilter[i].value.value;
                const filterValue: string[] = typeof filter == 'string' ? filter.split(';') : filter;
                const key: string =
                    props.queryPageFilter[i].id !== 'verifiedStatus.name'
                        ? dotToCamel(props.queryPageFilter[i].id)
                        : 'verifiedStatus'; //temp fix
                params[key] =
                    props.queryPageFilter[i].id === 'deviceSerialConnectionType' ? filterValue : filterValue[0]; //temp fix
                params[key] = props.queryPageFilter[i].value.name === 'multipleValues' ? filterValue : filterValue[0]; //temp fix
            }
        }
    }

    return params;
};

//todo: move to new custom hook useConverter
export const DateToPHPFormat = (date: string): string => {
    const dates = {
        'YYYY-MM-DD': 'Y-m-d',
        'YYYY/MM/DD': 'Y/m/d',
        'MM/DD/YYYY': 'm/d/Y',
        'DD/MM/YYYY': 'd/m/Y'
    };
    return dates[date] ?? 'Y-m-d';
};

//todo: move to new custom hook useConverter
export const TimeToPHPFormat = (time: string): string => {
    const times = {
        'hh:mm': 'h:i',
        'HH:mm': 'H:i'
    };
    return times[time] ?? 'H:i';
};

//todo: move to new custom hook useConverter
export const fromPHPDateFormat = (date: string): string => {
    return date.replace(/Y/, 'YYYY').replace(/y/, 'yyyy').replace(/m/, 'MM').replace(/d/, 'DD');
};

//todo: move to new custom hook useConverter
export const fromPHPTimeFormat = (time: string): string => {
    return time.replace(/H/, 'HH').replace(/h/, 'hh').replace(/i/, 'mm');
};

//todo: move to new custom hook useConverter
export const simpleTimezone = (timezone: string): string => {
    return timezone.slice(timezone.indexOf('|') + 1);
};

export function setToLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
    return null;
}

export const getTranslations = (key: string): string => {
    const value = localStorage.getItem('translator');

    if (value) {
        let TranslationsList: Array<string> = JSON.parse(value);
        return TranslationsList[key] ?? key;
    }
    return key;
};

export const getGpsIconValues = (props: GpsIndicatorProps): GpsIconProps => {
    if (props.measuredAt && !props.outOfService && !props.maintenance && props.powerSource > 10000) {
        if (!DateTime.fromISO(props.lastGpsMeasuredAt).isValid) {
            return {
                show: true,
                color: 'blue'
            };
        }

        const vehicleTime: DateTime = DateTime.fromISO(props.measuredAt);
        const gpsTime: DateTime = DateTime.fromISO(props.lastGpsMeasuredAt);
        const currentTime: DateTime = DateTime.utc();

        if (currentTime.diff(vehicleTime, 'minutes').minutes < 30) {
            if (currentTime.diff(gpsTime, 'hours').hours > 24) {
                return {
                    show: true,
                    color: 'red'
                };
            } else if (currentTime.diff(gpsTime, 'minutes').minutes > 30) {
                return {
                    show: true,
                    color: 'orange'
                };
            }
        }
    }
    return {
        show: false,
        color: 'red'
    };
};

export const applyStyleByMode = (style: {
    styleJade?: string;
    theme?: ThemeMode | PaletteMode;
    dark?: string;
    light?: string;
}) => {
    const styleJade = style.styleJade || '';
    const light = style.light || '';
    const dark = style.dark || '';

    if (style.theme) {
        if (style.theme === ThemeMode.light) return light + styleJade;
        if (style.theme === ThemeMode.dark) return dark + styleJade;
    }
    return styleJade;
};

export const booleanToNumber = (value: boolean): 1 | 0 => {
    return value ? 1 : 0;
};

export const checkErrorsInCMD30 = (
    type: DiagnoseTable,
    value: number | string | null | DiagnoseSensor[],
    numberOfSensors?: number
): boolean => {
    if (value !== null && typeof value !== 'number') return false;
    switch (type) {
        case DiagnoseTable.BARO_PRESSURE:
            return value !== null ? value < 8000 || value > 12000 : true;
        case DiagnoseTable.BARO_TEMPERATURE:
            return value !== null ? value >= 700 : true;
        case DiagnoseTable.SENSOR_AVG_RSSI_REM:
            return value !== null ? value <= -850 || value === 0 : true;
        case DiagnoseTable.SENSOR_AVG_RSSI:
            return value !== null ? value <= -850 || (value === 0 && (numberOfSensors || 0) > 0) : true;
        case DiagnoseTable.VOLTAGE_TEMPERATURE:
            return value !== null ? value < 0 || value > 500 : true;
        case DiagnoseTable.VOLTAGE_BATTERY:
            return value !== null ? value <= 7300 : true;
        case DiagnoseTable.VOLTAGE_VEHICLE:
            return value !== null ? value <= 6900 : true;
        case DiagnoseTable.GPS_SATELLITES:
            return value !== null ? value <= 3 : true;
        case DiagnoseTable.ACCEL_Z:
        case DiagnoseTable.ACCEL_Y:
        case DiagnoseTable.ACCEL_X:
            return value !== null ? Math.abs(value) > 50 : true;
        case DiagnoseTable.ALERT_COUNT:
            return value !== null ? value > 0 : true;
        case DiagnoseTable.PSI:
            return value !== null ? value > 1400 : true;
        case DiagnoseTable.TEMPERATURE:
            return value !== null ? value > 1000 : true;
        default:
            return false;
    }
};

export const calculateGranularity = (dateFrom: DateTime, dateTo: DateTime) => {
    const withinMonth = DateTime.now().diff(dateFrom, ['days']).values?.days <= 30;
    const secondsDiff = dateTo.diff(dateFrom).valueOf() / 1000;

    const total = 600;
    if (withinMonth && secondsDiff / 3600 <= 3) {
        return 1;
    }

    return Math.ceil(secondsDiff / total);
};

export const createTimeline = (granularity: number, dateFrom: DateTime, dateTo: DateTime) => {
    let timeline = {};
    let dateFromR = granularity * Math.floor(dateFrom.startOf('minutes').valueOf() / 1000 / granularity);
    let dateToMoment = dateTo;

    let dateToR =
        dateToMoment.seconds || dateToMoment.milliseconds
            ? dateToMoment.plus({ minutes: 1 }).startOf('minutes').valueOf()
            : dateToMoment.startOf('minutes').valueOf();

    dateToR /= 1000;

    while (dateFromR < dateToR) {
        timeline[dateFromR] = { timeKey: dateFromR };
        dateFromR += granularity;
    }

    timeline[dateToR] = { timeKey: dateToR };

    return timeline;
};

export const getListDateFormat = () => {
    const listDateFormat: CommonDropDown[] = [
        {
            luxonValue: 'yyyy-MM-dd',
            value: 'yyyy-MM-dd',
            name: `YYYY-MM-DD (${DateTime.local().toFormat('yyyy-MM-dd')})`
        },
        {
            luxonValue: 'yyyy/MM/dd',
            value: 'YYYY/MM/DD',
            name: `YYYY/MM/DD (${DateTime.local().toFormat('yyyy/MM/dd')})`
        },
        {
            luxonValue: 'MM/dd/yyyy',
            value: 'MM/DD/YYYY',
            name: `MM/DD/YYYY (${DateTime.local().toFormat('MM/yyyy/dd')})`
        },
        {
            luxonValue: 'dd/MM/yyyy',
            value: 'DD/MM/YYYY',
            name: `DD/MM/YYYY (${DateTime.local().toFormat('dd/MM/yyyy')})`
        }
    ];

    return listDateFormat;
};

export const getListTimeFormat = () => {
    const listTimeFormat: CommonDropDown[] = [
        { value: 'HH:mm', name: `HH:mm (${DateTime.local().toFormat('HH:mm')})` },
        { value: 'hh:mm', name: `hh:mm (${DateTime.local().toFormat('hh:mm a')})` }
    ];

    return listTimeFormat;
};

export const getCustomerToken = (): string | undefined => {
    return !GlobalUserInfo?.user?.customer?.enabled ? undefined : GlobalUserInfo?.user?.customerToken;
};

export const updateAppTitle = (title: string, showAppName = true): void => {
    const appName = showAppName ? '- Bridgestone iTrack' : '';
    document.title = `${title} ${appName}`;
    setToLocalStorage('AppTitle', `${title} ${appName}`);
};

export const getPercentage = (value: number, total: number): number => {
    return value > 0 ? Math.round((value * 100) / total) : 0;
};

export const getColorOfChartForSystemTotals = (percentage: number): string => {
    if (percentage >= 95) return '#2c9f54';
    if (percentage >= 85) return '#b8842f';
    if (percentage >= 50) return '#694e12';
    return '#b43737';
};

export const diffDate = (dateFrom: DateTime | null, dateTo: DateTime | null): number => {
    return dateFrom.diff(dateTo).as('minutes');
};

export const getVersionFromService = (service: string, returnString?: string): string => {
    if (!service) return returnString ?? '';

    const matchResult = service.match(/^(\d+\.\d+\.\d+)/);

    if (matchResult && matchResult[1]) return matchResult[1];

    return returnString ?? '';
};

export const graphTicksFromDateTime = (valueFrom: DateTime, valueTo: DateTime, ticks: number): number[] => {
    const dateDifference = valueTo.valueOf() / 1000 - valueFrom.valueOf() / 1000;
    const splitDifference = dateDifference / (ticks - 1);
    const timeStampTicks: number[] = [];

    for (let i = 0; i < ticks - 1; i++) {
        timeStampTicks.push(valueFrom.valueOf() / 1000 + splitDifference * i);
    }

    timeStampTicks.push(valueTo.valueOf() / 1000);

    return timeStampTicks;
};

export const getBesRank = (besName: string | undefined, rank: number) => {
    if (besName === 'CSEP') {
        const CSEPRank = {
            0: -1,
            1: 3,
            2: 6,
            3: 4,
            4: 5
        };
        return CSEPRank[rank] || 0;
    }
    return rank;
};

export const getBesLabel = (besName: string | undefined, rank: number, translate?: any): string => {
    if (besName === 'CSEP') {
        switch (rank) {
            case 1:
                return `${besName}:${1}`;
            case 2:
                return translate('t.initial_check');
            case 3:
                return translate('t.for_repair');
            case 4:
                return translate('t.for_scrap');
        }
    }
    return `${besName}:${rank}`;
};

export const getBesTyreLabel = (
    besName: string | undefined,
    rank: number,
    translate?: any
): { top: string; bottom: string | number } => {
    let bottom: number = rank;
    let top: string = besName || '';
    if (besName === 'CSEP') {
        top = translate('t.for');
        if (rank > 1) {
            if (rank === 2) {
                top = translate('t.initial');
                bottom = translate('t.check');
            }
            if (rank === 3) {
                bottom = translate('t.for_repair2');
            }
            if (rank === 4) {
                bottom = translate('t.for_scrap2');
            }
        } else {
            bottom = 1;
            top = besName;
        }
    }
    return {
        top,
        bottom
    };
};

export const BESColors = {
    0: RANK0,
    1: RANK1,
    2: RANK2,
    3: RANK3,
    4: RANKNA2, // repair
    5: RANKNA, // scrap
    6: RANK4 // initial check
};

export const getClickableAxelColor = (allowSelectTyre: boolean, theme) => {
    if (allowSelectTyre) {
        if (theme.mode === ThemeMode.light) {
            return '#656565';
        }
        return '#fff';
    } else {
        if (theme.mode === ThemeMode.dark) {
            return '#7f7f7f';
        }
        return '#000';
    }
};

export const createSCV = (exportData: string, exportName: string) => {
    const blob = new Blob([exportData ?? ' '], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${exportName} report.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const freeEmailService = (email: string | null | undefined): boolean => {
    if (!email) {
        return false;
    }
    const emailDomain = email.split('@').pop();
    const freeDomain = ['gmail.com', 'yahoo.com', 'hotmail.com', 'msn.com'];
    return emailDomain ? freeDomain.includes(emailDomain) : false;
};

export const getUrlBasedOnHash = (routeHash: RouteHash, routeParams: LinkHashModel['params']) => {
    switch (routeHash) {
        case RouteHash.VEHICLE_STAS_MAP:
            return `/vehicle/${routeParams.v}/statistics`;
        default:
            return '/dashboard';
    }
};
export const getTimezoneOffset = (timezoneName: string): number => {
    const now = DateTime.now().setZone(timezoneName);
    return now.offset / 60;
};

export const getTimezoneOffsetFormatted = (timezoneName) => {
    const now = DateTime.now().setZone(timezoneName);
    const offsetMinutes = now.offset;
    const sign = offsetMinutes >= 0 ? '+' : '-';
    const absOffset = Math.abs(offsetMinutes);
    const hours = Math.floor(absOffset / 60)
        .toString()
        .padStart(2, '0');
    const minutes = (absOffset % 60).toString().padStart(2, '0');

    return `(GMT${sign}${hours}:${minutes})`;
};

export const getSortedTimezone = (
    timezones: ITimeZone[],
    dateTimeFormat: UseConverterReturnType['dateTimeFormat']
): ITimeZoneFormatted[] => {
    return timezones
        ?.map((timezone) => {
            return {
                ...timezone,
                timezoneFormatted: `${timezone?.timezoneName && timezone?.timezoneName} (${DateTime.local({
                    zone: timezone.timezoneName
                }).toFormat(dateTimeFormat('dateTime', true))})`,
                offset: getTimezoneOffset(timezone.timezoneName)
            };
        })
        .sort((a, b) => {
            if (a.offset > b.offset) {
                return 1;
            }
            if (a.offset < b.offset) {
                return -1;
            }
            return 0;
        });
};

export const rcUrl = {
    webtrack: 'rc.atmstechnology.com',
    freeport: 'rc-freeport.atmstechnology.com',
    au: 'rc-au.itrack.bridgestone',
    sa: 'rc-sa.itrack.bridgestone',
    na: 'rc-na.itrack.bridgestone'
};

export const getLastValidatedTempPredOutOfPeriod = (time: string | null) => {
    if (time) {
        const tempPredTime = DateTime.fromISO(time, { zone: 'UTC' });
        const currentTime = DateTime.now().setZone('UTC');
        const diff = currentTime.diff(tempPredTime, 'minutes').minutes;
        return diff >= 6;
    }
    return false;
};

export const isVehicleTyreBayHub = (vehicleModelId: number) => vehicleModelId === 9;

export const dateCheckByMonth = (dateUTC: string) => {
    const dateReceived = DateTime.fromISO(dateUTC);
    const currentDate = DateTime.now().toUTC();

    const startMonthReceived = dateReceived.startOf('month');
    const startMonthCurrent = currentDate.startOf('month');

    return startMonthCurrent.diff(startMonthReceived, 'months').months > 1;
};

export const createCSV = (data, exportName, setExportState?: React.Dispatch<React.SetStateAction<ExportState>>) => {
    const blob = new Blob([data ?? ' '], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${exportName} report.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExportState && setExportState(ExportState.SET_NONE);
    }
};
