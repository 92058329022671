import React, { useEffect, useRef, useState, useCallback } from 'react';
import { SensorsTableContent } from './SensorsTable.view';
import { SensorsTableProps } from './SensorsTable.type';
import { FilterContent } from 'components/CustomHooks/TableFilterState/TableFilterState.type';
import { Wrapper } from 'helpers/wrapper';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Sensors from 'api/Sensors';
import SensorType from 'api/SensorType';
import SensorHistory from 'api/SensorHistory';
import Menu from 'components/Ui/Components/UiMenu';
import Button from 'components/Ui/Components/UiButton/UiButton';
import UiCheckbox from 'components/Ui/Components/UiCheckbox/UiCheckbox';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { Success } from 'components/Popup/Popup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    ContainerTextModal,
    CustomLabel as CustomCheckerLabel,
    CustomFormControl,
    ShowDropdownUpdate
} from './SensorsTable.style';
import SensorStatisticsTabManager from '../SensorStatisticsTabManager/SensorStatisticsTabManager';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { Autocomplete, CircularProgress, FormControl } from '@mui/material';
import { COLOR_GREY, ERROR_DARK, GRAY, WHITE } from 'components/Ui/colors';
import { SensorQueryKeys, SensorTable, SensorTableResponse } from 'models/Sensors.type';
import CommentUpdate from '../CommentUpdate/CommentUpdate';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { UserInfo } from 'states/global/User';
import DropdownUpdate from '../DropdownUpdate/DropdownUpdate';
import AddSensor from '../AddSensor/AddSensor';
import RemoveSensor from '../RemoveSensor/RemoveSensor';
import ReplaceSensor from '../ReplaceSensor/ReplaceSensor';
import { TextField } from '@mui/material';
import useConverter from 'components/CustomHooks/Converter/Converter';
import WheelHistoryTable from 'components/Tyre/WheelHistoryTable/WheelHistoryTable';
import { DropdownTypesEnum } from '../DropdownUpdate/DropdownUpdate.type';
import useModal from 'components/CustomHooks/ModalHook';
import TemperatureExposureSensorModal from '../TemperatureExposureSensorModal';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { applyStyleByMode, createSCV } from 'helpers';
import { Theme } from 'states/global/Theme';
import UiIcon from 'components/Ui/Components/UiIcon';
import { ERROR } from 'components/Ui/colors';
import { faBatteryLow } from '@fortawesome/pro-solid-svg-icons';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { TableAtom } from 'components/Ui/UiTable/TableAtom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import UiCheckboxForTable from 'components/Ui/Components/UiCheckboxForTable/UiCheckboxForTable';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import { DateTimePicker } from 'states/global/Statistics';
import { DateTime } from 'luxon';
import { SensorHistoryQueryKeys } from 'models/SensorHistory.type';
import { SensorTypeQueryKeys } from 'models/SensorType.type';
import { cacheTimeToMilliseconds, removeAllQueryKeysFromModel } from 'helpers/cache';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const sensorApi = new Sensors();
const sensorTypeApi = new SensorType();
const sensorHistoryTypeApi = new SensorHistory();
const BATTERY_LOW_VOLTAGE = 2690;
const TABLE_KEY = 'firstSensorPage';

const SensorsTable: React.FC<SensorsTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryTableFn = useRecoilValue(QueryFn(`${TABLE_KEY}-QueryFn`));
    const userInfo = useRecoilValue(UserInfo);
    const queryClient = useQueryClient();
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const refForAddSensor = useRef<HTMLButtonElement>(null);
    const { fromUTCtoUserTimezone } = useConverter();
    const [serialNumberHex, setSerialNumberHex] = useState<string | undefined | null>(undefined);
    const [inactiveFilter, setInactiveFilter] = useState(false);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const { setUserSettings } = useUserLocalStorageSettings([`table.${TABLE_KEY}Table`]);
    const { setModalState } = useModal();
    const ThemeState = useRecoilValue(Theme);
    const [sensorCheckedId, setSensorCheckedId] = useState<number>();
    const [sensorIds, setSensorIds] = useState<number[]>([]);
    const [globalDateTimePicker, setGlobalDateTimePicker] = useRecoilState(DateTimePicker);
    const [tableState, setTableState] = useRecoilState(TableAtom(`${TABLE_KEY}-Table`));

    const removeFilterFromLocalStorage = (key: string): void => {
        if (getUserSettingTable(`table.${TABLE_KEY}Table`)?.filter.find((currentFilter) => currentFilter.id === key)) {
            setUserSettings(`table.${TABLE_KEY}Table`, {
                ...getUserSettingTable(`table.${TABLE_KEY}Table`),
                filter: getUserSettingTable(`table.${TABLE_KEY}Table`)?.filter.filter(
                    (currentFilter) => currentFilter.id !== key
                )
            });
        }
    };

    const getSensorSerialNumberHex = (): string | null => {
        let id: string | null;
        const urlParams = new URLSearchParams(location.search);
        id = urlParams.get('id');
        urlParams.get('inactive') && setInactiveFilter(true);

        if (id) {
            return id;
        }

        return null;
    };

    const options = [
        { label: 'True', value: 'true' },
        { label: 'False', value: 'false' }
    ];

    const { data: sensorTypeData } = useQuery(
        [SensorTypeQueryKeys.getSensorsTypeCodebook],
        sensorTypeApi.getSensorsTypeCodebook,
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const {
        data: exportSensorWheelHistoryData,
        isFetching: isLoadingExportSensorWheelHistory,
        refetch: exportSensorWheelHistoryRefetch
    } = useQuery(
        [SensorHistoryQueryKeys.exportSensorWheelHistoryByIdSensors],
        () => sensorHistoryTypeApi.exportSensorWheelHistoryByIdSensors(sensorIds),
        {
            retry: false,
            keepPreviousData: true,
            enabled: false,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(5, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(5, 'minutes')
        }
    );

    const { mutate: removeSensorMutate, isLoading: removeSensorLoading } = useMutation(
        sensorApi.patchRemoveSensorOnWheel,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.sensor_on_wheel_removed')
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
                modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
                removeAllQueryKeysFromModel(SensorQueryKeys, queryClient);
            },
            onError: (e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    );

    const { mutate: addSensorMutate, isLoading: addSensorLoading } = useMutation(sensorApi.patchAddSensorOnWheel, {
        onSuccess: () => {
            Success({
                text: translate('t.sensor_on_wheel_added')
            });
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
            removeAllQueryKeysFromModel(SensorQueryKeys, queryClient);
        },
        onError: (e) => {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    });

    const { mutate: handlePressureChecked, isLoading: checkedPressureLoading } = useMutation(
        sensorApi.patchPressureStatus,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.edited_press_status')
                });
                removeAllQueryKeysFromModel(SensorQueryKeys, queryClient);
            },
            onSettled: () => {
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            },
            onError: (e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    );

    const { mutate: handleTemperatureChecked, isLoading: checkedTemperatureLoading } = useMutation(
        sensorApi.patchTemperatureStatus,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.edited_temp_status')
                });
                removeAllQueryKeysFromModel(SensorQueryKeys, queryClient);
            },
            onSettled: () => {
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            },
            onError: (e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            }
        }
    );

    const contentModalResolved = {
        id: 'ModalConfrim',
        content: <></>,
        leftTitle: '',
        width: 370,
        height: 60,
        widthUnit: 'px',
        heightUnit: 'px',
        buttons: (
            <Button skin='success' color='primary' variant={VARIANT_BUTTON}>
                {translate('t.remove')}
            </Button>
        ),
        onClose: () =>
            modalDispach({
                type: ModalActionTypesEnum.SET_MODAL_STATE,
                ModalPropsPayload: {
                    ...contentModalResolved,
                    isOpen: false
                }
            }),
        isOpen: true
    };

    const removeSensor = (sensor: SensorTable) => {
        const newModal = {
            customContent: (
                <RemoveSensor
                    sensor={sensor}
                    removeSensorLoading={removeSensorLoading}
                    removeSensorMutate={removeSensorMutate}
                />
            ),
            content: undefined,
            leftTitle: translate('t.remove_sensor_from_wheel'),
            cancelColor: GRAY,
            width: 450,
            height: 230
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    const addSensor = (id) => {
        const newModal = {
            content: <AddSensor refForAddSensor={refForAddSensor} addSensorMutate={addSensorMutate} sensorId={id} />,
            leftTitle: translate('t.add_wheel'),
            cancelColor: GRAY,
            width: 750,
            height: 350,
            buttons: (
                <Button
                    skin='success'
                    color='primary'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        refForAddSensor?.current && refForAddSensor?.current.click();
                    }}
                >
                    {addSensorLoading ? <CircularProgress size='1.75em' /> : translate('t.add')}
                </Button>
            )
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            ...newModal,
                            isOpen: false
                        }
                    }),
                isOpen: true
            }
        });
    };

    const replaceSensor = (data: SensorTable) => {
        const newModal = {
            customContent: <ReplaceSensor refForReplaceSensor={refForAddSensor} sensor={data} />,
            content: undefined,
            leftTitle: translate('t.replace_sensor'),
            rightTitle: `${translate('t.position')}:${data.wheel?.customPosition}`,
            width: 750,
            height: 260
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            ...newModal,
                            isOpen: false
                        }
                    }),
                isOpen: true
            }
        });
    };

    const wheelHistoryContent = (id, serialNumberHex) => {
        const newModal = {
            content: <WheelHistoryTable sensorId={id} />,
            leftTitle: translate('t.wheel_history'),
            rightTitle: `${translate('t.sensor_serial_number')} ${serialNumberHex}`,
            cancelColor: GRAY,
            width: 920,
            height: 100,
            heightUnit: '%',
            buttons: <></>
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    const handleTemperatureExposureModal = useCallback((sensor: SensorTableResponse['items'][0]) => {
        if (sensor.id) {
            // this should be fixed, not optional sensorid
            setModalState({
                isOpen: true,
                content: <TemperatureExposureSensorModal sensorId={sensor.id} />,
                width: 99,
                widthUnit: 'vw',
                height: 430,
                leftTitle: translate('t.distribution_temperature_by_sensor'),
                rightTitle: `${translate('t.sensor')} ${sensor.serialNumberHex}`
            });
        }
    }, []);

    const checkAddOnWheelStatus = (status: string): boolean => {
        return !!(
            status.toLowerCase() == 'removing faulty' ||
            status.toLowerCase() == 'inactive' ||
            status.toLowerCase() == 'suspicious' ||
            status.toLowerCase() == 'fault'
        );
    };

    const endOfLife = (sensor: SensorTableResponse['items'][0]) => {
        const newModal = {
            content: <ContainerTextModal>{translate('p.remove_sensor_due_to_end_of_life')}</ContainerTextModal>,
            leftTitle: `${translate('t.end_of_life')} (${translate('t.sensor')} ${sensor.serialNumberHex})`,
            cancelColor: GRAY,
            buttons: (
                <Button
                    skin='error'
                    color='error'
                    variant={VARIANT_BUTTON}
                    onClick={() => {
                        removeSensorMutate({ sensorId: sensor.id, unmountReason: 'End of life' });
                    }}
                >
                    {translate('t.yes')}
                </Button>
            )
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () =>
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    }),
                isOpen: true
            }
        });
    };

    const getStatisticsTabManager = (sensorId: number | undefined, sensorHex: string | undefined) => {
        const newModal = {
            content: <SensorStatisticsTabManager sensorId={sensorId ?? 0} />,
            width: 800,
            height: 400,
            buttons: <></>,
            cancelColor: GRAY,
            leftTitle: translate('t.sensor_statistics'),
            rightTitle: `${translate('t.sensor')} #${sensorHex}`
        };
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalResolved,
                ...newModal,
                onClose: () => {
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalResolved,
                            isOpen: false,
                            ...newModal
                        }
                    });
                    setTimeout(() => {
                        modalDispach({
                            type: ModalActionTypesEnum.SET_MODAL_STATE,
                            ModalPropsPayload: {
                                ...contentModalResolved,
                                isOpen: false,
                                ...newModal
                            }
                        });
                    }, 300);
                },
                isOpen: true
            }
        });
    };

    const updateModal = () => {
        modalDispach({
            type: ModalActionTypesEnum.SET_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: <Button>{<CircularProgress size='1.75em' />}</Button>
            }
        });
    };

    useEffect(() => {
        updateModal();
    }, [removeSensorLoading, addSensorLoading]);

    const columns: Column<SensorTableResponse['items'][0]>[] = [
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'analysis',
            width: 35,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <UiCheckbox
                    testid='Checkbox-List-Sensor'
                    checked={Boolean(sensorIds.find((id) => id === props.row.original.id))}
                    name={`${props.row.original.id}_checkbox_list_sensor`}
                    color='primary'
                    onChange={(e) => {
                        setSensorIds(
                            e.target.checked
                                ? [...sensorIds, props.row.original.id]
                                : [...sensorIds.filter((id) => id !== props.row.original.id)]
                        );
                    }}
                />
            )
        },
        {
            Header: <UiIcon icon={faBatteryLow} size='sm' fixedWidth />,
            accessor: 'battery_low',
            width: 50,
            height: 20,
            disableFilters: true,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>
                    {props.row.original.sensorStats &&
                    props.row.original.sensorStats[0]?.battery !== null &&
                    props.row.original.sensorStats[0]?.measuredAt !== null &&
                    props.row.original.sensorStats[0]?.battery <= BATTERY_LOW_VOLTAGE ? (
                        <UiIcon
                            icon={faBatteryLow}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: ThemeState?.mode,
                                light: ERROR,
                                dark: ERROR_DARK
                            })}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )
        },
        checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
            ? {
                  Header: translate('t.sensor_id'),
                  accessor: 'id',
                  width: 100,
                  height: 20,
                  Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                      <>{props.row.original.id ?? '-'}</>
                  )
              }
            : {
                  Header: '',
                  accessor: 'id',
                  width: 0,
                  height: 0,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: () => <></>
              },
        checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
            ? {
                  Header: `${translate('t.serial_number')}(DEC)`,
                  accessor: 'sensorSerialNumber',
                  width: 120,
                  height: 20,
                  Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                      <>{props.row.original.sensorSerialNumber ?? '-'}</>
                  )
              }
            : {
                  Header: '',
                  accessor: 'sensorSerialNumber',
                  width: 0,
                  height: 0,
                  disableFilters: true,
                  disableSortBy: true,
                  Cell: () => <></>
              },
        {
            Header: `${translate('t.serial_number')}`,
            accessor: 'serialNumberHex',
            width: 140,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.serialNumberHex ?? '-'}</>
            )
        },
        {
            Header: translate('t.sensor_type'),
            accessor: (row: SensorTableResponse['items'][0]) => row.sensorType?.name,
            id: 'sensorTypeId',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                if (
                    checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_BASE_USER, ROLES.ROLE_TECHNICAL_USER])
                ) {
                    return <>{props.row.original.sensorType?.name ?? '-'}</>;
                }

                return (
                    <>
                        {props.row.original.wheel?.vehicle?.name ? (
                            <DropdownUpdate
                                sensorId={props.row.original.id}
                                valueId={props.row.original.sensorType?.id}
                                type={DropdownTypesEnum.SENSOR_TYPE}
                                testid={`DropdownUpdate-SensorType-${props.row.original.id}`}
                                showComment={
                                    props.row.original.sensorType?.name != null
                                        ? props.row.original.sensorType?.name
                                        : '-'
                                }
                            />
                        ) : (
                            <ShowDropdownUpdate>{props.row.original.sensorType?.name ?? '-'}</ShowDropdownUpdate>
                        )}
                    </>
                );
            },
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <CustomFormControl variant='outlined' size='small'>
                        <Autocomplete
                            options={sensorTypeData || []}
                            getOptionLabel={(option) => (option ? String(option.name) || '' : '')}
                            onChange={(e, newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.sensor_type'),
                                          humanValue: newValue.name,
                                          value: `${newValue.id}`
                                      };

                                setFilterValue(value);
                            }}
                            size='small'
                            defaultValue={sensorTypeData?.find((element) => element.id === Number(cellValue?.value))}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate('t.sensor_type')}
                                    variant='outlined'
                                    name='sensor_type'
                                    value={column.filterValue?.value || ''}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    </CustomFormControl>
                );
            }
        },
        {
            Header: translate('t.vehicle_name'),
            accessor: (row: SensorTableResponse['items'][0]) => row.wheel?.vehicle?.name,
            id: 'vehicleName',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.wheel?.vehicle?.name ?? '-'}</>
            )
        },
        {
            Header: translate('t.position'),
            accessor: (row: SensorTableResponse['items'][0]) => row.wheel?.customPosition,
            id: 'wheel.customPosition',
            width: 80,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original.wheel?.customPosition ?? '-'}</>
            )
        },
        {
            Header: translate('t.status'),
            accessor: (row: SensorTableResponse['items'][0]) => row.sensorInfo?.status,
            id: 'sensorInfo.status',
            disableFilters: true,
            width: 70,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                return (
                    <>
                        {checkRole(userInfo.user, [
                            ROLES.ROLE_SUPER_ADMIN,
                            ROLES.ROLE_ADMIN,
                            ROLES.ROLE_TYRE_BAY_USER
                        ]) ? (
                            <DropdownUpdate
                                testid={`DropdownUpdate-Status-${props.row.original.id}`}
                                sensorId={props.row.original.id}
                                type={DropdownTypesEnum.STATUS}
                                valueId={props.row.original?.sensorInfo?.sensorStatus?.id ?? 0}
                                showComment={props.row.original?.sensorInfo?.status ?? '-'}
                            />
                        ) : (
                            <ShowDropdownUpdate> {props.row.original?.sensorInfo?.status ?? '-'} </ShowDropdownUpdate>
                        )}
                    </>
                );
            }
        },
        {
            Header: translate('t.battery'),
            accessor: (row: SensorTableResponse['items'][0]) => row.sensorStats[0]?.battery,
            width: 80,
            height: 20,
            disableSortBy: true,
            Cell: (props: { value: string; row: { original: SensorTableResponse['items'][0] } }) => (
                <>{props.row.original?.sensorStats?.length > 0 ? props.row.original.sensorStats[0].battery : 0}</>
            ),
            Filter: ({ setFilter, state, column, ...filterProps }) => {
                const { cellValue: cellValueFrom, setFilterValue: setFilterValueFrom } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'sensorBatteryVoltageFrom')[0]?.value
                            ?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'sensorBatteryVoltageFrom'
                });

                const { cellValue: cellValueTo, setFilterValue: setFilterValueTo } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: state.filters.filter((filter) => filter.id === 'sensorBatteryVoltageTo')[0]?.value?.value
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: 'sensorBatteryVoltageTo'
                });

                return (
                    <>
                        <CustomFormControl variant='outlined' size='small'>
                            <TextField
                                id='outlined-number'
                                label={translate('t.battery_voltage_from')}
                                type='number'
                                size='small'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={cellValueFrom?.value || null}
                                onChange={(e) => {
                                    setFilterValueFrom({
                                        value: e.target.value,
                                        name: translate('t.battery_voltage_from')
                                    });
                                }}
                            />
                        </CustomFormControl>
                        <CustomFormControl variant='outlined' size='small'>
                            <TextField
                                id='outlined-number'
                                label={translate('t.battery_voltage_to')}
                                type='number'
                                size='small'
                                value={cellValueTo?.value || null}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={(e) => {
                                    setFilterValueTo({
                                        value: e.target.value,
                                        name: translate('t.battery_voltage_to')
                                    });
                                }}
                            />
                        </CustomFormControl>
                    </>
                );
            }
        },
        {
            Header: translate('t.pressure_status'),
            accessor: 'pressureStatus',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <UiCheckboxForTable
                    testid='pressureStatus'
                    checked={props.row.original.pressureStatus}
                    disabled={!checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN])}
                    name={`${props.row.original.id}_checkbox`}
                    color='primary'
                    checkboxLoading={sensorCheckedId === props.row.original.id && checkedPressureLoading}
                    onChange={() => {
                        setSensorCheckedId(props.row.original.id);
                        handlePressureChecked({
                            id: props.row.original.id,
                            data: !props.row.original.pressureStatus
                        });
                    }}
                />
            ),
            Filter: ({ column, setFilter, state, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <CustomFormControl variant='outlined' size='small'>
                        <Autocomplete
                            options={options}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.pressure_status'),
                                          humanValue: newValue.label,
                                          value: newValue.value
                                      };

                                setFilterValue(value);
                            }}
                            size='small'
                            defaultValue={options.find((element) => element.value === column.filterValue?.value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate('t.pressure_status')}
                                    variant='outlined'
                                    name='pressureStatus'
                                    value={cellValue?.value || null}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    </CustomFormControl>
                );
            }
        },
        {
            Header: translate('t.temperature_status'),
            accessor: 'temperatureStatus',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => (
                <UiCheckboxForTable
                    testid='temperatureStatus'
                    checked={props.row.original.temperatureStatus}
                    disabled={!checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN])}
                    name={`${props.row.original.id}_checkbox`}
                    color='primary'
                    checkboxLoading={sensorCheckedId === props.row.original.id && checkedTemperatureLoading}
                    onChange={() => {
                        setSensorCheckedId(props.row.original.id);
                        handleTemperatureChecked({
                            id: props.row.original.id,
                            data: !props.row.original.temperatureStatus
                        });
                    }}
                />
            ),
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <CustomFormControl variant='outlined' size='small'>
                        <Autocomplete
                            options={options}
                            getOptionLabel={(option) => option.label}
                            onChange={(e, newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.temperature_status'),
                                          humanValue: newValue.label,
                                          value: newValue.value
                                      };

                                setFilterValue(value);
                            }}
                            size='small'
                            defaultValue={options.find((element) => element.value === column.filterValue?.value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={translate('t.temperature_status')}
                                    variant='outlined'
                                    name='temperature_status'
                                    value={cellValue?.value || null}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    </CustomFormControl>
                );
            }
        },
        {
            Header: translate('t.comment'),
            accessor: 'comment',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) => {
                if (checkRole(userInfo.user, [ROLES.ROLE_ENGINEERING, ROLES.ROLE_BASE_USER])) {
                    return <>{props.row.original.comment ?? '-'}</>;
                }

                return <CommentUpdate commentId={props.row.original.id} showComment={props.row.original.comment} />;
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) =>
                fromUTCtoUserTimezone({
                    date: props.row.original.createdAt,
                    format: 'dateTime',
                    displaySeconds: true
                }) != 'Invalid DateTime' ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.createdAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={`${translate('t.created_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={`${translate('t.created_at')} ${translate('t.to')}`}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.measured_at'),
            accessor: 'measuredAt',
            width: 120,
            height: 20,
            Cell: (props: { row: { original: SensorTableResponse['items'][0] } }) =>
                fromUTCtoUserTimezone({
                    date: props.row.original.sensorInfo?.measuredAt,
                    format: 'dateTime',
                    displaySeconds: true
                }) != 'Invalid DateTime' ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.sensorInfo?.measuredAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtFrom'
                            keyFilter='measuredAtFrom'
                            label={`${translate('t.measured_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtTo'
                            keyFilter='measuredAtTo'
                            label={`${translate('t.measured_at')} ${translate('t.to')}`}
                        />
                    </>
                );
            }
        },
        {
            Header: translate('t.actions'),
            width: 90,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { value: number; row: { original: SensorTableResponse['items'][0] } }) => (
                <>
                    <Menu
                        testid='Menu-Sensors'
                        items={[
                            {
                                title: translate('t.statistics'),
                                value: props.value,
                                action: () => {
                                    getStatisticsTabManager(props.row.original.id, props.row.original.serialNumberHex);
                                }
                            },
                            {
                                title: translate('t.wheel_history'),
                                value: props.value,
                                action: () => {
                                    wheelHistoryContent(props.row.original.id, props.row.original.serialNumberHex);
                                }
                            },

                            checkRole(userInfo.user, [
                                ROLES.ROLE_SUPER_ADMIN,
                                ROLES.ROLE_ADMIN,
                                ROLES.ROLE_TYRE_BAY_USER
                            ])
                                ? props.row.original.wheel?.vehicle
                                    ? {
                                          title: translate('t.replace'),
                                          value: props.value,
                                          action: () => {
                                              replaceSensor(props.row.original);
                                          }
                                      }
                                    : {
                                          title: '',
                                          none: true,
                                          value: ''
                                      }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  },

                            checkRole(userInfo.user, [
                                ROLES.ROLE_SUPER_ADMIN,
                                ROLES.ROLE_ADMIN,
                                ROLES.ROLE_TYRE_BAY_USER,
                                ROLES.ROLE_TECHNICAL_ADMIN
                            ])
                                ? props.row.original.wheel?.vehicle
                                    ? {
                                          title: translate('t.remove_from_wheel'),
                                          value: props.value,
                                          action: () => {
                                              removeSensor(props.row.original);
                                          }
                                      }
                                    : !checkAddOnWheelStatus(props.row.original.sensorInfo?.status ?? '')
                                    ? {
                                          title: translate('t.add_wheel'),
                                          value: props.value,
                                          action: () => {
                                              addSensor(props.row.original.id);
                                          }
                                      }
                                    : {
                                          title: '',
                                          none: true,
                                          value: ''
                                      }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  },
                            checkRole(userInfo.user, [
                                ROLES.ROLE_SUPER_ADMIN,
                                ROLES.ROLE_ADMIN,
                                ROLES.ROLE_TYRE_BAY_USER
                            ])
                                ? props.row.original.wheel?.vehicle
                                    ? {
                                          title: translate('t.end_of_life'),
                                          value: props.value,
                                          action: () => {
                                              endOfLife(props.row.original);
                                          }
                                      }
                                    : {
                                          title: '',
                                          none: true,
                                          value: ''
                                      }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  },
                            {
                                title: translate('t.report_sensor'),
                                value: props.value,
                                action: () => {
                                    setFaultySensorReporterState({
                                        vehicleId: props.row.original.wheel?.vehicle?.id ?? 0,
                                        vehicleName: props.row.original.wheel?.vehicle?.name ?? '',
                                        sensorId: props.row.original.id ?? 0,
                                        sensorName: props.row.original.serialNumberHex ?? '',
                                        showOnlyGraph: true,
                                        wheelPosition: props.row.original.wheel?.position ?? 0,
                                        internalOnVehicle: true,
                                        isExternal: props.row.original.sensorType?.id === 2,
                                        allowReportLeakAlert: true
                                    });
                                }
                            },
                            {
                                title: translate('t.sensor_temp_levels'),
                                value: '',
                                action: () => {
                                    handleTemperatureExposureModal(props.row.original);
                                }
                            },
                            props.row.original.wheel?.vehicle?.id
                                ? {
                                      title: (
                                          <UiLink
                                              $padding='0'
                                              data-testid={`Vehicle-Details-Button-${props.row.original.wheel?.vehicle?.id}`}
                                              content={translate('t.vehicle_details')}
                                              url={`/vehicle-status?vehicle=${props.row.original.wheel?.vehicle?.id}`}
                                              color={applyStyleByMode({
                                                  theme: ThemeState?.mode,
                                                  light: COLOR_GREY,
                                                  dark: WHITE
                                              })}
                                          />
                                      ),
                                      value: ''
                                  }
                                : {
                                      title: '',
                                      none: true,
                                      value: ''
                                  }
                        ]}
                        rootEl={
                            <UiIconButton
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                testid={`Action-Button-${props.row.original.id}`}
                            >
                                <SettingsIcon />
                            </UiIconButton>
                        }
                    />
                </>
            )
        },
        {
            Header: '',
            accessor: 'inactive',
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.show_inactive_sensors'),
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });

                return (
                    <FormControl variant='outlined' size='small'>
                        <div className='ContainerChecboxFilter'>
                            <CustomCheckerLabel>{translate('t.show_inactive_sensors')}</CustomCheckerLabel>
                            <UiCheckbox
                                checked={cellValue?.value === '1'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value: {} | undefined = undefined;
                                    if (e.target.checked) {
                                        value = {
                                            name: translate('t.show_inactive_sensors'),
                                            value: '1',
                                            humanValue: 'True'
                                        };
                                    }
                                    setFilterValue(value);
                                }}
                                name='_checkboxInactive'
                                color='primary'
                            />
                        </div>
                    </FormControl>
                );
            }
        },
        {
            Header: '',
            accessor: 'isWheel',
            disableSortBy: true,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.sensors_attached_wheels'),
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small'>
                        <div className='ContainerChecboxFilter'>
                            <CustomCheckerLabel>{translate('t.sensors_attached_wheels')}</CustomCheckerLabel>
                            <UiCheckbox
                                checked={cellValue?.value === '1'}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value: {} | undefined = undefined;
                                    if (e.target.checked) {
                                        value = {
                                            name: translate('t.sensors_attached_wheels'),
                                            value: '1',
                                            humanValue: 'True'
                                        };
                                    }
                                    setFilterValue(value);
                                }}
                                name='_checkboxInactive'
                                color='primary'
                            />
                        </div>
                    </FormControl>
                );
            }
        },
        {
            Header: '',
            accessor: 'sensorStatsBatteryTo',
            disableSortBy: true,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.sensors_with_low_battery'),
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FormControl variant='outlined' size='small'>
                        <div className='SensorsContainerChecboxFilterLowBattery'>
                            <CustomCheckerLabel>{translate('t.sensors_with_low_battery')}</CustomCheckerLabel>
                            <UiCheckbox
                                checked={cellValue?.value === `${BATTERY_LOW_VOLTAGE}`}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value: FilterContent = undefined;
                                    if (e.target.checked) {
                                        value = {
                                            name: translate('t.sensors_with_low_battery'),
                                            value: `${BATTERY_LOW_VOLTAGE}`,
                                            humanValue: 'True'
                                        };
                                    }
                                    setFilterValue(value);
                                }}
                                name='_checkboxInactiveSensors'
                                color='primary'
                            />
                        </div>
                    </FormControl>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'sensorInfoStatus'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'sensorBatteryVoltageFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'sensorBatteryVoltageTo'
        }
    ];

    useEffect(() => {
        setSerialNumberHex(getSensorSerialNumberHex());
        setGlobalDateTimePicker({
            ...globalDateTimePicker,
            current: {
                dateFrom: DateTime.now().minus({ days: 3 }),
                dateTo: DateTime.now()
            },
            original: {
                dateFrom: DateTime.now().minus({ days: 3 }),
                dateTo: DateTime.now()
            }
        });
    }, []);

    useEffect(() => {
        if (serialNumberHex && tableState.tableKey === TABLE_KEY) {
            removeFilterFromLocalStorage('serialNumberHex');
            inactiveFilter && removeFilterFromLocalStorage('inactive');

            const tableStateFiltered = inactiveFilter
                ? tableState.queryPageFilter.filter(
                      (currentFilter) => currentFilter.id !== 'serialNumberHex' && currentFilter.id !== 'inactive'
                  )
                : tableState.queryPageFilter.filter((currentFilter) => currentFilter.id !== 'serialNumberHex');

            const queryPageFilter = [
                ...tableStateFiltered,
                {
                    id: 'serialNumberHex',
                    value: {
                        name: translate('t.serial_number'),
                        value: serialNumberHex,
                        humanValue: serialNumberHex
                    }
                }
            ];

            const inactiveFilterQueryFilter = [
                {
                    id: 'inactive',
                    value: {
                        name: translate('t.show_inactive_sensors'),
                        value: '1',
                        humanValue: 'True'
                    }
                }
            ];

            setTableState({
                ...tableState,
                queryPageFilter: inactiveFilter ? queryPageFilter.concat(inactiveFilterQueryFilter) : queryPageFilter
            });
            setSerialNumberHex(null);
        }
    }, [tableState.tableKey]);

    useEffect(() => {
        if (exportSensorWheelHistoryData?.length && !isLoadingExportSensorWheelHistory) {
            createSCV(exportSensorWheelHistoryData, 'SensorsWheelHistory');
        }
    }, [isLoadingExportSensorWheelHistory]);

    return (
        <SensorsTableContent
            data-testid='SensorsTable-testid'
            columns={columns}
            sensorIds={sensorIds}
            isLoadingExportSensorWheelHistory={isLoadingExportSensorWheelHistory}
            exportSensorWheelHistoryRefetch={exportSensorWheelHistoryRefetch}
        />
    );
};

export default Wrapper(SensorsTable);
