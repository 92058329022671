import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { SIZE_TEXT_VARIANT } from '../Ui/variables';

export const ModalContainer = styled.div``;

export const DialogTitle = styled(MuiDialogTitle)`
    &.MuiDialogTitle-root {
        font-size: ${SIZE_TEXT_VARIANT(2)};
    }
`;

export const ContainerDialogTitle = styled.div`
    margin: 0 auto;
    width: 97%;
`;

export const LeftTitle = styled.span`
    float: left;
`;

export const RightTitle = styled.span`
    float: right;
`;

export const DialogBody = styled(Dialog)<{
    width?: number;
    height?: number;
    widthunit?: string;
    heightunit?: string;
    $allowContentOverflow?: boolean;
    $maxWidth?: string;
    $maxHeight?: string;
}>`
    & .MuiDialog-paper {
        width: ${(props) => props.width}${(props) => props.widthunit};

        ${({ $maxWidth, width, widthunit }) => {
            if (!$maxWidth) {
                return `min-width: ${width}${widthunit};`;
            }
        }}

        max-width: ${(props) => props.$maxWidth || props.width}${(props) => (props.$maxHeight ? '' : props.widthunit)};
        max-height: ${(props) => props.$maxHeight || props.height}${(props) => (props.$maxHeight ? '' : props.heightunit)};

        ${(props) => {
            return `
                border-radius: 4px;
                height: ${props.height}${props.heightunit};
            `;
        }}

        ${() => useTheme().breakpoints.between('xs', 'md')} {
            min-width: auto;
            height: auto;
            max-height: 95vh;
            min-height: 60px;
        }
    }
`;

export const DialogActions = styled(MuiDialogActions)`
    margin: auto;
    border-top: solid thin #c0c0c0;
    width: 97%;
`;

export const CancelButton = styled(Button)<{ customcolor?: string }>``;
