import styled from 'styled-components';
import { Box, Collapse, Divider, Fab, TableCell, TableRow, useTheme } from '@mui/material';
import { AlertFontSize, CommonTypeTheme } from '../../../../Ui/Theme';
import { applyStyleByMode, BESColors } from 'helpers';
import {
    ALERT_LEVEL_0,
    ALERT_LEVEL_0_DARK,
    ALERT_LEVEL_0_DARK_HOVER,
    ALERT_LEVEL_0_HOVER,
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_1_DARK_HOVER,
    ALERT_LEVEL_1_HOVER,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_2_DARK_HOVER,
    ALERT_LEVEL_2_HOVER,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_LEVEL_3_DARK_HOVER,
    ALERT_LEVEL_3_HOVER,
    COLOR_GREY,
    PRIMARY,
    WHITE
} from '../../../../Ui/colors';
import { ThemeMode } from '../../../../../states/global/Theme';
import { ReactComponent as HighPressureIcon } from '../../../../../assets/svg/pressureAlertHigh.svg';
import { ReactComponent as LowPressureIcon } from '../../../../../assets/svg/pressureAlertLow.svg';

export const VehicleStatsRowContent = styled.div``;

export const FabCustom = styled(Fab)`
    &.MuiFab-root {
        margin: 0;
        width: 20px;
        height: 20px;
        min-height: 20px;
        margin-right: 6px;
        background: transparent;
    }
`;

export const StyledTableRow = styled(TableRow)<{ alertlevel: number }>`
    &:hover {
        transition: background-color 500ms linear 0s;
        cursor: pointer;
        background: ${(props) => {
            const alertColors = {
                light: {
                    0: ALERT_LEVEL_0_HOVER,
                    1: ALERT_LEVEL_1_HOVER,
                    2: ALERT_LEVEL_2_HOVER,
                    3: ALERT_LEVEL_3_HOVER
                },
                dark: {
                    0: ALERT_LEVEL_0_DARK_HOVER,
                    1: ALERT_LEVEL_1_DARK_HOVER,
                    2: ALERT_LEVEL_2_DARK_HOVER,
                    3: ALERT_LEVEL_3_DARK_HOVER
                }
            };
            return alertColors[useTheme().palette.mode][props.alertlevel];
        }};
    }

    .linkVehicleName {
        font-weight: 800;
        color: ${(props) => {
            const alertColors = {
                0: PRIMARY,
                1: ALERT_LEVEL_1_DARK,
                2: ALERT_LEVEL_2_DARK,
                3: ALERT_LEVEL_3_DARK
            };
            return useTheme().palette.mode === ThemeMode.light ? alertColors[props.alertlevel] : WHITE;
        }};
    }

    background-color: ${(props) => {
        const alertColors = {
            light: {
                0: ALERT_LEVEL_0,
                1: ALERT_LEVEL_1,
                2: ALERT_LEVEL_2,
                3: ALERT_LEVEL_3
            },
            dark: {
                0: ALERT_LEVEL_0_DARK,
                1: ALERT_LEVEL_1_DARK,
                2: ALERT_LEVEL_2_DARK,
                3: ALERT_LEVEL_3_DARK
            }
        };

        return alertColors[useTheme().palette.mode][props.alertlevel];
    }};

    & .firstTd {
        padding: 0 5px !important;
        display: flex;
        align-items: center;
        min-height: ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.easyMode ? '32px;' : '26px;';
        }}
        border-left: none;

        span {
            z-index: 0;
        }
    }

    & td {
        border-bottom: ${(props) => {
            const alertColors = {
                0: '#bfbfbf',
                1: '#9f9415',
                2: '#CD6E0A',
                3: '#e00000'
            };

            if (useTheme().palette.mode === ThemeMode.dark) {
                return `2px solid ${useTheme().palette.background.default} !important`;
            }

            return `2px solid ${alertColors[props.alertlevel]} !important`;
        }};
    }

    & td {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return common.easyMode ? '' : 'padding: 0px 10px !important;';
        }}
        border-top: none;
        min-width: 90px !important;
    }
    & .MuiTableCell-root {
        background-color: transparent;
        border: none;
        ${(props) => {
            if (props.alertlevel !== 0 && useTheme().palette.mode === ThemeMode.light) {
                return `color:  ${COLOR_GREY};`;
            }
        }}
    }

    & .MuiButtonBase-root,
    .MuiFab-root {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                styleJade: 'box-shadow: none !important;',
                dark: `color: ${WHITE} !important; box-shadow: none !important;`
            });
        }}
    }
`;

export const StatusIcon = styled.span<{ $pressureTemperatureIcon?: boolean; $hide?: boolean; $display?: string }>`
    font-size: 15px;
    margin: 0px 2px;
    display: ${(props) => (props.$hide ? 'none' : props.$display ? `${props.$display};` : 'block')};
    font-weight: bold;
    visibility: ${(props) => (props.$hide ? 'hidden' : 'visible')};

    ${(props) =>
        props.$pressureTemperatureIcon &&
        `       
        align-items: center;
        justify-content: center;
    `}
`;

export const TdSubTable = styled(TableCell)`
    padding: 0 !important;

    & .MuiTableCell-root {
        border-bottom: thin solid rgba(224, 224, 224, 1);
    }
`;

export const CollapseTable = styled(Collapse)`
    box-shadow: rgb(115, 115, 115) 0 3px 20px -9px inset;
`;

export const TitleMainSubTable = styled.span`
    font-weight: 600;
    padding: 10px;
    display: block;
`;

export const HeaderSubTable = styled(TableCell)`
    font-weight: 600;
    font-size: ${AlertFontSize};
`;

export const NoVehicleIcon = styled.span`
    font-size: 19px;
    opacity: 0.3;
`;

export const DividerStyle = styled(Divider)<{ height?: string }>`
    height: ${({ height }) => height ?? '2px'} !important;
`;

export const ContainerSubTable = styled(Box)`
    width: 100%;
    padding: 0px 10px;
    margin: 0;
`;

export const Container = styled.div<{ $textAlign?: string; display?: string }>`
    text-align: ${({ $textAlign }) => $textAlign ?? 'initial'};
    width: 100%;
    ${({ display }) => display && `display: ${display} !important`};
    .hubIcon {
        padding: 0 2.5px;
    }
`;

export const TableCellButtomCollapse = styled(TableCell)<{ $paddingCustom?: string }>`
    ${({ $paddingCustom }) => $paddingCustom && `padding: ${$paddingCustom} !important`};
`;

export const TableLeftSubTable = styled.div`
    width: 60%;
    margin-right: 0;
    border-right: thin solid #c1c1c1;

    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        width: 50%;
    }
`;

export const TableRightSubTable = styled.div`
    width: 40%;
`;

export const BESLabel = styled.div<{ rank: number; name?: 'BES' | 'CSEP' }>`
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: fit-content;
    margin-left: 8px;
    padding: 0 5px;
    background-color: ${(props) => {
        return BESColors[props.rank];
    }};
`;

export const BESPopover = styled.div`
    padding: 12px;
`;

export const BESPopoverRow = styled.div<{ $last?: boolean }>`
    padding-bottom: ${(props) => (props.$last ? '0' : '6px')};
    display: flex;
    align-items: center;
`;

export const UiHighPressureIcon = styled(HighPressureIcon)<{ $paddingTop?: boolean }>`
    ${() => {
        if (useTheme().palette.mode === ThemeMode.dark) {
            return `
                .highPressureStyle {
                    stroke: #FFF !important;
                    fill: #FFF !important;
                }
                

                path {
                    fill: #FFF !important;
                }`;
        }
        return '';
    }}
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const sizeIcon = common.easyMode ? '22px' : '15px';
        return ` width: ${sizeIcon}; height: ${sizeIcon}; ${common.easyMode ? 'padding-left: 5px; ' : ''}
        `;
    }}

    ${({ $paddingTop }) => ($paddingTop ? 'padding-top: 4px' : '')}
`;

export const UiLowPressureIcon = styled(LowPressureIcon)<{ $paddingTop?: boolean }>`
    ${() => {
        if (useTheme().palette.mode === ThemeMode.dark) {
            return `
                .lowPressureStyle {
                    stroke: #FFF !important;
                    fill: #FFF !important;
                }
            
                path {
                    fill: #FFF !important;
                }`;
        }
        return '';
    }}
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const sizeIcon = common.easyMode ? '22px' : '15px';
        return ` width: ${sizeIcon}; height: ${sizeIcon}; ${common.easyMode ? 'padding-left: 5px; ' : ''}
        `;
    }}
    font-size: 12px !important;
    ${({ $paddingTop }) => ($paddingTop ? 'padding-top: 4px' : '')}
`;

export const UITableCell = styled(TableCell)<{ $align?: string }>`
    padding-right: 7px !important;
    ${(props) => {
        return props.$align ? `text-align: ${props.$align} !important` : '';
    }};
`;

export const BESLabelText = styled.div<{ $popup?: boolean }>`
    line-height: 1rem;
    color: white;
    ${(props) =>
        props.$popup
            ? `        
        font-weight: 600;                     
    `
            : `        
        font-weight: 400;           
    `}
`;

export const LoadingPopup = styled.div`
    width: 100px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PositionDiv = styled.div`
    margin-right: 5px;
`;
