import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export const SaveRoutePopupContainer = styled.div``;

export const CustomTextfield = styled(TextField)`
    &.MuiTextField-root {
        margin-bottom: 10px;
        margin-top: 10px;
    }
`;

export const RequestProgress = styled(CircularProgress)({
    color: '#4f714f !important',
    width: '25px !important',
    height: '25px !important',
    margin: '6.5px',
    marginRight: '15px !important'
});
