import styled from 'styled-components';
import { Card, CardContent, CardHeader } from '@mui/material';
import { useTheme } from '@mui/material';
import { SIZE_TEXT_VARIANT } from '../../variables';
import { CommonTypeTheme } from 'components/Ui/Theme';
import UiIconButton from '../UiIconButton/UiIconButton';

export const UiWidgetContainer = styled.div`
    width: 100%;
`;

export const CustomCard = styled(Card)<{
    width: number;
    unit: string;
    height?: number;
    padding?: number | string;
    $scrollable?: boolean;
    $paddingcontent?: string;
    $contentHeight?: number;
    $fullscreen?: boolean;
    $applyPadding?: boolean;
}>`
    &.MuiCard-root {
        width: ${(props) => `${props.width}%`};
        min-width: ${(props) => `${props.width}%`};
        height: ${(props) => (props.height ? `${props.height}${props.unit}` : 'auto')};
        min-height: ${(props) => (props.height ? `${props.height}${props.unit}` : 'auto')};
        border-radius: 4px;
        box-shadow: 6px 6px 20px #0000001a;

        ${(props) => {
            if (props.$fullscreen) {
                return `
                width: calc(${innerWidth}px-20px);
                min-width: calc(${innerWidth}px-20px);
                height: ${innerHeight}px;
                min-height: ${innerHeight}px;
                z-index: 9999; 
                position: fixed;
                bottom: 0;
                top: 0;
                right: 0;
                left: 0;
            `;
            }
        }}

        & .MuiCardContent-root {
            height: ${(props) => (props.$contentHeight ? `${props.$contentHeight}${props.unit}` : 'auto')};
            min-height: ${(props) => (props.$contentHeight ? `${props.$contentHeight}${props.unit}` : 'auto')};
            padding: ${(props) => (props.$applyPadding ? '0px 10px 10px 10px' : props.padding || '0px')};

            ${(props) => {
                if (props.$fullscreen) {
                    return `
                    width: calc(${innerWidth}px-20px);
                    min-width: calc(${innerWidth}px-20px);
                    height: ${innerHeight - 32}px;
                    min-height: ${innerHeight - 32}px;
                `;
                }
            }}
        }
    }
`;

export const LoadingContent = styled.div<{ height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
    justify-content: center;
    display: flex;
}
`;

export const CustomCardHeaderContainer = styled.div<{ $headerColor?: string }>`
    padding: 0px 10px;

    ${({ $headerColor }) => {
        if ($headerColor) {
            return `background: ${$headerColor};`;
        }
        return '';
    }}
`;

export const CustomCardHeader = styled(CardHeader)<{
    $headerActionStartsLeft?: boolean;
    $marginBottom: boolean;
    $headerColor?: string;
}>`
    &.MuiCardHeader-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            const height = common.easyMode ? '37px' : '30px';
            return `
                height: ${height};
            `;
        }}
        padding: 4px 0px 4px 0px;
        border-bottom: 1px solid #c5c5c5;
        ${({ $headerColor, $marginBottom }) => {
            const marginBottom: string = $headerColor ? '2' : '10';
            if ($headerColor) {
                return 'border-bottom: none;';
            }
            return `margin-bottom: ${$marginBottom ? marginBottom : '0'}px;`;
        }}

        & .MuiTypography-root {
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `font-size: ${common.titleSize};`;
            }}
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @media (min-width: 375px) and (max-width: 480px) {
                width: 113px;
            }
        }

        & .MuiCardHeader-avatar {
            font-size: ${SIZE_TEXT_VARIANT(2)} !important;
            & .fa-lg {
                font-size: ${SIZE_TEXT_VARIANT(2)} !important;
            }
        }

        & .MuiCardHeader-content {
            text-transform: capitalize;
        }

        & .MuiCardHeader-action {
            width: ${(props) => (props.$headerActionStartsLeft ? '100%' : 'auto')};
            line-height: 25px;
            display: flex;
            align-self: center;
            > button,
            > button:hover,
            .MuiIconButton-root,
            .legend > button {
                ${() => {
                    const common = useTheme().palette.common as CommonTypeTheme;
                    return `font-size: ${common.titleSize} !important;`;
                }}
                box-shadow: none;
            }
            > button,
            > div > button {
                padding: 8px;
                box-shadow: 0px 0px 4px #2b2b2b;
                display: inline-flex;
                box-shadow: none;
                ${() => {
                    const common = useTheme().palette.common as CommonTypeTheme;
                    return `font-size: ${common.titleSize} !important;`;
                }}
            }
            & .MuiAutocomplete-root {
                display: inline-flex;
                width: 150px;
                align-self: center;

                @media (max-width: 415px) {
                    width: 100px;
                }

                & .MuiAutocomplete-input {
                    padding: 0;
                }
                & .MuiInputBase-root {
                    padding-top: 3px !important;
                    padding-bottom: 3px !important;
                }
                & .MuiInputBase-root:hover,
                .MuiInputBase-root:focus-within {
                    transition: background-color 500ms linear, border-color 500ms linear, color 500ms linear;
                }
                & .MuiFormControl-root {
                    & .MuiInputLabel-shrink {
                        background: none;
                    }
                }
                & svg {
                    font-size: 13px !important;
                }
            }
            & .MuiButton-root {
                text-transform: capitalize !important;
                ${() => {
                    const common = useTheme().palette.common as CommonTypeTheme;
                    return `font-size: ${common.subTitleSize};`;
                }}
                padding: 4px 10px 4px 10px;
            }
        }
    }
`;

export const CardContentCustom = styled(CardContent)<{
    height?: string;
    $applyPadding?: boolean;
    $scrollbar?: boolean;
}>`
    overflow: ${(props) => (props.$scrollbar ? 'auto' : 'hidden')};
    ${(props) => (props.height ? `height: ${props.height}` : '')}
`;

export const CollapseButton = styled(UiIconButton)<{ $collapsed: boolean }>`
    &.MuiIconButton-root {
        & .MuiSvgIcon-root {
            ${(props) => (props.$collapsed ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
            transition: transform 300ms linear;
        }
    }
`;

export const CollapseContent = styled(CardContent)<{ $paddingcontent?: string }>`
    padding: ${(props) => `${props.$paddingcontent ?? '20px 5px'}`};
    height: 100%;
`;
