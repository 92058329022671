import React from 'react';
import * as style from './VehicleTypesTable.style';
import * as type from './VehicleTypesTable.type';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import UiTable from 'components/Ui/UiTable/UiTable';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { ShowVehicleTypesAddModal } from 'states/global/Modal';
import { VehicleTypeAddState } from 'states/component/VehicleType';
import AddVehicleType from 'components/AddVehicleType/AddVehicleType';
import VehicleGroupAPI from 'api/VehicleGroup';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import { VARIANT_BUTTON } from 'components/Ui/variables';

import { Theme } from 'states/global/Theme';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { WHITE } from 'components/Ui/colors';
import UiButton from 'components/Ui/Components/UiButton';

const vehicleGroupApi = new VehicleGroupAPI();

export const VehicleTypesContent: React.FC<type.VehicleTypesViewProps> = (props): JSX.Element => {
    const setVehicleTypesModal = useSetRecoilState(ShowVehicleTypesAddModal);
    const setVehicleAddType = useSetRecoilState(VehicleTypeAddState);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <style.VehicleTypesContent data-testid='VehicleTypesContent'>
            <Widget
                testid='vehicle-group-widget'
                title={translate('t.vehicle_group')}
                avatar={
                    <UiIcon
                        icon={['fas', 'car-bus']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#c0c0c0',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <UiTable
                        fetchFn={vehicleGroupApi.getAll}
                        exportFn={vehicleGroupApi.getExport}
                        exportName={'Vehicles group'}
                        columns={props.columns}
                        hiddenColumns={[]}
                        queryKey='VehicleTypes'
                        rightActionBtns={() => (
                            <UiButton
                                testid='vehicle-group-add-button'
                                skin='success'
                                variant={VARIANT_BUTTON}
                                onClick={() => {
                                    setVehicleTypesModal(true);
                                    setVehicleAddType({
                                        action: 'add'
                                    });
                                }}
                            >
                                {translate('t.vehicle_group_add')}
                            </UiButton>
                        )}
                    />
                }
            />
            <AddVehicleType />
        </style.VehicleTypesContent>
    );
};
