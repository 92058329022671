import { ListItemIcon } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import * as style from './UiMenu.style';
import * as type from './UiMenu.type';
import UiLink from '../UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { COLOR_GREY, WHITE } from 'components/Ui/colors';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
// import module

export const UiMenuContent: React.FC<type.UiMenuViewProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);

    const getItem = (index: number, item: type.Item, isLink = false, url: string): JSX.Element => {
        if (isLink) {
            return (
                <UiLink
                    key={`UiLink-${index}`}
                    $padding='0'
                    content={
                        <MenuItem
                            key={`MenuItem-${index}`}
                            onClick={() => {
                                props.closeMenu();
                                setTimeout(() => {
                                    item.action && item.action(item.value, item.title);
                                    item.actionGeneral && item.actionGeneral();
                                }, 400);
                            }}
                            style={item?.style}
                            selected={item?.selected}
                            data-testid={`${props.testid || 'UiMenu-testid'}-Item-${index}`}
                        >
                            {item.icon && (
                                <img
                                    alt={`vehicle ${item.title}`}
                                    src={item.icon}
                                    height={item.iconHeight || 25}
                                    style={{ marginRight: '5px' }}
                                />
                            )}
                            {item.iconUI && <ListItemIcon key={`ListItemIcon-${index}`}>{item.iconUI}</ListItemIcon>}
                            {item.title}
                        </MenuItem>
                    }
                    url={url}
                    styleCustom={{ width: '100%', display: 'flex' }}
                    color={applyStyleByMode({
                        theme: ThemeMode?.mode,
                        light: COLOR_GREY,
                        dark: WHITE
                    })}
                    testId='Areas-Edit-Button'
                />
            );
        }

        return (
            <MenuItem
                key={`MenuItem-${index}`}
                onClick={() => {
                    props.closeMenu();
                    setTimeout(() => {
                        item.action && item.action(item.value, item.title);
                        item.actionGeneral && item.actionGeneral();
                    }, 400);
                }}
                style={item?.style}
                selected={item?.selected}
                data-testid={`${props.testid || 'UiMenu-testid'}-Item-${index}`}
            >
                {item.icon && (
                    <img
                        alt={`vehicle ${item.title}`}
                        src={item.icon}
                        height={item.iconHeight || 25}
                        style={{ marginRight: '5px' }}
                    />
                )}
                {item.iconUI && <ListItemIcon key={`ListItemIcon-${index}`}>{item.iconUI}</ListItemIcon>}
                {item.title}
            </MenuItem>
        );
    };

    return (
        <>
            <style.UiMenuCover
                onClick={(e) => {
                    e.stopPropagation();
                    props.openMenu(e);
                }}
            >
                {props.rootEl}
            </style.UiMenuCover>
            <style.CustomMenu
                anchorEl={props.anchorEl}
                anchorOrigin={props.anchorOrigin}
                transformOrigin={props.transformOrigin}
                keepMounted
                onClick={(e) => e.stopPropagation()}
                open={Boolean(props.anchorEl)}
                onClose={props.closeMenu}
                title={props.title}
                PaperProps={{
                    'data-testid': props.testid || 'UiMenu-testid'
                }}
            >
                {props.items?.map((item, index) => !item.none && getItem(index, item, item?.isLink, item?.url || ''))}
            </style.CustomMenu>
        </>
    );
};
