import styled from 'styled-components';
import { FormControl } from '@mui/material';

export const RemovedSensorsTableViewContent = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }
    width: 100%;
    && .MuiCardContent-root {
        background-color: white;
        font-size: 13px !important;
        border: none !important;
        box-shadow: none;
    }
`;

export const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin: 4px 0 8px 0 !important;
    }
`;

export const Strikethrough = styled.div`
    text-decoration: line-through !important;
`;
