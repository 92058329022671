import React from 'react';
import { useRecoilValue } from 'recoil';
import { ExportedReportsDetail, ExportedReportsList, ExportedReportsContainer } from './ExportedReports.style';
import { ExportedReportsViewProps } from './ExportedReports.type';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import UiWidget from 'components/Ui/Components/UiWidget';
import Report from 'api/Report';
import UiTable from 'components/Ui/UiTable/UiTable';
import ExportedReportDetail from './ExportedReportDetail';
import { applyStyleByMode } from 'helpers';
import { faList } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

const reportApi = new Report();

export const ExportedReportsContent: React.FC<ExportedReportsViewProps> = ({
    columns,
    report,
    reportStatus
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <ExportedReportsContainer data-testid='ExportedReportsContainer'>
            <Grid container spacing={2}>
                <ExportedReportsList item xl={7} lg={7} xs={12} md={7} sm={6}>
                    <UiWidget
                        testid='exportedReportsContent'
                        loading={false}
                        avatar={
                            <UiIcon
                                icon={faList}
                                size='lg'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        }
                        title={translate('t.exported_reports')}
                        content={
                            <UiTable
                                columns={columns}
                                fetchFn={reportApi.getAll}
                                hiddenColumns={[]}
                                defaultSortBy={{
                                    id: 'requested_at',
                                    desc: true
                                }}
                                refresh={false}
                                queryKey={'exportedDataTable'}
                            />
                        }
                    ></UiWidget>
                </ExportedReportsList>

                <ExportedReportsDetail item xl={5} lg={5} xs={12} md={6} sm={6}>
                    <UiWidget
                        testid='exportedReportsContentDetail'
                        loading={false}
                        avatar={
                            <UiIcon
                                icon={faList}
                                size='lg'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        }
                        title={translate('t.exported_report_detail')}
                        content={<ExportedReportDetail reportStatus={reportStatus} report={report} />}
                    ></UiWidget>
                </ExportedReportsDetail>
            </Grid>
        </ExportedReportsContainer>
    );
};
