import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useTheme } from '@mui/material';
import { BACKGROUND_PAPER_DARK_MODE, GRAY_LIGHT } from '../../Ui/colors';
import { ThemeMode } from '../../../states/global/Theme';
import Paper from '@mui/material/Paper';
import { applyStyleByMode } from 'helpers';

export const ReportDetailContainer = styled.div``;

export const LoadingProgressConatiner = styled.div`
    margin: 0 auto;
    text-align: center;
`;

export const LoadingProgress = styled(CircularProgress)``;

export const CustomTextfield = styled(TextField)``;

export const CustomLabel = styled(InputLabel)``;

export const CardDetail = styled(Paper)`
    padding: 10px;
`;

export const DateRangeContainer = styled.div`
    ${() => {
        return `background-color: ${
            useTheme().palette.mode === ThemeMode.light ? GRAY_LIGHT : BACKGROUND_PAPER_DARK_MODE
        };`;
    }}
    margin: 0 auto;
    padding: 10px;
    border-radius: 4px;
`;
