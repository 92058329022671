import React from 'react';
import { NotificationSettingsProps } from './NotificationSettings.type';
import { NotificationSettingsContent } from './NotificationSettings.view';
import UsersSuscribedNotifications from './UsersSuscribedNotifications/UsersSuscribedNotifications';
import { useTranslation } from 'react-i18next';
import { ChipLevel } from '../AlertNotification/AlertCustom/AlertCustom.style';
import { NotificationModel as NotificationsSetting } from 'models/Notification.type';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';

const NotificationSettings: React.FC<NotificationSettingsProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();

    const columns: Column<NotificationsSetting>[] = [
        {
            Header: translate('t.notification_type'),
            accessor: 'type',
            Cell: (props: { row: { original: NotificationsSetting } }) => {
                const msgType: string = props.row.original.type === 'leak' ? 'pressure_leak' : props.row.original.type;
                return <>{translate(`t.${props.row.original.type2}_${msgType}`)}</>;
            }
        },
        {
            Header: translate('p.users_being_notified'),
            accessor: 'assignation',
            disableFilters: true,
            disableSortBy: true,
            width: 200,
            Cell: (props: { row: { original: NotificationsSetting } }) => (
                <>
                    <UsersSuscribedNotifications data={props.row.original} rowId={props.row.original.id} />
                </>
            )
        },
        {
            Header: translate('t.level'),
            accessor: 'level',
            Cell: (props: { row: { original: NotificationsSetting } }) =>
                props.row.original.level ? (
                    <ChipLevel level={props.row.original.level} label={`${props.row.original.level}`} size={'small'} />
                ) : (
                    <>-</>
                )
        }
    ];

    return <NotificationSettingsContent data-testid='NotificationSettings-testid' columns={columns} />;
};

export default Wrapper(NotificationSettings);
