import React from 'react';
import { TempAndPressListRowProps } from './TemperaturePressureTableListRow.type';
import { Wrapper } from 'helpers/wrapper';
import { CustomTableRow } from './TemperaturePressureTableListRow.style';
import { TemperaturePressureTableVehicleInfo } from '../TemperaturePressureTableVehicleInfo/TemperaturePressureTableVehicleInfo';
import TemperaturePressureTablePressureTemperatureReadings from '../TemperaturePressureTablePressureTemperatureReadings/TemperaturePressureTablePressureTemperatureReadings';
import TemperaturePressureTableTransmissionDistance from '../TemperaturePressureTableTransmissionDistance/TemperaturePressureTableTransmissionDistance';

const TemperaturePressureTableListRow: React.FC<TempAndPressListRowProps> = (props): JSX.Element => {
    return (
        <CustomTableRow key={props.vehicle.id}>
            <TemperaturePressureTableVehicleInfo vehicle={props.vehicle} rowNumber={props.rowNumber} />
            <TemperaturePressureTablePressureTemperatureReadings {...props} />
            <TemperaturePressureTableTransmissionDistance vehicle={props.vehicle} />
        </CustomTableRow>
    );
};

export default Wrapper(TemperaturePressureTableListRow);
