import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    BasicRow,
    CustomerSettingsContainer,
    DeprecatedKey,
    EditRowMode,
    ItemsAmbTepm,
    KeyValue
} from './CustomerSettings.style';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomerSettingsViewProps } from './CustomerSettings.type';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { ERROR, SUCCESS, WHITE } from 'components/Ui/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faCheck, faGear, faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
    CustomerSettingEditModeAtom,
    SelectedCustomerSetting,
    SelectedCustomerSettingValue
} from 'states/component/CustomerSettings';
import {
    CustomerSettingAll,
    CustomerSettingType,
    CustomerSettingsModel,
    OptionLabels
} from 'models/CustomerSettings.type';
import { UserUnits } from 'states/global/User';
import { CRUD } from 'variables';
import CustomerSettingInputs from '../CustomeSettingInputs';
import AddCustomerSettings from '../AddCustomerSettings/AddCustomerSettings';
import UiWidget from 'components/Ui/Components/UiWidget';
import { applyStyleByMode } from 'helpers';
import { DateTime } from 'luxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { snakeToCamel } from 'helpers/converter/text';

const EditRow: React.FC<{
    value: unknown;
    translationKey: string;
    customerSetting: CustomerSettingsModel['settings'][0];
    type: CustomerSettingType | undefined;
    customerSettingsPopup: (
        actionType: CRUD.EDIT,
        value: string | number | boolean | null | undefined | [],
        customerSettingType: CustomerSettingsModel['settings'][0] | undefined
    ) => void;
}> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setEditMode = useSetRecoilState(CustomerSettingEditModeAtom);
    const { dateTimeFormat } = useConverter();
    const [value, setValue] = useState<unknown>(props.value);
    const [selectedCustomerSetting, setSelectedCustomerSetting] = useRecoilState(SelectedCustomerSetting);
    const [selectedSettingValue, setSelectedSettingValue] = useRecoilState(SelectedCustomerSettingValue);

    const editActionBtns = useCallback((): JSX.Element => {
        return (
            <>
                <UiButton
                    size='small'
                    skin={SUCCESS}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        props.customerSettingsPopup(
                            CRUD.EDIT,
                            selectedSettingValue,
                            selectedCustomerSetting?.customerSetting
                        );
                    }}
                    testid={`${props.customerSetting.key}SaveButton`}
                    style={{
                        margin: '0 2px'
                    }}
                    disabled={
                        selectedCustomerSetting?.customerSetting?.type === CustomerSettingType.DATETIME
                            ? (typeof selectedSettingValue === 'string'
                                  ? !DateTime.fromFormat(
                                        selectedSettingValue,
                                        dateTimeFormat('dateTime', !!props.customerSetting.format?.includes('s'))
                                    ).isValid
                                  : !(selectedSettingValue as DateTime)?.isValid) || selectedSettingValue === undefined
                            : selectedCustomerSetting?.customerSetting?.key === 'ac_set_hotspot'
                            ? (selectedSettingValue as [])?.length === 0
                            : false
                    }
                >
                    {translate('t.save')}
                </UiButton>
                <UiButton
                    size='small'
                    color='error'
                    variant='contained'
                    testid={`${props.customerSetting.key}CancelButton`}
                    skin={ERROR}
                    onClick={() => {
                        setEditMode([]);
                        setSelectedCustomerSetting(undefined);
                        setSelectedSettingValue(undefined);
                    }}
                    style={{
                        margin: '0 2px'
                    }}
                >
                    {translate('t.cancel')}
                </UiButton>
            </>
        );
    }, [props.translationKey, value, selectedSettingValue, selectedCustomerSetting]);

    useEffect(() => {
        setSelectedSettingValue(value as string | number | boolean | null | undefined | []);
    }, [value]);

    return (
        <EditRowMode>
            <TableCell
                width={150}
                style={{
                    maxWidth: 150,
                    overflow: 'hidden'
                }}
                data-testid={`Row${snakeToCamel(props.translationKey)}Label`}
            >
                {translate(`t.${props.translationKey}`)}
            </TableCell>
            <TableCell width={170} data-testid={`Row${snakeToCamel(props.translationKey)}Value`}>
                <CustomerSettingInputs
                    actionType={CRUD.EDIT}
                    value={value}
                    setValue={setValue}
                    type={props.type}
                    settingOptions={props.customerSetting}
                />
            </TableCell>
            <TableCell width={210} data-testid={`Row${snakeToCamel(props.translationKey)}Actions`}>
                {editActionBtns()}
            </TableCell>
        </EditRowMode>
    );
};

export const CustomerSettingsContent: React.FC<CustomerSettingsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [editMode, setEditMode] = useRecoilState(CustomerSettingEditModeAtom);
    const [selectedCustomerSetting, setSelectedCustomerSetting] = useRecoilState(SelectedCustomerSetting);
    const [selectedSettingValue, setSelectedSettingValue] = useRecoilState(SelectedCustomerSettingValue);
    const NO_EDITABLE = ['mine_operation_start'];
    const userUnits = useRecoilValue(UserUnits);
    const currentMonth = 7;
    const { dateTimeFormat } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    useEffect(() => {
        if (selectedCustomerSetting?.actionType === CRUD.REMOVE) {
            let value = selectedCustomerSetting?.customerSetting?.value;
            setSelectedSettingValue(value);
            setEditMode([]);
        }
    }, [selectedCustomerSetting?.customerSetting]);

    useEffect(() => {
        if (selectedCustomerSetting?.actionType === CRUD.REMOVE) {
            props.customerSettingsPopup(CRUD.REMOVE, selectedSettingValue, selectedCustomerSetting?.customerSetting);
        }
    }, [selectedSettingValue]);

    const actionBtns = (settingId: string, row: CustomerSettingAll): JSX.Element => {
        return (
            <>
                <UiButton
                    size='small'
                    color='primary'
                    variant='contained'
                    testid={`${row.key}EditButton`}
                    skin={SUCCESS}
                    onClick={() => {
                        setEditMode([settingId]);
                        setSelectedCustomerSetting({ customerSetting: row, actionType: CRUD.EDIT });
                    }}
                >
                    {translate('t.edit')}
                </UiButton>
                {!row.required && (
                    <UiButton
                        size='small'
                        color='error'
                        variant='contained'
                        testid={`${row.key}RemoveButton`}
                        skin={ERROR}
                        onClick={() => {
                            setSelectedCustomerSetting({ customerSetting: row, actionType: CRUD.REMOVE });
                        }}
                        style={{
                            margin: '0 2px'
                        }}
                    >
                        {translate('t.remove')}
                    </UiButton>
                )}
            </>
        );
    };

    const NoEditValue = (type: CustomerSettingType, row: CustomerSettingAll): JSX.Element => {
        switch (type) {
            case CustomerSettingType.BOOLEAN:
                return <UiIcon icon={row?.value ? faCheck : faTimes} style={{ padding: 0 }} />;
            case CustomerSettingType.LIST:
                return row.options ? row.options[row.value] : row.value;
            case CustomerSettingType.MULTIPLE_LIST:
                return OptionLabels[row?.value] || row?.value || translate('t.none');
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return row?.value
                    ?.filter((month, index) => index === currentMonth - 1)
                    ?.map((val, index) => (
                        <TextField
                            data-testid='AmbientTemperature-TextField-testid'
                            label={`${translate(`t.month${currentMonth}`)}`}
                            type='number'
                            size='small'
                            key={index}
                            InputLabelProps={{ shrink: true }}
                            value={val || null}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment
                                        position='end'
                                        data-testid='AmbientTemperature-InputAdornment-testid'
                                    >
                                        °{userUnits.temperature.toUpperCase()}
                                    </InputAdornment>
                                )
                            }}
                            style={{
                                marginRight: '10px',
                                marginTop: '10px'
                            }}
                        />
                    ));
            case CustomerSettingType.MAP_AREA:
                return row?.value?.map((val, index) => (
                    <TextField
                        data-testid='MapArea-TextField-testid'
                        label={`${translate('t.coordinates')} ${index + 1}`}
                        type='number'
                        size='small'
                        key={index}
                        InputLabelProps={{ shrink: true }}
                        value={val || null}
                        InputProps={{
                            readOnly: true
                        }}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                ));
            case CustomerSettingType.DATETIME: {
                return <>{row?.value?.toFormat(dateTimeFormat('dateTime', !!row.format?.includes('s')))}</>;
            }
            case CustomerSettingType.TEXT: {
                return (
                    <TextField
                        data-testid={`${row.key}-TextField-testid`}
                        label={translate(`t.${row.key}`)}
                        size='small'
                        multiline
                        minRows={8}
                        maxRows={10}
                        InputLabelProps={{ shrink: true }}
                        value={OptionLabels[row?.value] || row?.value}
                        InputProps={{
                            readOnly: true
                        }}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                );
            }
            default:
                return OptionLabels[row?.value] || row?.value;
        }
    };

    const customerSettingsTableBody = useCallback(() => {
        return props.typedCustomerSettings?.settings
            .filter((setting) => setting.customValue)
            .map((row, index) => {
                const hasEditMode = editMode.includes(row.key);
                return (
                    <div key={row.key}>
                        <ItemsAmbTepm
                            $hasEditMode={hasEditMode}
                            height={row.key === 'ambient_temperature' ? '700px' : '48px'}
                        >
                            {hasEditMode ? (
                                <EditRow
                                    customerSetting={row}
                                    translationKey={row.key}
                                    type={row.type}
                                    value={row.value}
                                    customerSettingsPopup={props.customerSettingsPopup}
                                />
                            ) : (
                                <BasicRow key={index}>
                                    <TableCell
                                        width={150}
                                        style={{
                                            maxWidth: 150,
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <KeyValue>
                                            {translate(`t.${row.key}`)}
                                            {row.deprecated && (
                                                <DeprecatedKey>*{translate('t.deprecated')}</DeprecatedKey>
                                            )}
                                        </KeyValue>
                                    </TableCell>
                                    <TableCell
                                        width={170}
                                        style={{
                                            cursor: !NO_EDITABLE.includes(row.key) ? 'pointer' : 'auto',
                                            width: 170,
                                            overflowWrap: 'anywhere'
                                        }}
                                        onClick={() => {
                                            !NO_EDITABLE.includes(row.key) && setEditMode([row.key]);
                                            setSelectedCustomerSetting({ customerSetting: row, actionType: CRUD.EDIT });
                                        }}
                                    >
                                        {NoEditValue(row.type, row)}
                                    </TableCell>
                                    <TableCell width={210}>
                                        {!NO_EDITABLE.includes(row.key) ? actionBtns(row.key, row) : <></>}
                                    </TableCell>
                                </BasicRow>
                            )}
                        </ItemsAmbTepm>
                    </div>
                );
            });
    }, [props.typedCustomerSettings, editMode]);

    return (
        <CustomerSettingsContainer data-testid='CustomerSettingsContent'>
            <UiWidget
                title={translate('t.customer_settings')}
                loading={props.isLoading}
                avatar={
                    <UiIcon
                        icon={faGear}
                        size='lg'
                        fixedWidth
                        data-testid='customerSettingsAvatar'
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                padding='10px'
            >
                <Grid data-testid='CustomerSettingsContainer' container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container xs={12} md={12} lg={12}>
                            <AddCustomerSettings
                                customerSettings={props.typedCustomerSettings}
                                customerSettingsPopup={props.customerSettingsPopup}
                                patchIsLoading={props.patchIsLoading}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} sx={{ paddingRight: '5px' }}>
                        <TableContainer component={Paper}>
                            <Table size='small' aria-label='a dense table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{translate('t.key')}</TableCell>
                                        <TableCell>{translate('t.value')}</TableCell>
                                        <TableCell width={200}>{translate('t.action')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{!props.isLoading ? customerSettingsTableBody() : ''}</TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </UiWidget>
        </CustomerSettingsContainer>
    );
};
