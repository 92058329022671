import styled from 'styled-components';
import { Alert, TextField } from '@mui/material';
import { WHITE } from '../../Ui/colors';

export const ContainerFormAccountSettings = styled.div`
    min-height: 137px;
    max-width: 100%;
    margin: 0 auto;
    padding-top: 35px;

    @media only screen and (min-width: 900px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        max-width: 100%;
    }
    @media only screen and (min-width: 1536px) {
        max-width: 90%;
    }
`;

export const AvatarImg = styled.img`
    width: 120px;
    height: 120px;
    border: thin solid #c0c0c0;
    margin: auto;
    border-radius: 50%;
    background: ${WHITE};

    .MuiIconButton-root {
        padding: 2px;
    }
`;

export const AccountSettingsFormContent = styled.div``;

export const ContainerImage = styled.div`
    width: 120px;
    height: 120px;
    border: thin solid #c0c0c0;
    margin: auto;
    border-radius: 50%;
    margin-bottom: 15px;

    .MuiIconButton-root {
        padding: 2px;
    }
`;

export const ContainerBtnImage = styled.div<{ previewImg: string }>`
    position: absolute;
    margin-top: 85px;
    margin-left: 86px;
    border-radius: 50%;
    padding: 3px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px;
    background: ${({ previewImg }) => previewImg};

    & input {
        display: none !important;
    }

    & svg {
        color: #fff;
    }
`;

export const CustomTextField = styled(TextField)``;

export const ContainerButton = styled.div<{ direction: string }>`
    float: ${({ direction }) => direction};
    padding: 7px;
`;

export const EmailWarning = styled(Alert)`
    &.MuiAlert-root {
        margin-top: 15px;
        margin-left: 10px;
        text-align: justify;
    }
`;
