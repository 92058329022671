import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { BACKGROUND_PAPER_DARK_MODE, BACKGROUND_TABLE } from '../../Ui/colors';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleDetailsTyresViewContent = styled.div`
    min-width: 351px;
`;

export const CheckboxDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 35px;
    && .MuiTypography-root {
        height: unset;
    }
`;

export const HeaderText = styled.div`
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `
                background: ${BACKGROUND_TABLE};
            `,
            dark: `
                background: ${BACKGROUND_PAPER_DARK_MODE};
            `,
            styleJade: `
                font-size: ${common.textSize};
                border-radius: 4px;
                padding: 2% 5%;
            `
        });
    }}
`;

export const Text = styled.div<{ width: number }>`
    width: ${(props) => props.width}%;
`;
