import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';

export const RequestProgress = styled(CircularProgress)`
    & {
        width: 26px !important;
        height: 26px !important;
        margin-right: 15px !important;
    }
`;

export const EmailNotificationCover = styled(Alert)``;

export const EmailNotification = styled.span``;
