import styled from 'styled-components';
import { PRIMARY, WHITE } from '../../Ui/colors';
import { Button, Checkbox, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';

export const AddHubNotifyContainer = styled.div`
    margin: 10px !important;
`;

export const CustomTextfield = styled(TextField)`
    width: 100%;
`;

export const CustomCheckBox = styled(Checkbox)``;

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0px;
    color: ${PRIMARY} !important;
`;

export const RefButton = styled(Button)`
    display: none;
`;

export const TextDiv = styled.div`
    margin: 5px 0;
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
    }
`;

export const CustomTextField = styled(TextField)`
    &.MuiTextField-root {
        margin: 8px;
        margin-left: 0px;
        && label {
            padding: 0 5px 0 0;
            ${() => {
                return applyStyleByMode({
                    styleJade:
                        useTheme().palette.mode === ThemeMode.dark
                            ? 'background: transparente;'
                            : `background: ${WHITE};`
                });
            }}
        }
        & .MuiInputLabel-shrink {
            transform: translate(13px, -12px) scale(0.9);
        }
        & .Mui-disabled:not(.MuiInputLabel-shrink) {
            color: #3a3633;
        }
        & svg {
            fill: #999999;
        }
    }
`;
