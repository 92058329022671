import React from 'react';
import Widget from '../../Ui/Components/UiWidget';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { TableProps, TyresSummaryViewProps } from './TyresSummary.type';
import UiLegend from '../../Ui/Components/UiLegend';
import {
    CustomHead,
    CustomTable,
    CustomTableCell,
    CustomTableContainer,
    LegendBody,
    TyresSummary
} from './TyresSummary.style';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useConverter from '../../CustomHooks/Converter/Converter';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../../states/global/Theme';
import { BACKGROUND_DARK_MODE, WHITE } from '../../Ui/colors';
import UiIcon from '../../Ui/Components/UiIcon/UiIcon';
import { UserInfo } from 'states/global/User';

const TyreSummaryTable: React.FC<TableProps> = ({ data }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCtoUserTimezone } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const { fromServerToUserUnit, displayUserUnits, convertType } = useConverter();

    return (
        <CustomTableContainer>
            <CustomTable>
                <CustomHead>
                    <TableRow>
                        <CustomTableCell $header $border colSpan={2}>
                            {translate('t.tyre')}
                        </CustomTableCell>
                        <CustomTableCell $header $border>
                            {translate('t.sensor')}
                        </CustomTableCell>
                        <CustomTableCell $header $border colSpan={3}>
                            {`${translate(
                                `t.${
                                    userInfo.user?.userSetting.showColdPressureInTyreSummary
                                        ? 'cold_pressure'
                                        : 'hot_pressure'
                                }`
                            )} (${displayUserUnits.pressure})`}
                        </CustomTableCell>
                        <CustomTableCell $header $border colSpan={3}>
                            {displayUserUnits.temperature}
                        </CustomTableCell>
                        <CustomTableCell $header colSpan={4}>
                            {translate('t.latest')}
                        </CustomTableCell>
                    </TableRow>
                    <TableRow>
                        <CustomTableCell>{translate('t.position')}</CustomTableCell>
                        <CustomTableCell $border>{translate('t.serial_number')}</CustomTableCell>
                        <CustomTableCell $border>{translate('t.serial_number')}</CustomTableCell>
                        <CustomTableCell>{translate('t.min')}</CustomTableCell>
                        <CustomTableCell>{translate('t.avg')}</CustomTableCell>
                        <CustomTableCell $border>{translate('t.max')}</CustomTableCell>
                        <CustomTableCell>{translate('t.min')}</CustomTableCell>
                        <CustomTableCell>{translate('t.avg')}</CustomTableCell>
                        <CustomTableCell $border>{translate('t.max')}</CustomTableCell>
                        <CustomTableCell>{translate('t.date')}</CustomTableCell>
                        <CustomTableCell>{translate('t.pressure')}</CustomTableCell>
                        <CustomTableCell>{translate('t.temperature')}</CustomTableCell>
                        <CustomTableCell>{translate('t.transmissions_total_number')}</CustomTableCell>
                    </TableRow>
                </CustomHead>
                {data && !!data.length ? (
                    <TableBody>
                        {data.map((record) => (
                            <TableRow key={record.position}>
                                <CustomTableCell>{record.position}</CustomTableCell>
                                <CustomTableCell $border>{record.tyreSerialNumber}</CustomTableCell>
                                <CustomTableCell $border>{record.serialNumberHex}</CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.pressure,
                                        value: record.minPressure,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.pressure,
                                        value: record.avgPressure,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.pressure,
                                        value: record.maxPressure,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.temperature,
                                        value: record.minTemperature,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.temperature,
                                        value: record.avgTemperature,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.temperature,
                                        value: record.maxTemperature,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromUTCtoUserTimezone({
                                        date: record.measuredAt,
                                        format: 'dateTime',
                                        displaySeconds: false
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.pressure,
                                        value: record.latestPressure,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>
                                    {fromServerToUserUnit({
                                        type: convertType.temperature,
                                        value: record.latestTemperature,
                                        fixed: 1
                                    })}
                                </CustomTableCell>
                                <CustomTableCell>{record.transmissionsCount}</CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                    <CustomTableCell colSpan={13}>
                        <h4>{translate('t.there_no_data')}</h4>
                    </CustomTableCell>
                )}
            </CustomTable>
        </CustomTableContainer>
    );
};

export const TyresSummaryContent: React.FC<TyresSummaryViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <TyresSummary data-testid='TyresSummaryContent'>
            <Widget
                id='tyres_summary'
                title={translate('t.tyres_summary')}
                avatar={
                    <UiIcon
                        icon={['fas', 'tire-rugged']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                collapseButton={true}
                headerColor={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: '#e9e9e9;',
                    dark: BACKGROUND_DARK_MODE
                })}
                loading={props.isLoading}
                headerAction={
                    <>
                        <UiLegend
                            content={<LegendBody>{translate('p.tyres_summary_calculation')}</LegendBody>}
                            width={250}
                            title={translate('t.info')}
                            icon={<i className='fa fa-info' />}
                        />
                    </>
                }
            >
                <TyreSummaryTable data={props.data?.deviations} />
            </Widget>
        </TyresSummary>
    );
};
