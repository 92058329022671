import styled from 'styled-components';
import { Autocomplete, CircularProgress, IconButton, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UiButton from '../../Ui/Components/UiButton';

export const ContainerForm = styled.div`
    padding-top: 8px;
`;

export const BugReporterButton = styled(IconButton)``;

export const TitleTextField = styled(TextField)`
    margin-bottom: 10px;
`;

export const DescriptionTextField = styled(TextField)``;

export const CustomAutocomplete = styled(Autocomplete)`
    margin-bottom: 10px;
    &&& .MuiAutocomplete-popupIndicator {
        padding: 5px;
        margin-right: 2px;
    }
`;

export const LegendHeaderIcon = styled(FontAwesomeIcon)``;

export const Button = styled(UiButton)``;

export const LoadingButton = styled(CircularProgress)`
    &.MuiCircularProgress-root {
        color: #474544;
        height: 24px !important;
        width: 24px !important;
    }
`;

export const RequestSupport = styled.div``;
