import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';

export const LogoGlobalAdminContainer = styled.div``;

export const ImageCell = styled.img<{ width?: string; $maxWidth?: string; $maxHeight?: string }>`
    ${({ width, $maxHeight, $maxWidth }) => {
        return applyStyleByMode({
            styleJade: `
                width: ${width ? width : 'auto'};
                max-width: ${$maxWidth ? $maxWidth : 'auto'};
                max-height: ${$maxHeight ? $maxHeight : 'auto'};
            `
        });
    }}
`;
