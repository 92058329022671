import styled from 'styled-components';
import { WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import Button from 'components/Ui/Components/UiButton/UiButton';

export const LifespanReportDetailsViewContent = styled.div`
    width: 98%;
    margin-top: 10px;
`;

export const LifespanReportDetailsToPrint = styled.div`
    @media print {
        @page {
            size: A3 portrait;
        }
    }
`;

export const GraphContainer = styled.div`
    padding-bottom: 15px;
    & .MuiCard-root {
        padding-top: 15px;
        border-radius: 0px;
        ${applyStyleByMode({
            light: `background-color: ${WHITE}`
        })}
        div {
            border-bottom: 0px;
        }
        box-shadow: none !important;
    }
`;

export const ExportContainer = styled.div`
    padding: 15px 5px;
    ${applyStyleByMode({
        light: `background-color: ${WHITE}`
    })}
`;

export const ExportDataButton = styled(Button)`
    text-transform: capitalize;
`;
