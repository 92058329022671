import React from 'react';
import { VehicleGroupProps } from './VehicleGroup.type';
import { useQuery } from '@tanstack/react-query';
import VehicleGroupApi from 'api/VehicleGroup';
import { VehicleGroupsCodeBook, VehicleGroupQueryKeys } from 'models/VehicleGroup.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';

const vehicleGroupApi = new VehicleGroupApi();

const GetVehicleGroupCodebook: React.FC<VehicleGroupProps> = ({ children }): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const useQueryProperties = useQuery<VehicleGroupsCodeBook, Error>(
        [VehicleGroupQueryKeys.getCodebook, userInfo.user?.customer.id],
        () => vehicleGroupApi.getCodebook({}),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    return <>{children(useQueryProperties)}</>;
};

export default GetVehicleGroupCodebook;
