import React from 'react';
import {
    ContainerInputPhone,
    FormHelperTextError,
    StyledPhoneInput,
    UiPhoneInputNumberContent as UiPhoneInputNumberContentStyle,
    PhoneNumberInfo
} from './UiPhoneInputNumber.style';
import { UiPhoneInputNumberViewProps } from './UiPhoneInputNumber.type';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';

export const UiPhoneInputNumberContent: React.FC<UiPhoneInputNumberViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    if (props?.value?.length) {
        if (!props.extraValidation(props.value) || !isValidPhoneNumber(props.value ?? '')) {
            props.setIsValid(translate('t.is_not_in_correct_format'));
        }
    }

    return (
        <UiPhoneInputNumberContentStyle data-testid='UiPhoneInputNumberContent' isValid={props.isValid}>
            <ContainerInputPhone isValid={props.isValid}>
                <legend>{props.placeholder}</legend>
                <StyledPhoneInput
                    data-testid={props.testId || 'UiPhoneInputNumber'}
                    international={props.international}
                    className={props.className}
                    defaultCountry={props.defaultCountry}
                    placeholder={props.placeholder}
                    value={props.value}
                    name={props.name}
                    rules={{ required: true }}
                    onChange={(value?: string | undefined) => {
                        if (value) {
                            if (!props.extraValidation(value) || !isValidPhoneNumber(value)) {
                                props.setIsValid(translate('t.is_not_in_correct_format'));
                            } else {
                                props.setIsValid(null);
                            }
                            props.setFieldValue(
                                props.name,
                                isValidPhoneNumber(value)
                                    ? `${formatPhoneNumberIntl(value).replace(/(\s|-)/g, '')}`
                                    : formatPhoneNumberIntl(value)
                            );
                        } else {
                            props.setFieldValue(props.name, '');
                            props.setIsValid(null);
                        }
                    }}
                />
            </ContainerInputPhone>
            {props.isValid && <FormHelperTextError> {props.isValid} </FormHelperTextError>}

            <PhoneNumberInfo severity='info' data-testid='sms-notifications-service-info'>
                {translate('p.agree_to_sms_notifications')}
            </PhoneNumberInfo>
        </UiPhoneInputNumberContentStyle>
    );
};
