import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete';
import { ERROR } from '../Ui/colors';
import AlertInfo from '@mui/material/Alert';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import { ThemeMode } from 'states/global/Theme';
import { Grid, useTheme } from '@mui/material';

export const TrackingMapTopBarContent = styled.div`
    @media (max-width: 426px) {
        .MuiButton-root {
            margin-top: 5px;
        }
    }
`;

export const VehicleGroupGrid = styled(Grid)`
    & .MuiFormControl-root {
        margin-right: 5px;
    }

    & .MuiInputBase-root {
        width: 213px;
    }
`;

export const ApplyButtonGrid = styled(Grid)`
    & .MuiButton-root {
        @media (max-width: 550px) {
            margin-top: 10px;
        }
    }
`;

export const CustomStaticDatePicker = styled(StaticDateTimePicker)`
    height: 400px;
`;

export const GroupContent = styled.div<{ right?: boolean; left?: boolean }>`
    border-right: ${({ right }) => (right ? 'solid #dddddd thin' : '')};
    border-left: ${({ left }) => (left ? 'solid #dddddd thin' : '')};
    display: flex;
    align-items: flex-start;
    height: 50px;
    .MuiAutocomplete-root {
        margin: 0 5px !important;
    }
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)`
    &.MuiFormControl-root {
        margin: 0 5px !important;
    }
`;

export const CustomAutocomplete = styled(Autocomplete)`
    min-width: 200px;
    ${() => {
        return `
            &&& { 
                .MuiTextField-root {
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
        `;
    }}
`;

export const InfoContent = styled(AlertInfo)`
    margin: 5px 0;
`;

export const WarningCover = styled.div`
    text-align: center;
    color: ${ERROR};
`;
