import { ButtonGroup, Button as ButtonMui, Select, useTheme } from '@mui/material';
import styled from 'styled-components';
import { BACKGROUND_DARK_MODE, WHITE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { FONT_BIG_TEXT_SIZE } from 'components/Ui/variables';

export const TemperaturePressureTableViewContent = styled.div`
    width: 100%;
    margin-right: -400px;

    & .MuiTableHead-root .MuiTableCell-head {
        text-align: center;
        min-height: 45px;
        ${() => {
            return `
                background-color: ${useTheme().palette.mode === ThemeMode.light ? WHITE : BACKGROUND_DARK_MODE};
                min-width: 49px;
                padding: 9px;
            `;
        }}
    }
`;

export const LegendListContainer = styled.ul`
    list-style: none;
    padding: 0px;
    font-size: 13px;
    display: inline-grid;
    line-height: 1.7;
`;

export const LegendItemContainer = styled.li`
    display: inline-flex;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const fontSize = common.easyMode ? FONT_BIG_TEXT_SIZE : '13px';
        return `font-size: ${fontSize} ;`;
    }}
`;

export const IconContainer = styled.div`
    min-width: 28px;
    margin-left: 10px;

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;

        return common.easyMode ? 'font-size: 16px;' : '';
    }}
`;

export const IconTemperatureAlertDisabledContainer = styled.div`
    min-width: 31px;
    margin-left: 7px;
`;

export const LegendIcon = styled.div<{
    $background: string;
    $borderColor: string;
    $borderWidth?: string;
    $borderRadius?: string;
}>`
    background: ${(props) => `${props.$background}` ?? '#c0c0c0'};
    border: ${(props) => props.$borderWidth ?? '4px'} solid ${(props) => props.$borderColor ?? '#c0c0c0'};
    width: 24px;
    margin-right: 5px;
    margin-bottom: 3px;
    text-align: center;
    ${(props) => {
        return props.$borderWidth === '1px' && 'min-width: 30px;';
    }}
`;

export const ButtonFilter = styled(ButtonMui)<{ $active: boolean }>`
    display: inline-flex;
    border: #777 1px solid !important;
    padding: 5px !important;
    margin: 0 !important;
    background: ${(props) => (props.$active ? '#d9d9d9 ' : '#fff')} !important;
`;

export const ContainerGroupButtonFilter = styled(ButtonGroup)`
    margin-right: 5px !important;
    padding: 1px !important;

    .MuiButtonGroup-grouped {
        min-width: 25px;
    }
`;

export const SelectFilter = styled(Select)`
    color: unset;

    & .MuiInputBase-input {
        padding: 1px 24px 2px 5px !important;
    }

    & .MuiSelect-icon {
        color: #fff !important;
    }

    &:before {
        border-bottom: 1px solid #fff !important;
    }
    &:after {
        border-bottom: 1px solid #fff !important;
    }
`;

export const SelectFilterLabel = styled.div`
    margin-right: 5px;
`;
