import React from 'react';
import { DeviceDetailsTableContent } from './DeviceDetailsTable.view';
import { DeviceDetailsTableProps } from './DeviceDetailsTable.type';
import { useQuery } from '@tanstack/react-query';
import { Wrapper } from 'helpers/wrapper';
import Device from 'api/Device';
import { useSetRecoilState } from 'recoil';
import { ActiveDeviceDetailsTab } from './DeviceDetailsTable.state';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { DeviceQueryKeys } from 'models/Device.type';
import { DeviceAppRegionAtom } from 'components/Hub/DeviceCommandsTable/atom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const deviceApi = new Device();

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = (props): JSX.Element => {
    const setActiveTab = useSetRecoilState(ActiveDeviceDetailsTab);
    const setDeviceAppRegion = useSetRecoilState(DeviceAppRegionAtom);
    const { t: translate } = useTranslation();

    const { data, isLoading, isRefetching } = useQuery(
        [DeviceQueryKeys.getDetails, props.deviceId],
        () => deviceApi.getDetails(props.deviceId),
        {
            retry: false,
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess: (data1) => setDeviceAppRegion(data1.device.appRegion.name)
        }
    );

    const handleTabChange = (event, newTabIndex: number): void => {
        setActiveTab({ details: newTabIndex });
    };

    const getDiffFromLastTransmitted = (lastTransmitted?: string | null): string => {
        if (!lastTransmitted) return '';

        const lastTransmittedDate = DateTime.fromISO(lastTransmitted, { setZone: 'utc' });
        const current = DateTime.local().toUTC();

        const diffInSeconds: number = Math.floor(current.diff(lastTransmittedDate, ['seconds']).values.seconds);

        if (diffInSeconds < 60) return `(${diffInSeconds} ${translate('t.seconds')})`;
        if (diffInSeconds < 7200) return `(${Math.floor(diffInSeconds / 60)} ${translate('t.minutes')})`;

        if (diffInSeconds < 172800) return `(${Math.floor(diffInSeconds / 3600)} ${translate('t.hours')})`;

        return `(${Math.floor(diffInSeconds / 86400)} ${translate('t.days')})`;
    };

    return (
        <DeviceDetailsTableContent
            data-testid='DeviceDetailsTable-testid'
            handleTabChange={handleTabChange}
            deviceId={props.deviceId}
            data={data}
            isLoading={isLoading || isRefetching}
            getDiffFromLastTransmitted={getDiffFromLastTransmitted}
        />
    );
};

export default Wrapper(DeviceDetailsTable);
