import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import { styled as styleMui } from '@mui/system';
import { Typography, useTheme } from '@mui/material';
import { WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import MuiDialogContent from '@mui/material/DialogContent';

export const SecondStep = styleMui(Typography)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            dark: `color: ${WHITE};`
        });
    }}
`;

export const GraphContainer = styled.div`
    color: #3a3633;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px) {
        &::-webkit-scrollbar,
        & *::-webkit-scrollbar {
            width: 9px !important;
            height: 14px !important;
        }
    }
`;

export const AlertContainer = styled.div`
    margin: 0 auto;
    max-width: 350px;
`;

export const NoDataInPeriod = styled.p<{ hideReportOptions?: boolean }>`
    margin: 0;
    line-height: ${(props) => (props.hideReportOptions ? '940px' : '330px')};
    position: absolute;
`;

export const WarningNoColdPressure = styled(Alert)`
    padding: 3px 16px;
    margin: 5px 0;
    width: 100%;
    justify-content: center;
`;

export const ReportLeakFastButton = styled(Button)`
    &.MuiButton-root {
        background: #de3e0d;
        box-shadow: none;
        color: #fff;
        &:hover {
            background: #c7360a;
        }
        margin: 0 2px;
    }
`;

export const MuiDialogContentStyle = styleMui(MuiDialogContent)`
    @media (max-width: 767px) {
        max-height : 100vh !important;
    }
`;
