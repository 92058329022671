import React from 'react';
import { GlobalAdminNotifyTableViewPropsView } from './GlobalAdminNotifyTable.type';
import { GlobalAdminNotifyTableContainer } from './GlobalAdminNotifyTable.style';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiTable from 'components/Ui/UiTable/UiTable';
import Button from 'components/Ui/Components/UiButton';
import Widget from 'components/Ui/Components/UiWidget';
import HubNotify from 'api/HubNotify';
import { faMegaphone } from '@fortawesome/pro-solid-svg-icons';
import { applyStyleByMode } from 'helpers';
import AddIcon from '@mui/icons-material/Add';
import { SIZE_BUTTON } from 'components/Ui/variables';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const GlobalAdminNotifyTableContent: React.FC<GlobalAdminNotifyTableViewPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const notifyApi = new HubNotify();
    const hidden: string[] = ['createdAtFrom', 'createdAtTo'];
    const ThemeMode = useRecoilValue(Theme);

    return (
        <GlobalAdminNotifyTableContainer data-testid='GlobalAdminNotifyTableContent'>
            <Widget
                data-testid='NotifyTableContent'
                avatar={
                    <UiIcon
                        icon={faMegaphone}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.hub_notify')}
                loading={false}
                content={
                    <UiTable
                        columns={props.columns}
                        fetchFn={notifyApi.getHubNotifyTable}
                        hiddenColumns={hidden}
                        defaultSortBy={{
                            id: 'id',
                            desc: false
                        }}
                        rightActionBtns={() => (
                            <Button
                                variant='contained'
                                skin='success'
                                style={{ color: '#fff' }}
                                size={SIZE_BUTTON}
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    props.handleModalAdd();
                                }}
                            >
                                {translate('t.new_hub_notify')}
                            </Button>
                        )}
                        refresh={false}
                        queryKey={'hubNotify'}
                    />
                }
            ></Widget>
        </GlobalAdminNotifyTableContainer>
    );
};
