import { Box, Fab } from '@mui/material';
import { styled } from '@mui/system';

export const VehicleStatsCollapseButtonContainer = styled(Box)``;

export const FabCustom = styled(Fab)<{ $collapsed: boolean }>`
    &.MuiFab-root {
        margin: 0;
        width: 20px;
        height: 20px;
        min-height: 20px;
        margin-right: 6px;
        ${(props) => (props.$collapsed ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)')};
        transition: transform 200ms linear;
        background: transparent;
    }
`;
