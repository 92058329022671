import React from 'react';
import { VehicleDetailTyreBayProps } from './VehicleDetailTyreBay.type';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ActiveVehicleDetailsTab } from '../VehicleDetails.state';
import { isAdmin } from 'states/global/User';
import { VehicleDetailsWidgetAtom } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { Theme } from 'states/global/Theme';
import { VehicleDetailTyreBayContainer } from './VehicleDetailTyreBay.style';
import { VehicleModel } from 'models/Vehicle.type';
import { AlertsTab, IconLabel, VehicleDetailsTabs } from '../VehicleDetails.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { WHITE } from 'components/Ui/colors';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import VehicleDetailsSummary from 'components/Vehicle/VehicleDetailsSummary/VehicleDetailsSummary';
import VehicleDetailsSettings from 'components/Vehicle/VehicleDetailsSettings/VehicleDetailsSettings';
import TabContent from 'components/Ui/Components/UiTab/UiTab';

export const VehicleDetailTyreBay: React.FC<VehicleDetailTyreBayProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const activeTabAtom = useRecoilValue(ActiveVehicleDetailsTab);
    const activeTab = props.isVehicleAlertsWidget ? activeTabAtom.alertsWidget : activeTabAtom.detailsWidget;
    const isUserAdmin = useRecoilValue(isAdmin);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const ThemeMode = useRecoilValue(Theme);

    return (
        <VehicleDetailTyreBayContainer data-testid='VehicleDetailsContent'>
            {(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id && (
                <>
                    <VehicleDetailsTabs
                        value={activeTab}
                        onChange={props.handleTabChange}
                        indicatorColor='primary'
                        textColor='primary'
                        variant='scrollable'
                        scrollButtons='auto'
                    >
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={['fas', 'file-alt']}
                                        fontSize={15}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 left-3'
                                    />
                                    <div>{translate('t.summary')}</div>
                                </IconLabel>
                            }
                            $hide={props.displayTab(props.isVehicleAlertsWidget, false)}
                            data-testid='VehicleStatus-SummaryTab-Button'
                        />
                        <AlertsTab
                            label={
                                <IconLabel>
                                    <UiIcon
                                        icon={['fas', 'gear']}
                                        fontSize={17}
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: '#0000008a',
                                            dark: WHITE
                                        })}
                                        transform='up-1 down-0 left-3'
                                    />
                                    <div>{translate('t.settings')}</div>
                                </IconLabel>
                            }
                            $hide={!isUserAdmin || props.displayTab(props.isVehicleAlertsWidget, false)}
                            $badge={true}
                            data-testid='VehicleStatus-SettingsTab-Button'
                        />
                    </VehicleDetailsTabs>
                    <TabContent value={activeTab} index={0} testId='VehicleDetailsSummary'>
                        <UiScroll maxHeight='67.7vh'>
                            <VehicleDetailsSummary />
                        </UiScroll>
                    </TabContent>
                    <TabContent value={activeTab} index={1} testId='VehicleDetailsSettings'>
                        <VehicleDetailsSettings
                            vehicleDetails={vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id || 0}
                        />
                    </TabContent>
                </>
            )}
        </VehicleDetailTyreBayContainer>
    );
};
