import React from 'react';
import { TextTick, G, LoadingBar, GraphContent as GraphContentView, NoDataAlert, LegendValue } from './Graph.style';
import { GraphViewProps, xDomainConfig, yDomainConfig } from './Graph.type';
import TooltipElement from './Components/Tooltip/Tooltip';
import DotElement from './Components/Dot/Dot';
import ActiveDotElement from './Components/ActiveDot';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    LegendType,
    Line,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { applyStyleByMode, displayDecimal } from 'helpers';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@mui/material';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { PressureUnits } from 'components/CustomHooks/Converter/Converter.type';
import UiButton from 'components/Ui/Components/UiButton';

const generateXDomain = (
    lines: xDomainConfig[],
    disabledLegend: string[],
    translator?: any,
    testId?: string
): JSX.Element[] => {
    return lines?.map((line: xDomainConfig, index) => {
        const legendType: LegendType = line.hide ? 'none' : index % 2 === 0 ? 'square' : 'square';
        return (
            <Line
                yAxisId={line.yAxisId}
                type='linear'
                key={index}
                data-testid={`${testId}-generateXDomain-Line-${index}`}
                unit={line.unit}
                activeDot={
                    <ActiveDotElement
                        data-testid={`${testId}-generateXDomain-ActiveDotElement-${index}-${line.dataKey}`}
                        index={index}
                        showDot={line.showDot}
                        dataKey={line.dataKey}
                    />
                }
                dataKey={line.dataKey}
                name={translator ? translator(line.name) : line.name}
                stroke={line.stroke}
                connectNulls={true}
                strokeDasharray={line.strokeDasharray}
                isAnimationActive={false}
                dot={
                    <DotElement data-testid={`${testId}-generateXDomain-DotElement-${index}`} showDot={line.showDot} />
                }
                legendType={legendType}
                strokeOpacity={line.hide || line.hideOnlyLine ? 0 : 1}
                strokeWidth={line.hideOnlyLine ? 0 : line.strokeWidth ?? 1}
                hide={disabledLegend.includes(line.dataKey)}
            />
        );
    });
};

const generateYDomain = (
    yAxises: yDomainConfig[],
    dataYPadding?: number,
    translator?: any,
    ticks?: number[],
    formatter?: (e) => string,
    color?: string,
    testId?: string
): JSX.Element[] => {
    let domain: number[] | string[] = dataYPadding
        ? [`dataMin-${dataYPadding}`, `dataMax+${dataYPadding}`]
        : ['dataMin', 'dataMax'];
    return yAxises?.map((yAxis, index) => {
        let showDecimal = 1;
        const isFirstItem: boolean = index === 0;
        if (yAxis?.ticks?.length) {
            domain = [yAxis?.ticks[0], yAxis?.ticks[yAxis?.ticks.length - 1]];
        }
        if (ticks) {
            domain = [ticks[0], ticks[ticks.length - 1]];
        }

        if (yAxis) {
            const yAxisName = yAxis.name.split(' ');

            if (
                typeof yAxisName[1] === 'string' &&
                yAxisName[1].toLowerCase instanceof Function &&
                yAxisName[1].toLowerCase() === PressureUnits.KPA
            ) {
                showDecimal = 0;
            }
        }

        return (
            <YAxis
                key={index}
                data-testid={`${testId}-generateYDomain-YAxis-${index}`}
                orientation={index % 2 === 0 ? 'left' : 'right'}
                allowDataOverflow
                domain={domain}
                ticks={ticks || yAxis?.ticks}
                tick={({ x, y, payload, index, ...props }) => (
                    <G transform={`translate(${x},${y})`} data-testid={`yAxis-tick-g-${index}`}>
                        <TextTick
                            {...props}
                            data-testid={`${testId}-yAxis-tick-g-textTick-${props.textAnchor}-${index}`}
                            text-anchor={props.textAnchor}
                            fontSize='0.9em'
                            dy={'0.355em'}
                            x={0}
                            y={0}
                        >
                            {payload.value}
                        </TextTick>
                    </G>
                )}
                stroke={color}
                interval={0}
                type='number'
                yAxisId={yAxis.id}
                label={{
                    value: translator ? translator(yAxis.name) : yAxis.name,
                    fill: color,
                    position: 'insideLeft',
                    angle: 90 * (isFirstItem ? -1 : 1),
                    dx: isFirstItem ? 0 : 50,
                    dy: isFirstItem ? 30 : -30
                }}
                tickFormatter={(value) => {
                    return formatter
                        ? formatter(value)
                        : displayDecimal(value && isFinite(value) ? value : 0, showDecimal);
                }}
            />
        );
    });
};

export const GraphContent: React.FC<GraphViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const restHeight = props.graphData.data.length ? 0 : 18;
    const colorGraph = applyStyleByMode({
        theme: ThemeMode?.mode,
        light: '#e9e9e9;',
        dark: WHITE
    });
    const isMobile = useMediaQuery('(max-width: 1023px)');
    const minus: number = !props.graphData.data.length ? 15 : 0;
    const heightDefault = 200 - minus;
    const graphHeight: number | undefined = isMobile ? 340 - minus : props.graphHeight ?? 200 - minus;

    const height: number = graphHeight || heightDefault;

    if (props.isLoading) {
        return (
            <LoadingBar
                height={isMobile ? 340 : props.height || 200}
                data-testid={`${props.testId}-GraphContent-LoadingBar`}
            >
                <UiLoadingPage size='30px' />
            </LoadingBar>
        );
    }

    return (
        <GraphContentView data-testid={`${props.testId}-GraphContent`} height={isMobile ? 355 : props.height}>
            {!props.graphData.data.length && (
                <NoDataAlert>
                    <UiIcon icon={faTriangleExclamation} size='sm' />
                    <Typography variant='subtitle1' marginLeft='4px' component='span'>
                        {props.unableLoadChart ? translate('p.unable_load_data_chart') : translate('t.there_no_data')}
                    </Typography>
                </NoDataAlert>
            )}

            {props.showZoomBtn && props.resetZoomButton && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <UiButton testid='outzoom-button' variant='outlined' onClick={props.events?.zoomOut}>
                        {translate('t.reset_zoom')}
                    </UiButton>
                </div>
            )}
            <ResponsiveContainer width='100%' height={height - restHeight}>
                <ComposedChart
                    data-testid={`${props.testId}-GraphContent-ComposedChart-test1`}
                    height={props.height}
                    data={props.graphData.data}
                    syncId='test1'
                    onMouseDown={(nextState, e) => {
                        !isMobile ? props.events?.onMouseDown(nextState, e) : undefined;
                    }}
                    onMouseMove={(nextState, e) => {
                        !isMobile ? props.events?.onMouseMove(nextState, e) : undefined;
                    }}
                    onMouseUp={(nextState, e) => {
                        !isMobile ? props.events?.zoomIn(nextState, e) : undefined;
                    }}
                    style={{ fontSize: '13px' }}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' data-testid='GraphContent-CartesianGrid' />
                    <XAxis
                        dataKey='timeKey'
                        type='number'
                        tickCount={10}
                        stroke={colorGraph}
                        allowDataOverflow={true}
                        scale='linear'
                        ticks={props.xTicks}
                        data-testid={`${props.testId}-GraphContent-XAxis`}
                        minTickGap={props.xTicks?.length ? props.xTickGap ?? 0 : 100}
                        domain={props.globalXDomain || props.xDomain}
                        tickFormatter={props?.xTickFormatter}
                        tick={props?.customAxisTicks}
                    />
                    <YAxis
                        allowDataOverflow
                        yAxisId={'hiddenData'}
                        domain={[0, 0]}
                        hide={true}
                        data-testid={`${props.testId}-GraphContent-YAxis-hiddenData`}
                    />
                    <YAxis
                        allowDataOverflow
                        domain={[-1000, 1000]}
                        type='number'
                        yAxisId={'refArea'}
                        hide={true}
                        data-testid={`${props.testId}-GraphContent-YAxis-refArea`}
                    />
                    <YAxis
                        allowDataOverflow
                        domain={[0, 1000]}
                        type='number'
                        yAxisId={'-1'}
                        hide={true}
                        data-testid={`${props.testId}-GraphContent-YAxis-(-1)`}
                    />
                    {props.referenceLineX?.length &&
                        props.referenceLineX.map((referenceLine, index) => (
                            <ReferenceLine
                                key={index}
                                y={referenceLine.y}
                                stroke={referenceLine.stroke}
                                strokeDasharray={referenceLine.strokeDasharray}
                                yAxisId={referenceLine.yAxisId}
                                data-testid={`${props.testId}-GraphContent-ReferenceLine-y-${index}`}
                            >
                                <Label
                                    position='top'
                                    data-testid={`${props.testId}-GraphContent-Label-${referenceLine.label}`}
                                ></Label>
                            </ReferenceLine>
                        ))}
                    {props.referenceLineY?.x && (
                        <ReferenceLine
                            x={props.referenceLineY.x}
                            data-testid={`${props.testId}-GraphContent-ReferenceLine-x-${props.referenceLineY.x || ''}`}
                            yAxisId={props.referenceLineY?.yAxisId}
                            label={props.referenceLineY?.label || ''}
                            stroke={props.referenceLineY?.stroke || '#000'}
                            strokeDasharray={props.referenceLineY?.strokeDasharray || ''}
                        />
                    )}
                    {generateYDomain(
                        props.yDomainConfig,
                        props.dataYPadding,
                        translate,
                        props.yTicks,
                        props.yTickFormatter,
                        colorGraph,
                        props.testId
                    )}
                    {generateXDomain(props.xDomainConfig, props.disabledLegend, translate, props.testId)}
                    <Tooltip
                        data-testid={`${props.testId}-GraphContent-Tooltip`}
                        content={
                            <TooltipElement
                                data-testid={`${props.testId}-GraphContent-TooltipElement`}
                                translator={translate}
                                excludeFromTooltip={props.disabledLegend.concat(props.excludeFromTooltip || [])}
                            />
                        }
                        active={true}
                        filterNull={true}
                        wrapperStyle={{ zIndex: props.tooltipSettings?.zIndex ?? 0 }}
                        isAnimationActive={false}
                    />

                    {props.graphData.data.length && props.showLegend && (
                        <Legend
                            data-testid={`${props.testId}-GraphContent-Legend`}
                            onClick={props.events?.clickLegend}
                            wrapperStyle={{ fontSize: '13px' }}
                            formatter={(value, entry: unknown) => {
                                if (
                                    !(props as { excludeFromLegend: string[] }).excludeFromLegend.includes(
                                        (entry as { dataKey: string }).dataKey
                                    )
                                ) {
                                    const isDisabledLegend: boolean = props.disabledLegend.includes(
                                        (entry as { dataKey: string }).dataKey
                                    );
                                    const color: string = isDisabledLegend
                                        ? '#bbbbbb'
                                        : (entry as { color: string }).color;
                                    return <LegendValue color={color}>{value}</LegendValue>;
                                }
                                return undefined;
                            }}
                        />
                    )}

                    {props.plotBandDataKey && (
                        <Area
                            type='step'
                            activeDot={false}
                            data-testid={`${props.testId}-GraphContent-Area-${props.plotBandDataKey}`}
                            dataKey={props.plotBandDataKey}
                            isAnimationActive={false}
                            fill='#fffede'
                            stroke='#fffede'
                            opacity={1}
                            yAxisId='-1'
                        />
                    )}

                    {props.refDomain && props.refDomain[0] && props.refDomain[1] && (
                        <ReferenceArea
                            yAxisId='refArea'
                            data-testid={`${props.testId}-GraphContent-ReferenceArea-refArea-(0.3)`}
                            x1={props.refDomain[0]}
                            x2={props.refDomain[1]}
                            strokeOpacity={0.3}
                        />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
        </GraphContentView>
    );
};
