import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { BACKGROUND_DARK_MODE, WHITE } from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

export const TemperaturePressureTableViewContent = styled.div`
    width: 100%;
    margin-right: -400px;

    & .MuiTableHead-root .MuiTableCell-head {
        text-align: center;
        min-height: 45px;
        ${() => {
            return `
                background-color: ${useTheme().palette.mode === ThemeMode.light ? WHITE : BACKGROUND_DARK_MODE};
                min-width: 49px;
                padding: 9px;
            `;
        }}
    }
`;

export const ContainerActionWidget = styled.div`
    display: flex;
    padding: 0px 0px;
    align-items: center;

    ${() => useTheme().breakpoints.between('xs', 'lg')} {
        height: auto;
    }
`;

export const IconButtonTempPress = styled(UiIconButton)`
    margin: 0px 3px !important;
    padding: 2px 2px !important;
    font-size: 19px !important;
    width: 26px !important;
    height: 24px !important;
`;

export const TempPressWidgetContentContainer = styled.div<{ showScroll: boolean }>`
    height: ${({ showScroll }) => (showScroll ? 'calc(100vh - 110px)' : 'auto')};
`;

export const ContainerToPrint = styled.div<{ $showScroll: boolean }>`
    @media print {
        @page {
            size: A3 landscape;
        }
    }
`;

export const AlertContainer = styled.span`
    margin-left: 16px;
    background-color: rgb(253, 242, 217);
    padding-left: 16px;
    padding-right: 16px;
    color: rgb(102, 60, 0);
    border-radius: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
`;

export const PrinterContainer = styled.div`
    display: none;
`;
