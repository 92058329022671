import styled from 'styled-components';
import imgSrc from '../../assets/images/exception_404_background.png';
import { useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const CustomerRedirectionContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${imgSrc});
    background-size: cover;
    background-repeat: no-repeat, repeat;
    background-position: center;
`;

export const CustomerRedirectionContainerCover = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    ${() =>
        applyStyleByMode({
            theme: useTheme().palette.mode,
            light: '',
            dark: `
            background: rgb(0,0,0);
            background: linear-gradient(to right, black, transparent);
        `
        })}
`;

export const ContainerLogo = styled.div`
    img {
        max-width: 445px;
    }
`;

export const ContentBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;
