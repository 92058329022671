import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { UiWidgetContent } from './UiWidget.view';
import { UiWidgetProps, WidgetLocalStorageInfo } from './UiWidget.type';
import { useRecoilValue } from 'recoil';
import { User } from '../../../../states/global/User';
import { PRIMARY } from 'components/Ui/colors';
import { Wrapper } from 'helpers/wrapper';
import { useMediaQuery } from '@mui/material';

const UiWidget: React.FC<UiWidgetProps> = (props): JSX.Element => {
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const user = useRecoilValue(User);
    const [collapsedWidget, setCollapseWidget] = useState<boolean | undefined>();
    const widgetRef = useRef<HTMLDivElement | null>(null);
    const [lock, setLock] = useState<boolean | undefined>();
    const isSm = useMediaQuery('(max-width:767px)');
    const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');

    const changeFullscreen = (): void => {
        setFullscreen((current) => !current);
    };

    const changeCollapsed = (): void => {
        const currentState =
            collapsedWidget === undefined
                ? getWidgetCollapseInfo(props.id, props.collapse, props.ignoreStoredCollapse)
                : collapsedWidget;
        setCollapseWidget(!currentState);
    };

    const changeLock = (): void => {
        setLock((current) => !current);
    };

    const getWidgetInfo = (id: string): WidgetLocalStorageInfo => {
        if (id) {
            const localStorageString: string | null = localStorage.getItem('widgetStatus');
            const parsedWidgetStorage = localStorageString ? JSON.parse(localStorageString) : {};
            if (parsedWidgetStorage[id]) {
                return parsedWidgetStorage[id];
            }
        }
        return {};
    };

    const getWidgetCollapseInfo = (id, collapse?: boolean, ignoreStoredCollapse?: boolean): boolean => {
        if (id) {
            if (!ignoreStoredCollapse) {
                const widgetInfo = getWidgetInfo(id);
                return widgetInfo.collapsed || false;
            }
            return collapse || false;
        }
        return collapse || false;
    };

    useEffect(() => {
        if (props.forceCollapse) {
            setCollapseWidget(true);
        }
    }, [props.forceCollapse]);

    useEffect(() => {
        if (props.forceUncollapse) {
            setCollapseWidget(false);
        }
    }, [props.forceUncollapse]);

    useEffect(() => {
        if (typeof fullscreen !== 'undefined') {
            props.onResize && props.onResize();
        }
    }, [fullscreen]);

    useEffect(() => {
        setTimeout(() => {
            props.onResize && props.onResize();
        }, 300);
    }, [collapsedWidget, user?.userSetting?.systemTotalsStage]);

    useEffect(() => {
        setTimeout(() => {
            props.onResize && props.onResize();
        }, 170);
    }, []);

    useLayoutEffect(() => {
        let onScroll = () => {};
        if (widgetRef?.current && props.lockButton) {
            const divAnimate = widgetRef.current.getBoundingClientRect().top;
            onScroll = () => {
                if (divAnimate < scrollY && widgetRef.current && lock) {
                    const divAnimate2 = widgetRef.current.getBoundingClientRect().top;
                    if (divAnimate2 <= 64) {
                        widgetRef.current.style.position = 'fixed';
                        widgetRef.current.style.top = '64px';
                        widgetRef.current.style.zIndex = '1';
                        const color = PRIMARY;
                        let width = 'auto';
                        if (isSm) {
                            width = '86vw';
                        } else if (isTablet) {
                            width = '92vw';
                        }
                        widgetRef.current.style.border = `2px solid ${color}`;
                        widgetRef.current.style.borderRadius = '3px';
                        widgetRef.current.style.width = width;
                        widgetRef.current.style.overflow = 'auto';
                        props.onScrollFixed && props.onScrollFixed();
                    }
                } else {
                    if (widgetRef.current) {
                        widgetRef.current.style.position = 'relative';
                        widgetRef.current.style.top = '0';
                        widgetRef.current.style.border = '0';
                        props.onScrollRelative && props.onScrollRelative();
                    }
                }
            };
            window.addEventListener('scroll', onScroll);
        }
        return () => window.removeEventListener('scroll', onScroll);
    }, [lock]);

    return (
        <UiWidgetContent
            data-testid='UiWidget-testid'
            {...props}
            collapsed={collapsedWidget ?? getWidgetCollapseInfo(props.id, props.collapse, props.ignoreStoredCollapse)}
            fullscreen={fullscreen}
            widgetRef={widgetRef}
            changeLock={changeLock}
            lock={lock}
            changeCollapsed={changeCollapsed}
            changeFullscreen={changeFullscreen}
        />
    );
};

export default Wrapper(UiWidget);
