import React from 'react';
import {
    ContentGrid,
    LastHubTransmission,
    LegendIcon,
    LegendTable,
    Text,
    TextBox,
    VehicleDetailsWidgetViewContent,
    WidgetContent,
    WidgetHeader
} from './VehicleDetailsWidget.style';
import { VehicleDetailsWidgetViewProps } from './VehicleDetailsWidget.type';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import VehicleDetails from '../VehicleDetails/VehicleDetails';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import VehicleDetailsActionMenu from '../VehicleDetailsActionMenu';
import { VehicleDetailsWidgetAtom } from './VehicleDetailsWidget.atom';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { Theme, ThemeMode } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { VehicleModel } from 'models/Vehicle.type';
import { CustomerSettings } from 'states/global/CustomerSettings';
import VehicleGeneralDetailsTab from '../VehicleGeneralDetailsTab/VehicleGeneralDetailsTab';

const LegendContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    return (
        <LegendTable>
            <tbody>
                <tr>
                    <UiIcon icon={['fas', 'snowflake']} size='lg' fixedWidth color='#0069FF' />
                    <td>{translate('t.calculated_cold_pressure')}</td>
                </tr>
                <tr>
                    <UiIcon icon={['fas', 'sun']} size='lg' fixedWidth color='#ff0000' />
                    <td>{translate('t.gauge_hot_pressure')}</td>
                </tr>
                <tr>
                    <UiIcon icon={['fas', 'tire-rugged']} size='lg' fixedWidth />
                    <td>{translate('t.tyre_serial_number')}</td>
                </tr>
                <tr>
                    <UiIcon icon={['fas', 'unlink']} size='lg' fixedWidth />
                    <td>{translate('t.sensor_serial_number')}</td>
                </tr>
                <tr>
                    <LegendIcon>
                        <ExternalIcon
                            style={{
                                width: theme.easyReadingMode ? '20px' : '14px',
                                height: theme.easyReadingMode ? '20px' : '14px',
                                fill: theme.mode === ThemeMode.light ? '#0000008a' : WHITE
                            }}
                        />
                    </LegendIcon>
                    <td>{translate('t.external_sensor')}</td>
                </tr>
                <tr>
                    <UiIcon icon={['fas', 'hourglass-half']} size='lg' fixedWidth />
                    <td>{translate('p.sensor_not_transmitting_more30m_ago')}</td>
                </tr>
                <tr>
                    <UiIcon icon={['fas', 'exclamation']} size='lg' fixedWidth color='red' />
                    <td>{`${translate('t.sensor')} ${translate('t.marked_faulty').toLowerCase()}`}</td>
                </tr>
                {customerSettings.temperature_prediction && (
                    <tr>
                        <UiIcon icon={['fas', 'chart-mixed-up-circle-currency']} size='lg' fixedWidth />
                        <td>{translate('t.has_temperature_prediction')}</td>
                    </tr>
                )}
                {customerSettings.temperature_prediction && (
                    <tr>
                        <span className='fa-layers fa-fw'>
                            <UiIcon icon={['fas', 'slash']} size='1x' fixedWidth />
                            <UiIcon icon={['fas', 'p']} size='1x' fixedWidth />
                        </span>
                        <td>{translate('p.temperature_prediction_not_working')}</td>
                    </tr>
                )}
                {!customerSettings.secondary_sensors_alerts && (
                    <tr>
                        <UiIcon
                            icon={['fas', 'bell-slash']}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: theme?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                        <td>{translate('t.secondary_sensor_notificatons_disabled')}</td>
                    </tr>
                )}
            </tbody>
        </LegendTable>
    );
};

export const VehicleDetailsWidgetContent: React.FC<VehicleDetailsWidgetViewProps> = (props): JSX.Element => {
    const vehicleId = props.vehicleId ?? 0;
    const { t: translate } = useTranslation();
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const { fromUTCToTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    let HeightWidget = 79;

    if (!props.matches) {
        HeightWidget = 67;
    }

    return (
        <VehicleDetailsWidgetViewContent data-testid='VehicleDetailsWidgetContent' id='wid-vehicle-summary-info'>
            <UiWidget
                id='vehicle-details-widget'
                title={`${translate('t.vehicle_details')} ${
                    (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name || ''
                }`}
                avatar={
                    <UiIcon
                        icon={['fas', 'analytics']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: SECONDARY_DARK_MODE,
                            dark: WHITE
                        })}
                    />
                }
                loading={vehicleId > 0 ? props.loadingData : false}
                collapse={false}
                $contentHeight={HeightWidget}
                unit='vh'
                headerAction={
                    <WidgetHeader>
                        <LastHubTransmission data-testid='VehicleDetailsWidget-LastTransmissionHub-dateTime'>
                            {vehicleId > 0 &&
                                `${translate('t.last_transmission_hub')} ${
                                    (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.vehicleInfo?.measuredAt
                                        ? fromUTCToTimezone(
                                              (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel).vehicleInfo
                                                  .measuredAt as string,
                                              false
                                          )
                                        : '-'
                                }`}
                        </LastHubTransmission>
                        <UiLegend content={<LegendContent />} title={translate('t.legend')} />
                    </WidgetHeader>
                }
                content={
                    props.vehicleId === 0 ? (
                        <TextBox>
                            <Text>{translate('t.choose_vehicle')}</Text>
                            <UiIcon
                                icon={['fas', 'truck']}
                                size='2x'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                            />
                        </TextBox>
                    ) : (
                        <WidgetContent>
                            <ContentGrid container spacing={2}>
                                <Grid
                                    sx={{ height: { xs: '320px', sm: 'auto', md: 'auto' } }}
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    textAlign='center'
                                >
                                    <VehicleGeneralDetailsTab {...props} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <VehicleDetails isVehicleAlertsWidget={false} />
                                </Grid>
                            </ContentGrid>
                            <div className='vehicle-tyre-alert-modal'> </div>
                        </WidgetContent>
                    )
                }
            />
            <VehicleDetailsActionMenu />
        </VehicleDetailsWidgetViewContent>
    );
};
