import React from 'react';
import { useRecoilValue } from 'recoil';
import { RoutesTableViewContent } from './RoutesTable.style';
import { RoutesTableViewProps } from './RoutesTable.type';
import { useTranslation } from 'react-i18next';
import UiTable from 'components/Ui/UiTable/UiTable';
import Widget from 'components/Ui/Components/UiWidget/UiWidget';
import Route from 'api/Route';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { useTheme, useMediaQuery } from '@mui/material';

export const RoutesTableContent: React.FC<RoutesTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const routeAapi = new Route();
    const ThemeMode = useRecoilValue(Theme);
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    return (
        <RoutesTableViewContent data-testid='RoutesTableContent'>
            <Widget
                data-testid={'RoutesTableContent-Widget'}
                title={translate('t.routes')}
                avatar={
                    <UiIcon
                        data-testid={'RoutesTableContent-UiIcon'}
                        icon={['fas', 'shuffle']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                padding={0}
                content={
                    <UiTable
                        data-testid='RoutesTableContent-UiTable'
                        fetchFn={routeAapi.getAll}
                        columns={props.columns}
                        hiddenColumns={[]}
                        defaultSortBy={{
                            id: 'name',
                            desc: false
                        }}
                        queryKey='routesAll'
                        maxHeight={showXsSm ? 300 : 0}
                        onlyIconsForDefActionBtns
                    />
                }
            />
        </RoutesTableViewContent>
    );
};
