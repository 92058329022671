import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const NotTransmittingPopoverContainer = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            && .MuiTypography-root {
                font-size: ${common.textSize};
            }
        `;
    }}
`;

export const LoadingContent = styled.div`
    text-align: center;
`;
