import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { PRIMARY } from '../Ui/colors';
import Button from '@mui/material/Button';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const AmbientTemperatureContainer = styled.div``;

export const Loading = styled(CircularProgress)({
    color: `${PRIMARY} !important`,
    margin: 'auto'
});

export const DefaultButton = styled(Button)`
    color: ${PRIMARY} !important;
`;

export const CustomTextField = styled(TextField)`
    ${applyStyleByMode({
        styleJade: `
        &.MuiTextField-root {
            margin: 5px 7px;
        }
        `
    })}
`;

export const ButtonOpenPopUp = styled(Button)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize} !important;`;
    }}
    margin: 0px !important;
    padding: 0px !important;
`;
