import styled from 'styled-components';
import { InputLabel, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ButtonsToTestAlertsContent = styled.div`
    text-align: center;
    margin-left: 10px;

    label {
        margin: 6px 1px 3px 16px;
    }
`;

export const CustomInputLabel = styled(InputLabel)``;

export const ButtonsBox = styled.div``;

export const ContainerButton = styled.div<{ direction: string }>`
    float: ${({ direction }) => direction};
    padding: 8px;

    && {
        button {
            min-width: 136px;
        }
    }
`;

export const FieldsetTestAlerts = styled.fieldset`
    && {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `border-color: #c4c4c4;`,
                dark: `border-color: #656565;`
            });
        }}

        border-radius: 5px;
        border-width: thin;
        border-style: solid;
        margin-right: 10px;

        legend {
            padding-inline: 4px !important;
            text-align: start;
            width: auto;
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `
                    font-size: ${common.easyMode ? common.textSize : '0.71rem'};
                `;
            }}
        }
    }
`;

export const ContainerButtons = styled.div`
    text-align: center;
    display: inline-flex;
    ${() => useTheme().breakpoints.between('xs', 'sm')} {
        display: inline-block;
    }
`;
