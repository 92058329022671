import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { EnabledCell } from './GlobalAdmin.type';
import Button from '@mui/material/Button';
import { Checkbox, CircularProgress, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { BLUE_BRIDGESTONE, BRANDIES_BLUE } from 'components/Ui/colors';

export const DISABLED = '#dc3545';

export const GlobalAdminContainer = styled.div`
    th,
    td {
        overflow-wrap: break-word;
    }

    width: 100%;
    && .MuiCardContent-root {
        padding: 0px 10px 10px 10px;
        font-size: 13px !important;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        font-size: 15px;
        margin: 20px 2px;
    }
`;

export const Cell = styled(Typography)<EnabledCell>`
    ${({ enabled }) =>
        applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `color: ${enabled ? BRANDIES_BLUE : DISABLED};`,
            dark: `color: ${enabled ? BLUE_BRIDGESTONE : DISABLED};`
        })};
`;

export const UiButton = styled(Button)`
    min-width: 20px !important;
    max-width: 20px !important;
`;

export const TempCheckBox = styled(Checkbox)`
    height: 36px;
`;

export const CustomLoading = styled(CircularProgress)`
    margin-left: 10px;
    margin-top: 5px;
`;

export const ImageCell = styled.div`
    background: #fff;
    border-radius: 4px;
    text-align: center;
    max-width: 140px;
`;
