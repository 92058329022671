import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TemperaturePredictionMenuAtom } from './TemperaturePredictionPopover.atom';
import {
    CustomPopover,
    VehicleDetailsActionMenuViewContent,
    PredictionViewContent,
    Header,
    FontAwesomeIconCustom
} from './TemperaturePredictionPopover.style';
import { Paper, Grid, Typography } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme } from 'states/global/Theme';
import { BACKGROUND_BLUE, COLOR_GREY_4, COLOR_GREY_DARK, SUCCESS, WHITE } from 'components/Ui/colors';
import { applyStyleByMode } from 'helpers';
import { useTranslation } from 'react-i18next';
import { faFlag } from '@fortawesome/pro-duotone-svg-icons';
import TemperaturePredictionActionMenu from '../TemperaturePredictionActionMenu/TemperaturePredictionActionMenu';

const TemperaturePredictionPopover = (): JSX.Element => {
    const [temperaturePredictionMenu, setTemperaturePredictionMenu] = useRecoilState(TemperaturePredictionMenuAtom);
    const ThemeMode = useRecoilValue(Theme);
    const { t: translate } = useTranslation();

    const getXPosition = useMemo((): number => {
        if (document.documentElement.clientWidth - temperaturePredictionMenu.positionX > 170) {
            return temperaturePredictionMenu.positionX - 15;
        }
        return temperaturePredictionMenu.positionX - 165;
    }, [temperaturePredictionMenu.positionX]);

    const getYPosition = useMemo((): number => {
        return temperaturePredictionMenu.positionY - 115;
    }, [temperaturePredictionMenu.positionY]);

    return (
        <VehicleDetailsActionMenuViewContent data-testid='VehicleDetailsActionMenuContent'>
            <CustomPopover
                top={getYPosition}
                left={getXPosition}
                open={temperaturePredictionMenu.isOpen}
                onClose={() => {
                    setTemperaturePredictionMenu({
                        ...temperaturePredictionMenu,
                        isOpen: false
                    });
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <PredictionViewContent>
                    <Paper>
                        <Header>
                            <Grid container>
                                <Grid item xs={9} md={9}>
                                    <UiIcon
                                        icon={['fas', 'temperature-half']}
                                        size='lg'
                                        fixedWidth
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: SUCCESS,
                                            dark: WHITE
                                        })}
                                    />
                                    {` ${translate('t.temperature_prediction')} - ${
                                        temperaturePredictionMenu?.data?.vehicleName
                                    }`}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <FontAwesomeIconCustom
                                        icon={faFlag}
                                        size='sm'
                                        fixedWidth
                                        cursor='pointer'
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: temperaturePredictionMenu.data?.counterActionsExists
                                                ? '#01fe00'
                                                : COLOR_GREY_4,
                                            dark: temperaturePredictionMenu.data?.counterActionsExists
                                                ? '#01fe00'
                                                : COLOR_GREY_4
                                        })}
                                    />
                                    <Typography
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: temperaturePredictionMenu.data?.counterActionsExists
                                                ? BACKGROUND_BLUE
                                                : COLOR_GREY_DARK,
                                            dark: temperaturePredictionMenu.data?.counterActionsExists
                                                ? BACKGROUND_BLUE
                                                : COLOR_GREY_4
                                        })}
                                    >
                                        {translate('t.counter_actions')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Header>
                        <TemperaturePredictionActionMenu
                            wheelPredictions={temperaturePredictionMenu?.data?.wheelPredictions}
                        />
                    </Paper>
                </PredictionViewContent>
            </CustomPopover>
        </VehicleDetailsActionMenuViewContent>
    );
};

export default TemperaturePredictionPopover;
