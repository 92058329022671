import PortalApi from 'helpers/api/PortalApiClient';
import { UserCustomerAccessModel } from '../models/UserCustomerAccess.type';
import { getParams } from 'helpers';

export default class UserCustomerAccess extends PortalApi<UserCustomerAccessModel> {
    route = 'user-customer-access';

    getExportAll = async (props): Promise<string> => {
        const propsParams = getParams(props);

        if (propsParams?.enabled) {
            propsParams.type = propsParams.enabled;
            delete propsParams.enabled;
        } else {
            propsParams.type = 'all';
        }

        return this.getByCriteria({
            extendUrl: 'all/export',
            criteria: propsParams
        });
    };
}
