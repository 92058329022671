import React, { useEffect } from 'react';
import { AreaEntriesViewContent } from './AreaEntries.style';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { TopBarAtom } from 'components/Mixs/DataExport/TopBar/TopBar.atom';
import UiWidget from 'components/Ui/Components/UiWidget';
import { Wrapper } from 'helpers/wrapper';
import DataExport from 'components/Mixs/DataExport';
import AreaEntriesAPI from 'api/AreaEvent';
import { useQuery } from '@tanstack/react-query';
import AreaSelect from 'components/Mixs/DataExport/AreaSelect';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { Success } from 'components/Popup';
import { applyStyleByMode } from 'helpers';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { AreaEventQueryKeys } from 'models/AreaEvent.type';

const areaEntriesAPI = new AreaEntriesAPI();

const AreaEntries: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const topBarAtom = useRecoilValue(TopBarAtom);
    const { fromUTCtoUserTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    const getDurationWithMinutes = (value: number): string => {
        let secondsToParse = value ? value : 0;
        let seconds;
        let minutes = 0;
        if (secondsToParse >= 60) {
            minutes = Math.trunc(secondsToParse / 60);
            seconds = secondsToParse - Math.trunc(secondsToParse / 60) * 60;
        } else {
            seconds = secondsToParse;
        }
        return `${minutes}m ${seconds}s`;
    };

    const { refetch } = useQuery(
        [AreaEventQueryKeys.getAreaEntriesReportsCsv],
        () =>
            areaEntriesAPI.getAreaEntriesReportsCsv(
                topBarAtom.vehicle.id,
                topBarAtom.dateFrom.toUTC().toISO(),
                topBarAtom.dateTo.toUTC().toISO(),
                topBarAtom.areaEntries
            ),
        {
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: () => Success({ text: translate('p.report_success_message') })
        }
    );

    const columns = [
        {
            header: translate('t.vehicle'),
            accessor: 'vehicleName'
        },
        {
            header: translate('t.area'),
            accessor: 'areaName'
        },
        {
            header: translate('t.entered_at'),
            accessor: 'enteredAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: translate('t.left_at'),
            accessor: 'leftAt',
            parseData: (props) => fromUTCtoUserTimezone({ date: props, format: 'dateTime' })
        },
        {
            header: `${translate('t.duration')} (S)`,
            accessor: 'duration'
        },
        {
            header: translate('t.duration'),
            accessor: 'duration',
            parseData: (props) => getDurationWithMinutes(props)
        }
    ];

    useEffect(() => {
        setPageHelpButton({
            article: 'data-exports-tab'
        });

        return () => {
            resetPageHelpButton();
        };
    }, [setPageHelpButton]);

    return (
        <AreaEntriesViewContent data-testid='AreaEntries-testid'>
            <UiWidget
                title={translate('t.area_entries')}
                id='area-entries-widget'
                avatar={
                    <UiIcon
                        icon={faMap}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                content={
                    <DataExport
                        columns={columns}
                        fetchFn={areaEntriesAPI.getAreaEntriesReports}
                        downloadFn={refetch}
                        queryKey='GetAreaEntriesPreview'
                        additionalFetchProps={{
                            vehicleId: topBarAtom.vehicle.id,
                            areaId: topBarAtom.areaEntries,
                            measuredFrom: topBarAtom.dateFrom.toUTC().toISO(),
                            measuredTo: topBarAtom.dateTo.toUTC().toISO()
                        }}
                        additionalElement={<AreaSelect />}
                    />
                }
            />
        </AreaEntriesViewContent>
    );
};

export default Wrapper(AreaEntries);
