import { ButtonGroup, Button as ButtonMui, Select, useTheme } from '@mui/material';
import styled from 'styled-components';
import { WHITE } from 'components/Ui/colors';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { ThemeMode } from 'states/global/Theme';

export const ButtonFilter = styled(ButtonMui)<{ $active: boolean }>`
    display: inline-flex;
    border: #777 1px solid !important;
    padding: 5px !important;
    margin: 0 !important;
    background: ${(props) => (props.$active ? '#d9d9d9 ' : '#fff')} !important;
`;

export const ContainerGroupButtonFilter = styled(ButtonGroup)`
    margin-right: 5px !important;
    padding: 1px !important;

    .MuiButtonGroup-grouped {
        min-width: 25px;
    }
`;

export const ContainerActionWidget = styled.div`
    display: flex;
    padding: 0px 0px;
    align-items: center;

    ${() => useTheme().breakpoints.between('xs', 'lg')} {
        height: auto;
    }
`;

export const SelectFilter = styled(Select)`
    color: unset;

    & .MuiInputBase-input {
        padding: 1px 24px 2px 5px !important;
    }

    & .MuiSelect-icon {
        color: #fff !important;
    }

    &:before {
        border-bottom: 1px solid #fff !important;
    }
    &:after {
        border-bottom: 1px solid #fff !important;
    }
`;

export const ContainerSelectFilter = styled.div`
    padding: 5px 5px;
    align-items: baseline;
    display: inline-flex;
`;

export const SelectFilterLabel = styled.div`
    margin-right: 5px;
`;

export const CustomButton = styled(Button)`
    ${() => {
        return `
            color: ${useTheme().palette.mode === ThemeMode.light ? '#000' : WHITE} !important;
        `;
    }}
    text-transform: initial !important;
`;

export const ButtonTitle = styled.div`
    max-width: ${() => {
        return '200px';
    }}
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media only screen and (max-width: 424px) {
        max-width: 140px;
    }
    @media only screen and (min-width: 900px) {
        max-width: ${() => {
            return '200px';
        }}
    }
    @media only screen and (min-width: 1200px) {
        max-width: ${() => {
            return '200px';
        }}
    }
    @media only screen and (min-width: 1536px) {
        max-width: 500px;
    }
`;

export const ActionsHeader = styled.div`
    ${() => {
        return `
            background: ${useTheme().palette.mode === ThemeMode.light ? WHITE : 'transparent'};
            border-bottom: thin solid #c0c0c0;
        `;
    }}

    padding: 0px 3px 0px 3px
    text-align: center;
    width: 97.3%;
    margin: 0 auto;
`;
