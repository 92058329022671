import UiButton from 'components/Ui/Components/UiButton/UiButton';
import styled from 'styled-components';

export const StatisticsButtonContainer = styled.div``;

export const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
`;

export const ContainerButtonBES = styled.div`
    margin-top: 5px;
`;

export const StatisticsBtn = styled(UiButton)`
    & .muibutton-root: {
        margin: 5px;
    }
`;

export const BESBtn = styled(StatisticsBtn)`
    & .muibutton-root: {
        text-align: center;
    }
`;
