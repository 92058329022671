import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled, { css, keyframes } from 'styled-components';
import { PRIMARY_LIGHT } from '../../colors';
import MaUTable from '@mui/material/Table';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import RefreshIcon from '@mui/icons-material/Refresh';

export const UiSimpleTableContentView = styled.div<{ $tableOnModal?: boolean }>`
    ${({ $tableOnModal }) => {
        return $tableOnModal
            ? `
       
        & .simpleTableRefreshBtn {
        justify-content: flex-start;
        }
        
        & .MuiTableContainer-root {
                overflow: visible;
                
        } `
            : '';
    }}
`;

export const Table = styled(MaUTable)`
    td {
        line-height: 21px !important;
    }

    &.loading {
        opacity: 0.5;
    }
`;

export const TableActionsCover = styled.div`
    width: 100%;
`;

export const CustomTableRow = styled(TableRow)`
    ${() => {
        return `
            &:nth-of-type(even) {
                background-color: ${useTheme().palette.mode === ThemeMode.dark ? '#1d2630de' : 'rgba(0, 0, 0, 0.04)'};
            }
        `;
    }}
`;

export const CustomHeaderRow = styled(TableRow)``;

export const CustomHeaderCell = styled(TableCell)``;

export const ResizeSeparator = styled.div`
    position: absolute;
    cursor: col-resize;
    z-index: 100;
    opacity: 0;
    height: 50%;
    top: 25%;
    transition: all linear 100ms;
    right: -2px;
    width: 3px;
    &.isResizing {
        opacity: 1;
        border: none;
        background-color: ${PRIMARY_LIGHT};
        height: calc(100% - 4px);
        top: 2px;
        right: -1px;
        width: 1px;
    }
`;

export const EmptyTable = styled(TableCell)`
    height: 70px;
    border-bottom: #e5e5e5 solid thin;

    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            dark: `
                & p {
                    color: #fff !important;
                }
            `
        });
    }}
    text-align: center !important;
`;

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 51vh;
    font-size: 1.25rem;
`;

export const RefreshButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 13px;
`;

export const RefreshButton = styled(RefreshIcon)<{ $spin: boolean }>`
    animation: ${(props) => props.$spin && refreshButtonAnimation()};
`;

const refreshButtonAnimation = () => css`
    ${refreshButtonSpin} 0.7s steps(120) infinite;
`;

const refreshButtonSpin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;
