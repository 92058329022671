import styled from 'styled-components';

export const StatisticsGraphsContainer = styled.div`
    @media (max-width: 767px) {
        &::-webkit-scrollbar,
        & *::-webkit-scrollbar {
            width: 9px !important;
            height: 14px !important;
        }
    }
`;

export const ZoomBtnCover = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;
