import React from 'react';
import { ReuseHubContainer } from './ReuseHub.style';
import { ReuseHubProps } from './ReuseHub.type';
import MuiDialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { VARIANT_ALERT, VARIANT_BUTTON } from 'components/Ui/variables';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Alert, Grid } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import Device from 'api/Device';
import { Success } from 'components/Popup/Popup';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const deviceApi = new Device();

const ReuseHub: React.FC<ReuseHubProps> = ({ hub }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryFn = useRecoilValue(QueryFn(`deviceCommandsTableTerminal-QueryFn`));

    const { mutate: reuseHubMutate, isLoading } = useMutation(deviceApi.reuseHub, {
        onSuccess: (data) => {
            if (data) {
                Success({
                    text: translate('p.hub_recycled')
                });
                modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
                queryFn.refetch instanceof Function && queryFn.refetch();
            }
        }
    });

    return (
        <ReuseHubContainer>
            <MuiDialogContent data-testid='ReuseHubContent-MuiDialogContent'>
                <Grid container justify-content='flex-start' direction='row' spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Alert variant={VARIANT_ALERT} severity='info'>
                            {translate('p.reuse_hub', { mac_hub: hub.macAddressWifi })}
                        </Alert>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Alert variant={VARIANT_ALERT} severity='warning'>
                            {translate('p.reuse_hub_confirmation')}
                        </Alert>
                    </Grid>
                </Grid>
            </MuiDialogContent>
            <ContainerActions>
                <CustomDialogActions>
                    <>
                        <UiButton
                            skin='success'
                            form='tyre-action-form '
                            color='primary'
                            testid='ReuseHubContent-confirm-button'
                            variant={VARIANT_BUTTON}
                            loading={isLoading}
                            type='submit'
                            onClick={() => reuseHubMutate(hub.id)}
                        >
                            {translate('t.confirm')}
                        </UiButton>
                        <CancelButton
                            data-testid='Modal-Cancel-Button'
                            onClick={() => modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL })}
                            variant='outlined'
                            color='primary'
                            size='small'
                        >
                            {translate('t.cancel')}
                        </CancelButton>
                    </>
                </CustomDialogActions>
            </ContainerActions>
        </ReuseHubContainer>
    );
};

export default ReuseHub;
