import React, { useEffect } from 'react';
import * as view from './VehicleTable.view';
import VehicleSchema from '../NewVehicleSchema';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '../../Ui/Components/UiMenu';
import { VehicleActionAtom } from 'states/component/Vehicle';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ShowVehicleModal } from 'states/global/Modal';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CRUD, NotValue } from 'variables';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { FormControlLabel, Switch } from '@mui/material';
import { applyStyleByMode, booleanToNumber, dateCheckByMonth, isVehicleTyreBayHub } from 'helpers';
import { SIZE_BUTTON } from '../../Ui/variables';
import UiLink from '../../Ui/Components/UiLink/UiLink';
import { COLOR_GREY, WHITE } from '../../Ui/colors';
import { Theme } from 'states/global/Theme';
import { ContainerSwitchFilter, HubContainer } from './VehicleTable.style';
import { getImgSource } from 'helpers/image';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { VehicleModel } from 'models/Vehicle.type';
import { Wrapper } from 'helpers/wrapper';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';

const VehicleTable: React.FC = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const setVehicleModal = useSetRecoilState(ShowVehicleModal);
    const setVehicleActionAtom = useSetRecoilState(VehicleActionAtom);
    const { t: translate } = useTranslation();
    const sizeImage = 30;
    const colorTransmission = 'rgb(35 116 255)';
    const colorTransmissionGrey = 'rgb(255, 0, 0)';
    const ThemeMode = useRecoilValue(Theme);
    const { convertType, fromUTCtoUserTimezone, fromServerToUserUnit, displayUserUnits } = useConverter();

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem(`${userInfo.user?.id || 0}`) || '{}');
        if (data) {
            if (data?.table?.VehiclesTable?.orderBy?.length) {
                if (data?.table?.VehiclesTable?.orderBy[0].id === 'device.deviceInfo.lastTransmission') {
                    data.table.VehiclesTable.orderBy[0].id = 'name';
                }
            }
            localStorage.setItem(`${userInfo.user?.id || 0}`, JSON.stringify(data));
        }
    }, []);

    const columns: Column<VehicleModel>[] = [
        {
            Header: translate('t.actions'),
            accessor: 'id',
            width: 85,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: (
                                    <UiLink
                                        $padding='0'
                                        content={translate('t.details')}
                                        url={`/vehicle-status?vehicle=${props.row.original.id}`}
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: COLOR_GREY,
                                            dark: WHITE
                                        })}
                                    />
                                ),
                                value: props.value
                            },
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    setVehicleActionAtom({
                                        vehicleId: props.value,
                                        action: CRUD.EDIT,
                                        vehicleName: props.row.original.name
                                    });
                                    setVehicleModal(true);
                                }
                            },
                            {
                                title: translate('t.clone'),
                                value: props.value,
                                action: () => {
                                    setVehicleActionAtom({
                                        vehicleId: props.value,
                                        action: CRUD.CLONE,
                                        vehicleName: props.row.original.name
                                    });
                                    setVehicleModal(true);
                                }
                            },
                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    setVehicleActionAtom({
                                        vehicleId: props.value,
                                        action: CRUD.DELETE,
                                        vehicleName: props.row.original.name,
                                        hasDevice: !!props.row.original.device
                                    });
                                    setVehicleModal(true);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                data-testid={`vehicle-action-button${props.row.index}`}
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        },
        {
            Header: translate('t.icon'),
            accessor: 'icon',
            disableFilters: true,
            disableSortBy: true,
            width: 75,
            height: 20,
            Cell: (props) => (
                <>
                    <UiLink
                        testId='vehicle-link-icon'
                        content={<img width={sizeImage} height={sizeImage} alt='img' src={getImgSource(props.value)} />}
                        url={`/vehicle-status?vehicle=${props.row.original.id}`}
                    />
                </>
            )
        },
        {
            Header: translate('t.vehicle_name'),
            accessor: 'name',
            width: 170,
            height: 20,
            Cell: (props) => (
                <div
                    style={{ overflow: 'hidden', width: '150px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    title={props.value}
                >
                    <UiLink
                        testId='vehicle-name-link'
                        content={props.value}
                        url={`/vehicle-status?vehicle=${props.row.original.id}`}
                    />
                </div>
            )
        },
        {
            Header: translate('t.vehicle_configuration_short'),
            accessor: 'vehicleConfigurationV2',
            width: 100,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <>
                    {!isVehicleTyreBayHub(props.value?.id) ? (
                        <UiLink
                            testId='vehicle-configuration-link'
                            content={
                                <div style={{ height: `${sizeImage}px`, width: `${sizeImage}px` }}>
                                    <VehicleSchema schema={props.value?.distribution} emptySchema={true} />
                                </div>
                            }
                            title={props.row.original.name}
                            url={`/vehicle-status?vehicle=${props.row.original.id}`}
                        />
                    ) : (
                        <HubContainer>{NotValue}</HubContainer>
                    )}
                </>
            )
        },
        {
            Header: translate('t.hub_serial_number'),
            accessor: (row: VehicleModel) => row.device?.serialNumber,
            id: 'device.serialNumber',
            width: 145,
            height: 20,
            disableSortBy: true,
            Cell: (props) => {
                return props.value ? (
                    <UiLink
                        testId='device-link'
                        content={props.value}
                        title={props.value}
                        url={`/device/${props.row.original.device.id}`}
                    />
                ) : (
                    <>{NotValue}</>
                );
            }
        },
        {
            Header: translate('t.manufacturer'),
            accessor: 'vehicleManufacturerV2',
            disableSortBy: true,
            width: 130,
            height: 20,
            Cell: (props) => {
                return <>{props.value ?? NotValue}</>;
            }
        },
        {
            Header: translate('t.model'),
            accessor: 'vehicleModelV2',
            disableSortBy: true,
            width: 100,
            height: 20,
            Cell: (props) => {
                return <>{props.value ?? NotValue}</>;
            }
        },
        {
            Header: translate('t.group'),
            accessor: (row: VehicleModel) => row?.vehicleGroup?.name ?? NotValue,
            id: 'vehicleGroup',
            width: 75,
            height: 20,
            Cell: (props) => {
                return <>{props.value ?? NotValue}</>;
            }
        },
        {
            Header: `${translate('t.max_speed')} (${displayUserUnits.speed})`,
            accessor: 'maxSpeed',
            width: 124,
            height: 20,
            disableSortBy: true,
            Cell: (props) => {
                return props.value ? (
                    <>
                        {fromServerToUserUnit({
                            type: convertType.speed,
                            value: props.value,
                            displayUnits: false,
                            fixed: 1
                        })}
                    </>
                ) : (
                    <>{NotValue}</>
                );
            }
        },
        {
            Header: translate('t.last_transmission_hub'),
            accessor: (row: VehicleModel) => row.device?.deviceInfo.lastTransmission,
            width: 120,
            height: 20,
            disableSortBy: true,
            Cell: (props) => {
                if (
                    dateCheckByMonth(props.row.original.device?.deviceInfo?.lastTransmission) ||
                    !props.row.original.device?.deviceInfo?.lastTransmission
                )
                    return <>{NotValue}</>;

                const difference: number = DateTime.local().diff(
                    DateTime.fromISO(props.row.original.device?.deviceInfo?.lastTransmission),
                    'hours'
                ).hours;
                return (
                    <span
                        style={{
                            color:
                                difference < 1
                                    ? colorTransmission
                                    : difference < 24
                                    ? 'rgb(255, 111, 0)'
                                    : colorTransmissionGrey
                        }}
                    >
                        {fromUTCtoUserTimezone({
                            date: props.row.original.device?.deviceInfo?.lastTransmission,
                            format: 'dateTime',
                            displaySeconds: false
                        })}
                    </span>
                );
            },
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DeviceLastTransmissionFrom'
                            keyFilter='deviceLastTransmissionFrom'
                            label={`${translate('t.last_transmission_hub')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DeviceLastTransmissionTo'
                            keyFilter='deviceLastTransmissionTo'
                            label={`${translate('t.last_transmission_hub')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            width: 120,
            height: 20,
            Cell: (props) => {
                return <>{fromUTCtoUserTimezone({ date: props.value, format: 'dateTime', displaySeconds: false })}</>;
            },
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={translate('t.created_at_from')}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={translate('t.created_at_to')}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.last_updated'),
            accessor: (row: VehicleModel) => row.vehicleInfo.measuredAt,
            id: 'vehicleInfo.measuredAt',
            width: 120,
            height: 20,
            Cell: (props) => {
                return (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.vehicleInfo?.measuredAt,
                            format: 'dateTime',
                            displaySeconds: false
                        })}
                    </>
                );
            },
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtFrom'
                            keyFilter='measuredAtFrom'
                            label={`${translate('t.last_updated')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='MeasuredAtTo'
                            keyFilter='measuredAtTo'
                            label={`${translate('t.last_updated')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: `ICD ${translate('t.mac_address')}`,
            accessor: (row: VehicleModel) => row.vehicleInfo.display,
            width: 120,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                if (props.value) {
                    const data = JSON.parse(props.value);
                    if (data.length > 0) {
                        return <>{data[0]['MAC']}</>;
                    }
                }
                return <>{NotValue}</>;
            }
        },
        {
            Header: `ICD ${translate('t.last_transmission')}`,
            accessor: (row: VehicleModel) => row.vehicleInfo.displayUpdateTime,
            id: 'vehicleInfo.displayUpdateTime',
            width: 110,
            height: 20,
            Cell: (props) => {
                if (dateCheckByMonth(props.value) || !props.value) return <>{NotValue}</>;

                const difference: number = DateTime.local().diff(DateTime.fromISO(props.value), 'hours').hours;
                return (
                    <span
                        style={{
                            color:
                                difference < 1
                                    ? 'rgb(9, 69, 173)'
                                    : difference < 24
                                    ? 'rgb(255, 111, 0)'
                                    : 'rgb(255, 0, 0)'
                        }}
                    >
                        {fromUTCtoUserTimezone({ date: props.value, format: 'dateTime', displaySeconds: false })}
                    </span>
                );
            },
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DisplayLastTransmissionFrom'
                            keyFilter='displayLastTransmissionFrom'
                            label={`ICD ${translate('t.last_transmission')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='DisplayLastTransmissionTo'
                            keyFilter='displayLastTransmissionTo'
                            label={`ICD ${translate('t.last_transmission')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.out_of_service'),
            accessor: 'outOfService',
            width: 80,
            height: 20,
            disableSortBy: true,
            Cell: (props) => {
                return props.value ? <CheckIcon fontSize={SIZE_BUTTON} /> : <CloseIcon fontSize={SIZE_BUTTON} />;
            },
            Filter: ({ setFilter, state, ...props }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: translate('t.status'),
                        value: state.filters.find((filter) => filter.id === 'outOfService')?.value?.value,
                        humanValue: state.filters.find((filter) => filter.id === 'outOfService')?.value?.value
                            ? translate(
                                  `t.${
                                      state.filters.find((filter) => filter.id === 'outOfService')?.value?.value === '1'
                                          ? 'vehicle_settings_service_off_success_title'
                                          : 'vehicle_settings_service_on_success_title'
                                  }`
                              )
                            : undefined
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: props.applyFiltersFlag,
                    filterId: 'outOfService'
                });

                return (
                    <ContainerSwitchFilter>
                        <FormControlLabel
                            value={cellValue?.value == '1'}
                            control={
                                <Switch
                                    color='primary'
                                    checked={cellValue?.value == '1'}
                                    value={cellValue?.value == '1'}
                                    name='showTargetPressure'
                                />
                            }
                            label={translate('t.out_of_service')}
                            labelPlacement='end'
                            onChange={(e, value) => {
                                const humanValue = value
                                    ? 'vehicle_settings_service_off_success_title'
                                    : 'vehicle_settings_service_on_success_title';

                                setFilterValue({
                                    name: translate('t.status'),
                                    value: booleanToNumber(value).toString(),
                                    humanValue: translate(`t.${humanValue}`)
                                });
                            }}
                        />
                    </ContainerSwitchFilter>
                );
            }
        },

        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'deviceLastTransmissionFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'deviceLastTransmissionTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'measuredAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'displayLastTransmissionFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'displayLastTransmissionTo'
        }
    ];

    return <view.VehicleTableContent data-testid='VehicleTable-testid' columns={columns} />;
};

export default Wrapper(VehicleTable);
