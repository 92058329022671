import React from 'react';
import { Button } from './TemperaturePredictionActionMenu.style';
import { AlertLevels, TemperaturePredictionActionMenuProps } from './TemperaturePredictionActionMenu.type';
import { Grid, Typography } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useModal from 'components/CustomHooks/ModalHook';
import {
    TemperaturePredictionMenuAtom,
    WheelPrediction as WheelPredictionType
} from '../TemperaturePredictionPopover/TemperaturePredictionPopover.atom';
import PredictedGraph from 'components/Mixs/PredictedGraph/PredictedGraph';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme } from 'states/global/Theme';
import useConverter from 'components/CustomHooks/Converter/Converter';
import {
    ALERT_LEVEL_2_PRED_TEMP_DARK,
    ALERT_LEVEL_2_PRED_TEMP,
    ALERT_LEVEL_1_PRED_TEMP,
    ALERT_LEVEL_1_PRED_TEMP_DARK,
    ALERT_LEVEL_0_PRED_TEMP
} from 'components/Ui/colors';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { CustomerSettings } from 'states/global/CustomerSettings';

// todo waiting new modal with layer repair

const TemperaturePredictionActionMenu: React.FC<TemperaturePredictionActionMenuProps> = ({
    wheelPredictions
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { setModalState } = useModal();
    const temperaturePredictionMenu = useRecoilValue(TemperaturePredictionMenuAtom);
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCtoUserTimezone, fromServerToUserUnit, convertType } = useConverter();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom); //temp fix
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom); //temp fix
    const customerSettings = useRecoilValue(CustomerSettings);

    if (!wheelPredictions || !wheelPredictions?.length) {
        return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Typography variant='body1'>{translate('t.there_no_data')}</Typography>
                </Grid>
            </Grid>
        );
    }

    const showInfoByAlert = (wheelPrediction: Partial<WheelPredictionType>) => {
        if (wheelPrediction.levelAlert === AlertLevels.LEVEL_3) {
            return (
                <>
                    <Typography variant='body1'>{`${translate('t.remaining_hours')}: `}</Typography>
                    <Typography variant='body1' fontWeight='bold'>
                        {`${wheelPrediction.remainingHours || '00:00:00'} ${fromServerToUserUnit({
                            type: convertType.temperature,
                            value: wheelPrediction?.criticalTemperature,
                            displayUnits: true,
                            fixed: 1,
                            displayIfEmpty: '-'
                        })}`}
                    </Typography>
                    {wheelPrediction.remainingHours === '00:00:00' && wheelPrediction.notificationEventLevel === 3 && (
                        <Typography
                            fontWeight='bold'
                            variant='body1'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: ALERT_LEVEL_2_PRED_TEMP,
                                dark: ALERT_LEVEL_2_PRED_TEMP_DARK
                            })}
                        >
                            {`${translate('t.immediate_action')}!  `}
                        </Typography>
                    )}
                    <Typography variant='body1'>
                        {`${translate('t.measured_at')}: ${fromUTCtoUserTimezone({
                            date: wheelPrediction?.measuredAt,
                            format: 'dateTime',
                            displaySeconds: false
                        })}`}
                    </Typography>
                </>
            );
        }

        if (wheelPrediction.levelAlert === AlertLevels.LEVEL_0) {
            return (
                <>
                    <Typography variant='body1'>{`${translate('t.remaining_hours')}: `}</Typography>
                    <Typography variant='body1' fontWeight='bold'>
                        {`${translate('p.more_than_2_hours')} ${fromServerToUserUnit({
                            type: convertType.temperature,
                            value: wheelPrediction?.criticalTemperature,
                            displayUnits: true,
                            fixed: 1,
                            displayIfEmpty: '-'
                        })}`}
                    </Typography>
                    {wheelPrediction.remainingHours === '00:00:00' && wheelPrediction.notificationEventLevel === 3 && (
                        <Typography
                            fontWeight='bold'
                            variant='body1'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: ALERT_LEVEL_2_PRED_TEMP,
                                dark: ALERT_LEVEL_2_PRED_TEMP_DARK
                            })}
                        >
                            {`${translate('t.immediate_action')}!  `}
                        </Typography>
                    )}
                    <Typography variant='body1'>
                        {`${translate('t.measured_at')}: ${fromUTCtoUserTimezone({
                            date: wheelPrediction?.measuredAt,
                            format: 'dateTime',
                            displaySeconds: false
                        })}`}
                    </Typography>
                </>
            );
        }

        return (
            <>
                <Typography variant='body1'>{`${translate('t.remaining_hours')}: `}</Typography>

                <Typography variant='body1' fontWeight='bold'>
                    {`${wheelPrediction?.remainingHours || '00:00:00'} ${fromServerToUserUnit({
                        type: convertType.temperature,
                        value: wheelPrediction?.criticalTemperature,
                        displayUnits: true,
                        fixed: 1,
                        displayIfEmpty: '-'
                    })}`}
                </Typography>

                <Typography variant='body1'>
                    {`${translate('t.measured_at')}: ${fromUTCtoUserTimezone({
                        date: wheelPrediction?.measuredAt,
                        format: 'dateTime',
                        displaySeconds: false
                    })}`}
                </Typography>
            </>
        );
    };

    return (
        <>
            {wheelPredictions.map((wheelPrediction, index) => {
                const alertLevel = wheelPrediction?.notificationEventLevel || 0;
                let color =
                    alertLevel === 1
                        ? {
                              light: ALERT_LEVEL_1_PRED_TEMP,
                              dark: ALERT_LEVEL_1_PRED_TEMP_DARK
                          }
                        : {
                              light: ALERT_LEVEL_2_PRED_TEMP,
                              dark: ALERT_LEVEL_2_PRED_TEMP_DARK
                          };
                if (customerSettings.temperature_prediction_workflow === 1) {
                    if (alertLevel === 0) {
                        color = {
                            light: ALERT_LEVEL_0_PRED_TEMP,
                            dark: ALERT_LEVEL_0_PRED_TEMP
                        };
                    }
                    if (alertLevel >= 1) {
                        color = {
                            light: ALERT_LEVEL_1_PRED_TEMP,
                            dark: ALERT_LEVEL_1_PRED_TEMP_DARK
                        };
                    }
                    if (alertLevel >= 2) {
                        color = {
                            light: ALERT_LEVEL_2_PRED_TEMP,
                            dark: ALERT_LEVEL_2_PRED_TEMP_DARK
                        };
                    }
                }

                const predictedGraphContent = () => {
                    //temp fix popovers table
                    const newModal = {
                        content: (
                            <div style={{ height: 600, padding: 10 }}>
                                {' '}
                                <PredictedGraph
                                    vehicleName={temperaturePredictionMenu.data?.vehicleName || ''}
                                    wheelPredictionId={wheelPrediction.id || 0}
                                    alertLevel={alertLevel}
                                    vehicleId={temperaturePredictionMenu.data?.vehicleId || 0}
                                />
                            </div>
                        ),
                        leftTitle: `${translate('t.remaining_hours')} - ${temperaturePredictionMenu.data?.vehicleName}`,
                        rightTitle: `
                            ${translate('t.tyre')}
                            ${translate('t.pos')} 
                            ${wheelPrediction.position}
                        `,

                        height: 100,
                        width: 90,
                        widthUnit: '%',
                        heightUnit: '%',
                        buttons: <></>
                    };
                    modalDispach({
                        type: ModalActionTypesEnum.SET_MODAL_STATE,
                        ModalPropsPayload: {
                            ...newModal,
                            onClose: () =>
                                modalDispach({
                                    type: ModalActionTypesEnum.SET_MODAL_STATE,
                                    ModalPropsPayload: {
                                        isOpen: false,
                                        ...newModal
                                    }
                                }),
                            isOpen: true
                        }
                    });
                };

                return (
                    <Button
                        key={`wheelprediction-${index}`}
                        datatest-id={`wheelprediction-${index}-datatest-id`}
                        disabled={alertLevel === 0}
                        $level={alertLevel}
                        onClick={() => {
                            /*
                            setModalState({
                                isOpen: true,
                                height: 72,
                                width: 90,
                                widthUnit: '%',
                                heightUnit: '%',
                                leftTitle: `${translate('t.remaining_hours')} - ${
                                    temperaturePredictionMenu.data?.vehicleName
                                }`,
                                rightTitle: `
                                ${translate('t.tyre')}
                                ${translate('t.pos')} 
                                ${wheelPrediction.position}
                            `,
                                content: (
                                    <PredictedGraph
                                        vehicleName={temperaturePredictionMenu.data?.vehicleName || ''}
                                        wheelPredictionId={wheelPrediction.id || 0}
                                        alertLevel={alertLevel}
                                        vehicleId={temperaturePredictionMenu.data?.vehicleId || 0}
                                    />
                                )
                            }); */

                            predictedGraphContent();
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <UiIcon
                                    icon={['fas', 'temperature-half']}
                                    size='lg'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: color.light,
                                        dark: color.dark
                                    })}
                                />
                                <Typography
                                    variant='body1'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: color.light,
                                        dark: color.dark
                                    })}
                                >
                                    {` ${translate('t.pos')} ${wheelPrediction?.position} `}
                                </Typography>
                                <UiIcon
                                    icon={['far', 'stopwatch']}
                                    size='lg'
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: color.light,
                                        dark: color.dark
                                    })}
                                />
                                {showInfoByAlert(wheelPrediction)}
                            </Grid>
                        </Grid>
                    </Button>
                );
            })}
        </>
    );
};

export default TemperaturePredictionActionMenu;
