import styled from 'styled-components';
import { Grid, Paper, TableHead, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { BACKGROUND_PAPER_DARK_MODE, COLOR_GREY_6 } from '../../Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const Loading = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NeedNewHubServiceVersion = styled.div`
    height: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 14px;
`;

export const TextResultTableBox = styled(Paper)`
    &.MuiPaper-root {
        padding: 16px;
        min-height: 335px;
        ${() => {
            return applyStyleByMode({
                styleJade: `background: ${
                    useTheme().palette.mode === ThemeMode.light ? COLOR_GREY_6 : BACKGROUND_PAPER_DARK_MODE
                };`
            });
        }}

        @media (max-width: 768px) {
            min-height: 403px;
        }
    }
`;

export const GridWithHeight = styled(Grid)<{ height: number; $overflow?: boolean; $paddingTop?: boolean }>`
    &.MuiGrid-root {
        ${(props) => `
            padding-top: ${props.$paddingTop ? '12px' : '16px'}
            padding-top: 12px;
            height: ${props.height}px;
            ${props.$overflow && 'overflow-y: auto;'}                      
        `};
    }
`;

export const LoadingContent = styled.div`
    width: 250px;
    text-align: center;
`;

export const TableHeadTestResult = styled(TableHead)`
    border-top: 1px solid #c0c0c0;
`;

export const SubTitleTestResult = styled.h5`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.titleSize}`;
    }}
`;
