import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../states/global/Theme';
import { DARK_STEEL_GREY } from '../Ui/colors';
import { styled as styleMui } from '@mui/system';

export const GraphContent = styled.div<{ height?: number }>`
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    @media (max-width: 767px) {
        min-width: 800px;
        overflow: auto;
    }

    ${(props) =>
        applyStyleByMode({
            styleJade: `
            height: auto;
            min-height: ${props.height ? `${props.height + 1}px;` : 'none;'}
            background: ${useTheme().palette.mode === ThemeMode.light ? '#f6f6f6' : 'rgb(0 0 0 / 30%)'};
            padding-top: 10px;
            border-radius: 5px;
            `
        })};
`;

export const LegendValue = styled.span<{ color: string }>`
    color: ${(props) => props.color || '#000'};
    cursor: pointer;
`;

export const LoadingBar = styled.div<{ width?: number; height?: number; unit?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${(props) => (props.height ? `${props.height}px` : '100%')};
    background: transparent;
`;

export const LoadingProgress = styled(CircularProgress)({
    color: DARK_STEEL_GREY
});

export const NoDataAlert = styleMui('div', { shouldForwardProp: (props) => props !== 'customHeight' })<{
    customHeight?: number;
}>` 
    text-align: center;
    ${({ customHeight }) => (customHeight ? `min-height: ${customHeight - 15}px;` : '')}
`;

export const G = styled.g``;

export const TextTick = styled.text<{ fontSize?: string }>`
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '1em')};
`;

export const TooltipContainer = styleMui(Box)`
    display: flex;
    height: auto;
    align-items: start;
    text-align: left;
    border: thin solid aliceblue;
`;
