import styled from 'styled-components';
import { applyStyleByMode, BESColors } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { useTheme } from '@mui/material';
import {
    ALERT_LEVEL_0_CARD,
    ALERT_LEVEL_0_DARK,
    ALERT_LEVEL_0_DARK_HOVER,
    ALERT_LEVEL_0_HOVER,
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_1_DARK_HOVER,
    ALERT_LEVEL_1_HOVER,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_2_DARK_HOVER,
    ALERT_LEVEL_2_HOVER,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_LEVEL_3_DARK_HOVER,
    ALERT_LEVEL_3_HOVER,
    PRIMARY,
    BACKGROUND_BLUE,
    WHITE
} from 'components/Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleStatusGridViewContent = styled.div`
    max-width: 100%;
    overflow: auto;
`;

export const NoVehicleIcon = styled.span`
    font-size: 28px;
    opacity: 0.3;
`;

export const VehicleWrapper = styled.section<{
    selected: boolean;
    alertLevel: number;
    $disableVehicleSelection: boolean;
}>`
    cursor: ${(props) => (props.selected ? 'initial' : props.$disableVehicleSelection ? 'wait' : 'pointer')};
    text-align: center;
    box-sizing: border-box;

    ${({ selected }) => {
        return `
            margin: 5px;
            border-width: ${selected ? '4px' : '0px'};
            width: 125px;

            

            height: 110px;
            min-width: 125px;
            min-height: 110px;
            @media (min-width: 320px) and (max-width: 1200px) {
                width: 100px;
                height: 98px;
                min-width: 110px;
                min-height: 103px;
            }
        `;
    }}
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const height = common.easyMode ? '130px' : '110px';
        return `height: ${height} ;`;
    }}
    border-style: solid;
    border-color: ${(props) => {
        const colorBorderSelected = applyStyleByMode({
            theme: useTheme().palette.mode,
            light: `${useTheme().palette.primary.main}`,
            dark: BACKGROUND_BLUE
        });

        return props.selected ? colorBorderSelected : 'none';
    }};
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    background: ${(props) => {
        const alertColors = {
            light: {
                0: ALERT_LEVEL_0_CARD,
                1: ALERT_LEVEL_1,
                2: ALERT_LEVEL_2,
                3: ALERT_LEVEL_3
            },
            dark: {
                0: ALERT_LEVEL_0_DARK,
                1: ALERT_LEVEL_1_DARK,
                2: ALERT_LEVEL_2_DARK,
                3: ALERT_LEVEL_3_DARK
            }
        };

        return alertColors[useTheme().palette.mode][props.alertLevel];
    }};

    &:hover {
        background: ${(props) => {
            const alertColors = {
                light: {
                    0: ALERT_LEVEL_0_HOVER,
                    1: ALERT_LEVEL_1_HOVER,
                    2: ALERT_LEVEL_2_HOVER,
                    3: ALERT_LEVEL_3_HOVER
                },
                dark: {
                    0: ALERT_LEVEL_0_DARK_HOVER,
                    1: ALERT_LEVEL_1_DARK_HOVER,
                    2: ALERT_LEVEL_2_DARK_HOVER,
                    3: ALERT_LEVEL_3_DARK_HOVER
                }
            };
            return alertColors[useTheme().palette.mode][props.alertLevel];
        }};
        transition: background-color 500ms linear;
    }
`;

export const HeaderWrapper = styled.section`
    height: 25%;
    text-align: center;
    margin-bottom: 13px;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return common.easyMode ? `display: inline;` : '';
    }}
`;

export const IconWrapper = styled.section`
    display: inline-block;
    padding: 2px;
`;

export const StatusIcon = styled.span<{
    $pressureIcon?: boolean;
    $hide?: boolean;
    $temperatureIcon?: boolean;
}>`
    display: inline-block;
    ${(props) => (props.$temperatureIcon ? 'color: #FF0000;' : '')};
    ${(props) => (props.$pressureIcon ? 'padding: 2px' : 'padding: 0px 3px')};
    font-weight: bold;
    ${({ $temperatureIcon }) => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${$temperatureIcon ? common.titleSize : common.textSize};`;
    }}

    ${(props) => {
        return `
            visibility: ${props.$hide ? 'hidden' : 'visible'};
        `;
    }};
`;

export const VehicleIconWrapper = styled.section<{ $outOfService: boolean }>`
    height: 40px;
    text-align: center;
    opacity: ${(props) => (props.$outOfService ? '0.4' : '1')};

    .router-icon {
        margin-top: 5px;
    }
`;

export const TitleWrapper = styled.section`
    text-align: center;
`;

export const VehicleTitle = styled.h1<{ alertLevel: number }>`
    text-align: center;
    font-weight: bold;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin: 0 0 0.7em 0;

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            font-size: ${common.textSize};
            width: 120px;
        `;
    }}

    color: ${(props) => {
        const alertColors = {
            0: PRIMARY,
            1: ALERT_LEVEL_1_DARK,
            2: ALERT_LEVEL_2_DARK,
            3: ALERT_LEVEL_3_DARK
        };
        return useTheme().palette.mode === ThemeMode.light ? alertColors[props.alertLevel] : WHITE;
    }};
`;

export const VehicleListDiv = styled.div`
    padding: 0 0 0 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: 320px) and (max-width: 1200px) {
        width: max-content;
    }
`;

export const BESLabel = styled.div<{ rank: number }>`
    border-radius: 5px;
    display: flex;
    position: absolute;
    justify-content: center;
    width: 33px;
    left: 5px;
    background-color: ${(props) => {
        return BESColors[props.rank];
    }};
    ${(props) => props.rank === 0 && 'display: none;'}
};`;

export const BESText = styled.div`
    padding-top: 3px;
    font-weight: 600;
    font-size: 10px;
    color: #fff;
`;
