import React, { useMemo } from 'react';
import UiIcon from 'components/Ui/Components/UiIcon';
import { applyStyleByMode } from 'helpers';
import { WHITE, SECONDARY_DARK_MODE } from 'components/Ui/colors';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { DateTime } from 'luxon';
import { VehicleStatus2Data } from 'models/Vehicle.type';

const TemperaturePredictionPeriodIcon: React.FC<{
    time: string | null;
    predictionTemperature: boolean;
    tempAndPressPage?: boolean;
    wheels?: VehicleStatus2Data['vehicle']['wheels'];
}> = ({ time, predictionTemperature, tempAndPressPage, wheels }) => {
    const ThemeMode = useRecoilValue(Theme);

    const checkOverTimeLimit = (time: string | null | undefined): boolean => {
        if (!time) {
            return false;
        }
        const tempPredTime = DateTime.fromISO(time, { zone: 'UTC' });
        const currentTime = DateTime.now().setZone('UTC');
        const diff = currentTime.diff(tempPredTime, 'minutes').minutes;
        return diff > 6;
    };
    const lastValidatedTempPredPeriod = () => {
        if (predictionTemperature && wheels) {
            return wheels.some((wheel) => checkOverTimeLimit(wheel.lastTemperaturePredictedAt));
        }
        return checkOverTimeLimit(time);
    };

    return lastValidatedTempPredPeriod() && predictionTemperature ? (
        <span className='fa-layers fa-fw'>
            <UiIcon
                icon={['fas', 'slash']}
                size='sm'
                fixedWidth
                color={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    dark: WHITE,
                    light: tempAndPressPage ? 'rgb(35, 35, 35)' : SECONDARY_DARK_MODE
                })}
            />
            <UiIcon
                icon={['fas', 'p']}
                size='sm'
                fixedWidth
                color={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    dark: WHITE,
                    light: tempAndPressPage ? 'rgb(35, 35, 35)' : SECONDARY_DARK_MODE
                })}
            />
        </span>
    ) : (
        <></>
    );
};

export default TemperaturePredictionPeriodIcon;
