import styled from 'styled-components';
import { Typography } from '@mui/material';
import { WarningMessage } from '../../components/Mixs/DataExport/TopBar/TopBar.style';

export const ReportPermissionsViewContent = styled.div`
    & tbody td {
        padding: 4px !important;
        border-bottom: 1px solid #d1d1d1 !important;
    }
`;

export const AssignedReportsTypography = styled(Typography)`
    &.MuiTypography-root {
        line-height: 1.8em;
        font-weight: 600;
    }
    padding-bottom: 9px;
`;

export const InfoMessage = styled(WarningMessage)`
    &.MuiAlert-root {
        padding: 0 5px;
    }
`;
