import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { PRIMARY, WHITE, BACKGROUND_BLUE } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../states/global/Theme';
import { Box, Grid, useTheme } from '@mui/material';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const AlertCustomContainer = styled.div<{ $displayBody?: boolean; $disableRedirect?: boolean }>`
    .popUpAlert {
        padding: 10px;
        border-radius: 15px;
        min-height: ${(props) => (props.$displayBody ? 100 : 0)}px;
        padding-bottom: 10px;
        box-shadow: 0px 8px 19px rgba(0, 0, 0, 0.25);
        margin-bottom: 15px;
    }
    text-align: left;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            td {
                border-bottom: solid thin #d1d1d1;
                font-size: ${common.textSize};
            }
        `;
    }}

    ${(props) => !props.$disableRedirect && 'cursor: pointer;'}
`;

export const AlertContent = styled.div<{
    level: number;
    $displayBody?: boolean;
    $float?: boolean;
    dense?: boolean;
}>`
    ${({ dense }) =>
        applyStyleByMode({
            styleJade: `
            padding: ${dense ? '0 12px' : '12px'};
            box-shadow: 4px 4px 10px #0000001a; 
        `,
            theme: useTheme(),
            light: `color: ${PRIMARY};`,
            dark: `color: ${WHITE};`
        })}
    border-radius: 4px;
    min-height: ${(props) => (props.$displayBody ? 100 : 0)}px;
    padding-bottom: ${(props) => (props.dense ? 0 : '10px')};
    margin-bottom: ${(props) => (props.$float ? 0 : props.dense ? 3 : 15)}px;
    background-image: ${(props) => {
        const theme = useTheme();
        const alertColors = {
            light: {
                1: 'rgb(255, 237, 158) 9%, rgb(255, 251, 233) 62%',
                2: 'rgb(255, 208, 163) 9%, rgb(255, 232, 209) 62%',
                3: 'rgb(255, 159, 159) 9%, rgb(255, 204, 204) 62%'
            },
            dark: {
                1: 'rgb(118 98 0) 9%,rgb(161 133 0) 62%',
                2: 'rgb(145 72 0) 9%,rgb(213 107 0) 62%',
                3: 'rgb(106 2 2) 9%,rgb(179 15 15) 62%'
            }
        };
        return `linear-gradient(to right, ${alertColors[theme.palette.mode][props.level]})`;
    }};

    & table {
        border-spacing: 0px;
    }

    & td {
        padding: 3px 0 2px 0;
        border-bottom: ${(props) => {
            switch (props.level) {
                case 0:
                    return 'transparent';
                case 1:
                    return '1px solid rgb(255 227 107)';
                case 2:
                    return '1px solid rgb(255 186 120)';
                case 3:
                    return '1px solid rgb(255 128 128)';
            }
        }};
    }
`;

export const DenseCover = styled(Grid)`
    display: flex;
    justify-content: space-between;
`;

export const DenseValueCover = styled(Box)`
    justify-content: right;
    display: inline-flex;
    align-items: center;
    width: 100%;
`;

export const DenseInfoCover = styled(Box)`
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 1px 0;
`;

export const ChipLevelContainer = styled.div`
    display: table;
    margin: 0 auto !important;
`;

export const ChipLevel = styled(Chip)<{ level: number; clickable?: boolean }>`
    border-radius: 4px !important;
    min-width: 60px !important;
    background-color: ${(props) => {
        switch (props.level) {
            case 0:
                return PRIMARY;
            case 1:
                return 'rgb(159, 148, 21)';
            case 2:
                return 'rgb(205, 110, 10)';
            case 3:
                return 'rgb(224, 0, 0)';
        }
    }} !important;
    color: #fff !important;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            font-size: ${common.textSize} !important;
        `;
    }}
    line-height: 14px !important;
    margin-left: 3px !important;
    margin-right: 10px !important;

    &:hover {
        ${({ clickable, level }) => {
            if (clickable) {
                switch (level) {
                    case 0:
                        return `
                            background-color: ${PRIMARY} !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 1:
                        return `
                            background-color: #7e7611 !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 2:
                        return `
                            background-color: #a3590b !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                    case 3:
                        return `
                            background-color: #b10a0a !important;
                            box-shadow: 0px 3px 4px rgba(0,0,0,0.25);
                        `;
                }
            }
            return '';
        }}
    }

    ${() => {
        if (useTheme().palette.mode === ThemeMode.dark) {
            return `
                border: 1px solid rgb(255 255 255 / 50%);
            `;
        }
    }}
`;

export const AlertFooter = styled.div`
    text-align: right;
    padding-top: 5px;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            font-size: ${common.textSize};
        `;
    }}

    ${() => {
        if (useTheme().palette.mode === ThemeMode.light) {
            return `color: ${PRIMARY};`;
        }
    }}
`;

export const SettingButton = styled.button`
    svg {
    }
`;

export const AlertBtnSetting = styled.div`
    float: right;
    margin-right: 5px;
    margin-top: -3px;
    button {
        background: transparent;
        border: none;
        margin-top: -2px;
        padding-right: 0;
        margin-right: -3px;
        widht: 20px;
        height: 20px;
        svg {
            font-size: 24px;
            ${() => {
                return `
                    color: ${useTheme().palette.mode === ThemeMode.light ? '#9c9c9c' : WHITE} !important;
                `;
            }}
            cursor: pointer;

            &:hover {
                ${() => {
                    return `
                        color: ${useTheme().palette.mode === ThemeMode.light ? '#4e4e4e' : BACKGROUND_BLUE} !important;
                    `;
                }}
            }
        }
    }
`;
